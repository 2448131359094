import { pnTokenManager } from '@pn/core/services/authentication/tokenManager';
import { isEmbedded } from '@pn/core/utils/embedded';
import env, { isUS } from '@pn/core/utils/env';
import { webStorageService } from '@pn/services/storage/webStorageService';
import { to2DPosition } from '@pn/services/utils/geojson';
import { isArray } from 'chart.js/helpers';
import { isNil, isNumber } from 'lodash-es';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = env.PN_MAPBOX_ACCESS_TOKEN;

declare module 'mapbox-gl' {
  interface Map {
    geolocate: mapboxgl.GeolocateControl;
  }
}

type MapPosition = {
  center: GeoJSON.Position;
  zoom: number;
};

const defaultMapPosition = isUS() ? [-98, 40] : [-114.25, 51.25];
const defaultZoom = isUS() ? 4 : 9.75;

const lastMapPosition = {
  center: undefined as GeoJSON.Position | undefined,
  zoom: undefined as number | undefined,
};

export function setLastMapPosition(params: {
  center: GeoJSON.Position;
  zoom: number;
}): void {
  lastMapPosition.center = params.center;
  lastMapPosition.zoom = params.zoom;

  webStorageService('pn').set('last-map-position', lastMapPosition);
}

function getMapPosition(): MapPosition {
  const savedMapPosition =
    webStorageService('pn').get<MapPosition>('last-map-position');

  if (!isNil(lastMapPosition.center) && !isNil(lastMapPosition.zoom)) {
    return {
      center: lastMapPosition.center,
      zoom: lastMapPosition.zoom,
    };
  } else if (isPosition(savedMapPosition?.center)) {
    return savedMapPosition;
  } else {
    return { center: defaultMapPosition, zoom: defaultZoom };
  }
}

export const configureMapboxMap = (
  mapContainer: HTMLElement,
  mapboxStyle: mapboxgl.Style,
  options: {
    zoom?: number;
    center?: GeoJSON.Position;
  }
): mapboxgl.Map => {
  const sw = new mapboxgl.LngLat(-172, 20);
  const ne = new mapboxgl.LngLat(-20, 73);
  const maxBounds = new mapboxgl.LngLatBounds(sw, ne);

  const map = new mapboxgl.Map({
    container: mapContainer,
    style: mapboxStyle,
    center: !isNil(options.center)
      ? to2DPosition(options.center)
      : to2DPosition(getMapPosition().center),
    zoom: isNil(options?.zoom) ? getMapPosition().zoom : options.zoom,
    maxZoom: 18,
    boxZoom: false,
    maxBounds,
    preserveDrawingBuffer: true,
    dragRotate: false,
    touchPitch: false,
    touchZoomRotate: true,
    // @ts-expect-error `useWebGL2` does exist despite what the types say
    useWebGL2: true,
    // fadeDuration: 0,

    transformRequest: (url, resourceType) => {
      if (
        resourceType === 'Tile' &&
        (url.startsWith('https://api.petroniche.com/') ||
          url.startsWith('https://api.petroninja.com/') ||
          url.includes('.cloudfront.net'))
      ) {
        const token = pnTokenManager.getCached();

        return {
          url,
          headers: {
            Authorization: token,
            'x-api-key': env.PN_API_KEY,
          },
        };
      } else {
        return { url };
      }
    },
  });

  map.touchZoomRotate.disableRotation();
  map.doubleClickZoom.disable(); // `doubleClickZoom` option doesn't work
  map.scrollZoom.setWheelZoomRate(1 / 125); // default is 1/450

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
    }),
    'bottom-right'
  );

  if (!isEmbedded()) {
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showAccuracyCircle: true,
    });

    geolocate.on('error', (error: any) => {
      console.error('Geolocate error', error);
    });

    map.addControl(geolocate, 'bottom-right');
    map.geolocate = geolocate;
  }

  return map;
};

/**
 * Older versions of PN Maps were storing map position as a {lat, lng} object.
 */
function isPosition(position: unknown): position is GeoJSON.Position {
  return isArray(position) && isNumber(position[0]) && isNumber(position[1]);
}
