import {
  type Mapping,
  type MappingItem,
  toMappingItem,
} from '@pn/core/domain/data';
import type { IMappingMapper } from '@pn/core/mappers/data';
import { nullToUndefined } from '@pn/core/utils/logic';
import type {
  ApiParquetMappingItem,
  ParquetSourceType,
} from '@pn/services/api/layer/types';

export const apiParquetMappingMapper: IMappingMapper<ApiParquetMappingItem[]> =
  {
    toDomainMapping: (apiParquetMapping) => {
      return apiParquetMapping.reduce<Mapping>(
        (acc, { field, data_type, is_not_renderable }) => {
          if (data_type === 'Null') return acc;

          const domainType = parquetSourceTypeToDomainType(data_type);

          acc[field] = toMappingItem({
            source: '',
            field,
            domainType,
            ui: {
              label: field,
              isShownByDefault: true,
              isNotRenderable: nullToUndefined(is_not_renderable),
            },
          });

          return acc;
        },
        {}
      );
    },
    toTargetMapping: () => {
      throw new Error('Not implemented');
    },
  };

// We can remove this when API returns domain types instead
function parquetSourceTypeToDomainType(
  sourceType: ParquetSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'Boolean':
      return 'boolean';
    case 'Float32':
    case 'Float64':
    case 'Int8':
    case 'Int16':
    case 'Int32':
    case 'Int64':
      return 'number';
    case 'String':
      return 'string';
    case 'Date':
      return 'DateString';
    case 'WKTString':
      return 'WKT';
    default:
      throw new Error(`Invalid parquet source type: ${sourceType}`);
  }
}
