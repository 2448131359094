import type { IDataInfoDocumentMapper } from '@pn/core/mappers/data-info';
import type { ApiDocument } from './types';
import { generateTemporaryId } from '@pn/core/utils/id';

export const apiDataInfoDocumentMapper: IDataInfoDocumentMapper<ApiDocument> = {
  toDomainDocument: (apiDocument) => {
    return {
      source: 'pn',
      id: apiDocument.id?.toString() ?? generateTemporaryId(),
      name: apiDocument.file_name,
      folder: apiDocument.folder,
      downloadUrl: 'v1/document/' + encodeURIComponent(apiDocument.fileKey),
    };
  },
  toTargetDocument: () => {
    throw new Error('Not implemented');
  },
};
