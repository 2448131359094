import { dependencies } from '@pn/core/dependencies';
import { formatDataType, type DataItemId } from '@pn/core/domain/data';
import { handleError } from '@pn/core/errors/handleError';
import { dataDocumentsActions } from '@pn/core/storage';
import { apiDataInfoProvider } from '@pn/services/api/data-info/apiDataInfoProvider';
import { isNil } from 'lodash-es';
import React from 'react';

async function getDataDocuments(dataType: string, id: DataItemId) {
  try {
    dataDocumentsActions(dataType).request();

    const documents = await apiDataInfoProvider.getDataDocuments(dataType, id);

    dataDocumentsActions(dataType).receive(documents);
  } catch (error) {
    handleError({
      error,
      onError: dataDocumentsActions(dataType).error,
      userFriendlyMessage: `Failed to load ${formatDataType(dataType, {
        form: 'singular',
      })} documents`,
    });
  }
}

export function useAutoGetDataDocuments(dataType: string, id?: DataItemId) {
  const { isAuthenticated } = dependencies.useAuthenticationService();

  React.useEffect(() => {
    if (isAuthenticated && !isNil(id)) {
      getDataDocuments(dataType, id);
    }
  }, [isAuthenticated, dataType, id]);
}
