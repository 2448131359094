import { dependencies } from '@pn/core/dependencies';
import type {
  CanvasBBox,
  PolyFeature,
  Transformation,
} from '@pn/core/domain/drawing';
import { areClose, type Point } from '@pn/core/domain/point';
import {
  REFERENCE_PT,
  RESIZE_HANDLE_SIZE,
  SELECT_STROKE_WIDTH,
  VERTEX_HANDLE_RADIUS,
  computeMapTransformation,
  getCanvasBBox,
  getContext,
  isFeaturePathHovered,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

export function useHoverFeature(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { staticCanvasRef, drawingState, drawingMode } = useDrawing();

  const isHoverableMode = ['select', 'text'].includes(drawingMode);

  React.useEffect(() => {
    if (!isDrawingPanelOpen || !isHoverableMode) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const ctx = getContext(staticCanvasRef.current);

    const onMouseMove = (e: mapboxgl.MapMouseEvent) => {
      drawingState.featureHovered = undefined;
      drawingState.featuresHovered = {};
      drawingState.vertexHovered = undefined;

      const xfm = computeMapTransformation(REFERENCE_PT, false);
      const inverseXfm = {
        dx: -xfm.dx,
        dy: -xfm.dy,
        scale: 1 / xfm.scale,
      };

      const point = transformPoint(e.point, inverseXfm);

      const transformation = computeMapTransformation(REFERENCE_PT);

      const featuresSelectedArr = Object.values(drawingState.featuresSelected);
      const featureSelected = featuresSelectedArr[0];
      if (
        featuresSelectedArr.length === 1 &&
        featureSelected.type === 'poly' &&
        isNil(featureSelected.subType)
      ) {
        drawingState.vertexHovered = getVertexHovered({
          feature: featureSelected,
          point,
          transformation,
        });
        if (!isNil(drawingState.vertexHovered)) {
          drawingState.isEdgeHovered = false;
          return;
        }

        // TODO potentially merge with path-related code below
        drawingState.isEdgeHovered = isFeaturePathHovered({
          ctx,
          feature: featureSelected,
          path: drawingState.paths[featureSelected.id],
          point,
        });
      }

      if (!isEmpty(featuresSelectedArr)) {
        const bbox = getCanvasBBox(featuresSelectedArr);

        drawingState.resizeHoverDirection = getResizeHoverDirection({
          bbox,
          point,
          transformation,
        });
        if (!isNil(drawingState.resizeHoverDirection)) return;

        if (isBBoxHovered({ bbox, point, transformation })) {
          drawingState.featuresHovered = drawingState.featuresSelected;
        }
      }

      for (let i = drawingState.order.length - 1; i >= 0; i--) {
        const id = drawingState.order[i];
        const feature = drawingState.features[id];
        const path = drawingState.paths[id];

        if (feature.itemId !== itemId) continue;

        if (isFeaturePathHovered({ ctx, feature, path, point })) {
          drawingState.featureHovered = feature;
          if (isEmpty(drawingState.featuresHovered)) {
            drawingState.featuresHovered[id] = feature;
          }

          return;
        }
      }
    };

    mapboxMap.on('mousemove', onMouseMove);

    return () => {
      mapboxMap.off('mousemove', onMouseMove);
    };
  }, [
    isDrawingPanelOpen,
    isHoverableMode,
    itemId,
    // the following never change:
    staticCanvasRef,
    drawingState,
  ]);
}

function getVertexHovered(params: {
  feature: PolyFeature;
  point: Point;
  transformation: Transformation;
}): number | undefined {
  const { feature, point, transformation } = params;

  const radius = VERTEX_HANDLE_RADIUS / transformation.scale;

  const index = feature.coordinates.findIndex((pt) =>
    areClose(pt, point, radius)
  );
  return index >= 0 ? index : undefined;
}

function getResizeHoverDirection(params: {
  bbox: CanvasBBox;
  point: Point;
  transformation: Transformation;
}): 'nw' | 'ne' | 'sw' | 'se' | undefined {
  const { bbox, point, transformation } = params;

  const resizeHandleSize = RESIZE_HANDLE_SIZE / transformation.scale;
  const offset = bbox.strokeWidth / 2 + resizeHandleSize;

  const xMin = bbox.x - offset;
  const xMax = bbox.x + bbox.width + offset;
  const yMin = bbox.y - offset;
  const yMax = bbox.y + bbox.height + offset;

  const isHovered = (corner: Point) =>
    areClose(point, corner, resizeHandleSize / 2);

  if (isHovered({ x: xMin, y: yMin })) {
    return 'nw';
  } else if (isHovered({ x: xMax, y: yMax })) {
    return 'se';
  } else if (isHovered({ x: xMin, y: yMax })) {
    return 'sw';
  } else if (isHovered({ x: xMax, y: yMin })) {
    return 'ne';
  } else {
    return undefined;
  }
}

function isBBoxHovered(params: {
  bbox: CanvasBBox;
  point: Point;
  transformation: Transformation;
}): boolean {
  const { bbox, point, transformation } = params;

  const xMin = bbox.x - bbox.strokeWidth / 2;
  const xMax = bbox.x + bbox.width + bbox.strokeWidth / 2;
  const yMin = bbox.y - bbox.strokeWidth / 2;
  const yMax = bbox.y + bbox.height + bbox.strokeWidth / 2;

  const selectStrokeWidth = SELECT_STROKE_WIDTH / transformation.scale;

  return (
    point.x >= xMin - selectStrokeWidth &&
    point.x <= xMax + selectStrokeWidth &&
    point.y >= yMin - selectStrokeWidth &&
    point.y <= yMax + selectStrokeWidth
  );
}
