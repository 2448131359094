import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useWorkspaceStorage } from '@pn/core/storage';
import { useDrawing, type DrawingState } from '@pn/services/drawing';
import assert from 'minimalistic-assert';
import { cloneDeep, isNil } from 'lodash-es';
import React from 'react';

export function useVisualizeDrawingItems() {
  const { workspaceItems } = useWorkspaceStorage();
  const { isInitialized, drawingState, redraw } = useDrawing();

  const itemIdsDrawn = React.useRef<Set<string>>(new Set());

  React.useEffect(() => {
    if (!isInitialized) return;

    const drawingItems = workspaceItems.filter(
      ({ itemType }) => itemType === 'drawing'
    );

    let shouldRedraw = false;

    drawingItems.forEach((item) => {
      const hasVisibilityChanged = setVisibility(item, drawingState);
      if (hasVisibilityChanged) shouldRedraw = true;

      if (itemIdsDrawn.current.has(item.id)) return;

      drawItem(item, drawingState);

      itemIdsDrawn.current.add(item.id);
      shouldRedraw = true;
    });

    for (const itemId of itemIdsDrawn.current) {
      const item = drawingItems.find((i) => i.id === itemId);
      if (isNil(item)) {
        undrawItem(itemId, drawingState);

        itemIdsDrawn.current.delete(itemId);
        shouldRedraw = true;
      }
    }

    if (shouldRedraw) redraw();
  }, [
    isInitialized,
    workspaceItems,
    // the following never change:
    drawingState,
    redraw,
  ]);
}

function setVisibility(
  item: WorkspaceItem,
  drawingState: DrawingState
): boolean {
  let hasChanged = false;

  for (const id in drawingState.features) {
    const feature = drawingState.features[id];

    if (feature.itemId !== item.id || feature.isVisible === item.isVisible)
      continue;

    feature.isVisible = item.isVisible;
    hasChanged = true;
  }

  return hasChanged;
}

export function drawItem(
  item: WorkspaceItem,
  drawingState: DrawingState
): void {
  const data = item.map.layers[0].metadata;
  assert(data, 'data must be defined in the drawing item');
  data.features.forEach((feature) => {
    drawingState.features[feature.id] = cloneDeep(feature);
    drawingState.order.push(feature.id);
  });
}

function undrawItem(
  itemId: WorkspaceItem['id'],
  drawingState: DrawingState
): void {
  const featureIds = Object.values(drawingState.features)
    .filter((feature) => feature.itemId === itemId)
    .map((feature) => feature.id);

  featureIds.forEach((id) => {
    delete drawingState.features[id];
    delete drawingState.featuresSelected[id];
  });
  drawingState.order = drawingState.order.filter(
    (id) => !featureIds.includes(id)
  );
}
