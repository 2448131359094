import type { DataItem } from '@pn/core/domain/data';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { unselectDataItem } from '@pn/core/operations/dataSelection';
import { useRouter } from '@pn/core/operations/router';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { DispositionQuickInfo } from './DispositionQuickInfo';
import { FacilityQuickInfo } from './FacilityQuickInfo';
import { LandPostingQuickInfo } from './LandPostingQuickInfo';
import { LoadingQuickInfo } from './LoadingQuickInfo';
import { MineralRightsQuickInfo } from './MineralRightsQuickInfo';
import { PipelineQuickInfo } from './PipelineQuickInfo';
import { PoolQuickInfo } from './PoolQuickInfo';
import { UnitQuickInfo } from './UnitQuickInfo';
import { WellQuickInfo } from './WellQuickInfo';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 52,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {
    width: 'calc(100% - 44px)',
  },
  actionWrapper: {
    flexShrink: 0,
    width: 44,
    textAlign: 'right',
  },
  typography: {
    fontSize: '0.8rem',
  },
  fontWeight500: {
    fontWeight: 500,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export type QuickInfoPanelProps = {
  classes: ReturnType<typeof useStyles>['classes'];
  dataItem: DataItem;
  onClose: () => void;
};

export const QuickInfo = ({ item }: Props) => {
  const { pathname } = useRouter();

  const { classes } = useStyles();

  const mainPanelOpen = pathname.split('/').length > 3;
  const dataItemSelected = getDataItemSelected(item);

  const handleClose = () => {
    unselectDataItem(item);
  };

  if (mainPanelOpen) {
    return null;
  }

  if (isNil(dataItemSelected)) {
    return <LoadingQuickInfo classes={classes} onClose={handleClose} />;
  }

  const sharedProps: QuickInfoPanelProps = {
    classes,
    dataItem: dataItemSelected,
    onClose: handleClose,
  };

  switch (item.dataType) {
    case 'wells':
    case 'wells_usa':
      return <WellQuickInfo {...sharedProps} />;
    case 'pipelines':
      return <PipelineQuickInfo {...sharedProps} />;
    case 'facilities':
      return <FacilityQuickInfo {...sharedProps} />;
    case 'mineral_rights':
      return <MineralRightsQuickInfo {...sharedProps} />;
    case 'land_postings':
      return <LandPostingQuickInfo {...sharedProps} />;
    case 'units':
      return <UnitQuickInfo {...sharedProps} />;
    case 'pools':
      return <PoolQuickInfo {...sharedProps} />;
    case 'dispositions':
    case 'dispositions_historical':
      return <DispositionQuickInfo {...sharedProps} />;
    default:
      return null;
  }
};
