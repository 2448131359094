import MarkdownToJsx, { type MarkdownToJSX } from 'markdown-to-jsx';

type Props = {
  markdown: string;
  options?: MarkdownToJSX.Options;
};

export const Markdown = ({ markdown, options }: Props) => {
  return <MarkdownToJsx options={options}>{markdown}</MarkdownToJsx>;
};
