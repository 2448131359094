export type Point = {
  x: number;
  y: number;
};

export function areClose(p1: Point, p2: Point, threshold = 0): boolean {
  return (
    Math.abs(p1.x - p2.x) <= threshold && Math.abs(p1.y - p2.y) <= threshold
  );
}
