import { z } from 'zod';

export const GptDataTypeResultSchema = z.object({
  dataType: z
    .enum([
      'wells',
      'wells_usa',
      'pipelines',
      'facilities',
      'mineral_rights',
      'units',
      'pools',
      'dispositions',
      'dispositions_historical',
    ])
    .nullable()
    .describe(`Select 'wells_usa' for all US-related prompts.`),
});

export type GptDataTypeResult = z.infer<typeof GptDataTypeResultSchema>;
