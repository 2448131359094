import { useWorkspaceStorage } from '@pn/core/storage';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isNil } from 'lodash-es';
import React from 'react';
import { DrawingPanel } from 'src/ui/drawing/DrawingPanel';
import { DrawingToolbar } from 'src/ui/drawing/DrawingToolbar';
import { WorkspaceItemPanel } from 'src/ui/workspace/WorkspaceItemPanel';

const WorkspaceItemPanelMux = () => {
  const { idsInWorkspace } = useWorkspaceStorage();
  const { isDrawingPanelOpen, liveItemOpened } = useWorkspaceItemPanel();

  if (isNil(liveItemOpened)) return null;

  switch (liveItemOpened.itemType) {
    case 'drawing':
      if (!isDrawingPanelOpen) return null;

      /* Toolbar is only shown if the drawing is being edited */
      return (
        <>
          {idsInWorkspace.includes(liveItemOpened.id) && <DrawingToolbar />}
          <DrawingPanel item={liveItemOpened} />
        </>
      );
    default:
      return <WorkspaceItemPanel item={liveItemOpened} />;
  }
};

export default React.memo(WorkspaceItemPanelMux);
