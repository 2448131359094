import { z } from 'zod';

const PreQuerySchema = z.object({
  promptIntent: z.string().describe('Intent of the prompt.'),
  // relevantColumns: z
  //   .string()
  //   .describe(
  //     'Describe which columns from the system prompt are relevant to the request.'
  //   ),
  relevantMapping: z
    .string()
    .describe(
      'An array of all JSON objects from the mapping that you will use to construct the query.'
    ),
  analyzeMapping: z.string().describe(
    'Analyze whether the mapping is sufficient to fully satisfy the query.\
      If relevant mapping has hints, describe how they fit (or not) into your logic.\
      Make changes to your selection of columns if necessary.\
      '.replace(/ +/g, ' ')
  ),
  unitConversions: z
    .string()
    .nullable()
    .describe(`Convert numeric units to match their related mapping 'symbol'.`),
  derivedColumns: z
    .string()
    .nullable()
    .describe(
      'Describe which extra columns must be derived to construct the query if applicable.'
    ),
  unableToSatisfy: z.string().nullable().describe(
    'Think about whether the resulting query will return the expected results or yield more or less data than expected.\
      If you cannot fully satisfy the request given the evaluation above, explain why.\
      '.replace(/ +/g, ' ')
  ),
});

const PostQuerySchema = z.object({
  explainSql: z
    .string()
    .describe(`Provide a concise user-friendly summary of the query.`),
  columnsUsed: z.array(z.string()).describe('Columns used in your SQL query.'),
});

export const GptSqlResultSchema = z
  .object({
    preQueryAnalysis: PreQuerySchema,
    sql: z.string().describe(
      `Always use 'SELECT *' to include all columns.\
        Use "LOWER(column_name::text) LIKE '%value%'" for string comparisons on 'string' columns.\
        Add 'NULLS LAST' to the end of all ORDER BY clauses when applicable.\
        Table name: 'sql_table'.\
        This SQL query will be executed by the Polars library. Please ensure the syntax is Polars-compatible and avoid PostgreSQL-specific features.\
        `.replace(/ +/g, ' ')
    ),
    postQueryAnalysis: PostQuerySchema,

    name: z
      .string()
      .describe('Short and descriptive name for the resulting dataset.'),
    colorByProperty: z
      .string()
      .nullable()
      .describe('Column to color by if requested.'),
    color: z
      .string()
      .nullable()
      .describe(
        '#HEX color of the layer; set to null if none was requested or if color is assigned by property.'
      ),
  })
  .strict();

export type GptSqlResult = z.infer<typeof GptSqlResultSchema>;

// export function hasFailed(result: GptSqlResult): boolean {
//   return result.preQueryAnalysis.unableToSatisfy !== null;
// }

export function logResult(result: GptSqlResult & { took: number }): void {
  console.group(`SQL Constructor Response (${result.took / 1000} s)`);

  console.log('%cPre-Query Analysis', 'font-weight: bold');
  Object.entries(result.preQueryAnalysis).forEach(([key, value]) => {
    if (key === 'unableToSatisfy') {
      return value === null
        ? console.log('%cunableToSatisfy:', 'color: #2196F3', value)
        : console.error('unableToSatisfy:', value);
    }
    console.log(key + ':', value);
  });

  console.log('');
  console.log(result.sql);

  console.log('');
  console.log('%cPost-Query Analysis', 'font-weight: bold');
  Object.entries(result.postQueryAnalysis).forEach(([key, value]) => {
    console.log(key + ':', value);
  });

  console.log('');

  console.log('name:', result.name);
  console.log('colorByProperty:', result.colorByProperty);
  console.log('color:', result.color);

  console.groupEnd();
}
