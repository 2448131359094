import type { DataItem, DataItemId, Mapping } from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import { geoJsonDataItemMapper } from './geoJsonDataItemMapper';
import type { GeoJsonDataItem } from './types';

export function geoJsonToData(
  geoJson: GeoJSON.FeatureCollection
): GeoJsonDataItem[] {
  return geoJson.features.map((feature, index) => ({
    ...feature.properties,
    internal_id: extractFeatureId(feature, index),
    geometry: feature.geometry,
  }));
}

function extractFeatureId(feature: GeoJSON.Feature, index: number): DataItemId {
  if (!isNil(feature.properties) && !isNil(feature.properties.internal_id)) {
    return feature.properties.internal_id;
  } else if (!isNil(feature.id)) {
    return feature.id.toString();
  } else {
    return index.toString();
  }
}

export function dataToGeoJson(
  data: DataItem[],
  mapping: Mapping
): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: data.reduce<GeoJSON.Feature[]>((acc, dataItem) => {
      const { geometry, ...properties } =
        geoJsonDataItemMapper(mapping).toTargetDataItem(dataItem);

      if (isNil(geometry)) return acc;

      acc.push({
        type: 'Feature',
        geometry,
        properties,
      });

      return acc;
    }, []),
  };
}
