import { ApiError, ConfigurationError } from '@pn/core/errors';
import { pnTokenManager } from '@pn/core/services/authentication/tokenManager';
import type {
  ApiRequestParams,
  IApiClient,
} from '@pn/core/services/http/ports';
import env from '@pn/core/utils/env';
import { drop, isArray, isNil, isNumber, isObject } from 'lodash-es';
import { makeRequest } from './apiClient';
import { NDJsonParser } from './ndJsonParser';

const { PN_API_URL, PN_API_KEY } = env;

async function streamFromApi({
  method = 'GET',
  url,
  urlTemplate,
  payload,
  raw = false,
  signal,
  onReceiveDataChunk,
  onReceiveTotalCount,
}: ApiRequestParams & { raw?: boolean }) {
  if (!PN_API_URL || !PN_API_KEY) {
    throw new ConfigurationError('Environment file is missing API values.');
  }

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-Api-Key': PN_API_KEY,
  });

  const token = await pnTokenManager.fetchToken();
  if (!isNil(token)) headers.set('Authorization', token);

  const options = {
    method,
    headers,
    body: JSON.stringify(payload),
    signal,
  };

  try {
    const response = await fetch(PN_API_URL + url, options);
    if (!response.body || !response.ok) {
      if (response.status === 400) {
        const json = await response.json();
        if (!isNil(onReceiveTotalCount)) onReceiveTotalCount(json.count);
        return;
      } else {
        throw new ApiError({
          message: `Streaming error: ${await response.text()}`,
          code: response.status,
          method,
          url,
          urlTemplate,
        });
      }
    }

    const reader = response.body?.getReader?.();
    if (!reader) {
      // We will monitor if removal of 'web-streams-polyfill' will cause any issues
      throw new Error('ReadableStream is not supported in this environment');
    }
    const ndJsonParser = new NDJsonParser();
    const textDecoder = new TextDecoder();

    let done: boolean | undefined;
    let value: Uint8Array | undefined;
    while ((({ value, done } = await reader.read()), !done)) {
      const chunk = textDecoder.decode(value);
      const chunkData = raw ? chunk : ndJsonParser.parseChunk(chunk);

      if (
        !isNil(onReceiveTotalCount) &&
        !isNil(onReceiveDataChunk) &&
        isArray(chunkData) &&
        isObject(chunkData[0]) &&
        'total_count' in chunkData[0] &&
        isNumber(chunkData[0].total_count)
      ) {
        /**
         * Extract total_count from the first JSON object in the chunk and pass
         * it along with the rest of the chunk data to relevant callbacks.
         */
        onReceiveTotalCount(chunkData[0].total_count);
        onReceiveDataChunk(drop(chunkData));
      } else if (!isNil(onReceiveDataChunk)) {
        onReceiveDataChunk(chunkData);
      }
    }
  } catch (error) {
    if (signal?.aborted) return;

    throw new ApiError({
      message: `Unexpected streaming error: ${error instanceof Error ? error.message : String(error)}`, // ensures even non-Error types are stringified
      code: -1,
      method,
      url,
      urlTemplate,
    });
  }
}

export const pnApiClient: IApiClient = {
  request: (params) =>
    makeRequest({
      baseUrl: PN_API_URL,
      tokenManager: pnTokenManager,
      extraHeaders: {
        'X-Api-Key': PN_API_KEY,
      },
      params,
    }),
  stream: streamFromApi,
};
