import { safeMap } from '@pn/core/utils/logic';
import { apiListMapper } from '@pn/services/api/list/apiListMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import type { ApiList } from './types';

const getListWorkspaceItems = async () => {
  const apiLists = await pnApiClient.request<ApiList[]>({
    url: 'v2/lists',
  });

  const workspaceItems = safeMap(apiLists, apiListMapper.toWorkspaceItem);

  return workspaceItems;
};

const getListWorkspaceItemById = async (listId: string) => {
  const apiList = await pnApiClient.request<ApiList>({
    url: `v2/lists/${listId}`,
  });

  const workspaceItem = apiListMapper.toWorkspaceItem(apiList);

  return workspaceItem;
};

export const apiListProvider = {
  getListWorkspaceItems,
  getListWorkspaceItemById,
};
