import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import LockIcon from '@mui/icons-material/Lock';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import { dependencies } from '@pn/core/dependencies';
import type { Mapping } from '@pn/core/domain/data';
import type { TableTemplate } from '@pn/core/domain/table';
import {
  useTableFields,
  useTableTemplates,
} from '@pn/core/operations/dataTableInteractions';
import { tableActions, workspaceActions } from '@pn/core/storage';
import { yamlTableTemplateMapper } from '@pn/services/local/table/yamlTableTemplateMapper';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { LoadingState } from '@pn/ui/custom-components/LoadingState';
import assert from 'minimalistic-assert';
import download from 'downloadjs';
import { isEmpty, isNil, isString } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const LIST_ITEM_HEIGHT = 64;

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  label: {
    position: 'absolute',
    left: 10,
    top: -10,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  input: {
    borderRadius: theme.shape.borderRadius,
  },
  list: {
    maxHeight: LIST_ITEM_HEIGHT * 4 + 2,
    overflow: 'auto',
  },
  listItem: {
    '&:hover': {
      '.actionButton': {
        display: 'inline-flex',
      },
    },
  },
  secondaryAction: {
    right: theme.spacing(1.5),
  },
  listItemButton: {
    height: LIST_ITEM_HEIGHT,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1.5),
    paddingRight: `100px !important`,
  },
  actionButton: {
    display: 'none',
  },
  inputAdornment: {
    fontSize: theme.typography.body2.fontSize,
  },
  lockIcon: {
    position: 'relative',
    top: 2,
    fontSize: 16,
    color: theme.palette.action.active,
    marginLeft: theme.spacing(0.5),
  },
}));

type Props = {
  disabled: boolean;
  sourceLayerId: string;
  mapping: Mapping;
};

export function ColumnsTemplates({ disabled, sourceLayerId, mapping }: Props) {
  const { notificationService } = dependencies;

  const { classes, cx, theme } = useStyles();

  const { fields } = useTableFields(sourceLayerId);
  const {
    isFetchingTemplates,
    sourceLayerTemplates,
    isTemplateSelected,
    selectTemplate,
    createTemplate,
    deleteTemplate,
  } = useTableTemplates(sourceLayerId);

  const [templateName, setTemplateName] = React.useState('');

  const handleSave = () => {
    createTemplate({ name: templateName, fields, mapping });
    setTemplateName('');
  };

  const uploadTemplate = (file: File | undefined) => {
    if (isNil(file)) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        assert(
          isString(event.target?.result),
          'Invalid YAML template file: assertion failed'
        );

        const template = await yamlTableTemplateMapper.toDomainTemplate(
          event.target?.result
        );

        tableActions().create(template);
        tableActions().updateTableFields(
          template.sourceLayerId,
          template.fields
        ); // cannot use `select` because template ID will change after sync-storage

        workspaceActions().addToWorkspace(template.sourceLayerId);
        workspaceActions().select(template.sourceLayerId);

        notificationService.notify('Template imported successfully!');
      } catch {
        notificationService.notify('Invalid YAML template file', 'error');
      }
    };
    reader.readAsText(file);
  };

  const downloadTemplate = async (template: TableTemplate) => {
    const yaml = await yamlTableTemplateMapper.toTargetTemplatePayload(
      template,
      mapping
    );
    download(yaml, `${template.name}.yaml`, 'text/yaml');
  };

  return (
    <Paper variant="outlined" className={classes.container}>
      <Box display="flex" gap={1.5} p={1.5}>
        <Tooltip title="Import YAML template">
          <span>
            <IconButton
              component="label"
              role={undefined}
              tabIndex={-1}
              disabled={disabled}
              onClick={(e) => e.stopPropagation()}
            >
              <UploadFileIcon />
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => uploadTemplate(e.target.files?.[0])}
              />
            </IconButton>
          </span>
        </Tooltip>
        <Box flexGrow={1}>
          <TextField
            fullWidth
            variant="filled"
            size="small"
            hiddenLabel
            placeholder="New template"
            disabled={disabled}
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            slotProps={{
              input: {
                className: classes.input,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={cx(
                      classes.inputAdornment,
                      disabled ? classes.disabledText : undefined
                    )}
                  >
                    {fields.length} columns
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        <CustomButton disabled={disabled} onClick={handleSave}>
          Save
        </CustomButton>
      </Box>

      <Divider />

      {isFetchingTemplates ? (
        <Box className={classes.container}>
          <LoadingState text="Loading..." />
        </Box>
      ) : isEmpty(sourceLayerTemplates) ? (
        <Box className={classes.container}>
          <EmptyStateWithIcon text="No saved templates" icon={ViewKanbanIcon} />
        </Box>
      ) : (
        <List disablePadding className={classes.list}>
          {sourceLayerTemplates.map((template) => (
            <ListItem
              key={template.id}
              classes={{
                root: classes.listItem,
                secondaryAction: classes.secondaryAction,
              }}
              disablePadding
              secondaryAction={
                <span>
                  <IconButton
                    title="Download"
                    className={cx(classes.actionButton, 'actionButton')}
                    disabled={disabled}
                    onClick={() => downloadTemplate(template)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    title="Delete"
                    className={cx(classes.actionButton, 'actionButton')}
                    disabled={disabled || template.isGlobal}
                    onClick={() => deleteTemplate(template)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              }
            >
              <ListItemButton
                className={classes.listItemButton}
                onClick={() => selectTemplate(template)}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    style={{
                      backgroundColor: isTemplateSelected(template)
                        ? theme.palette.primary.main
                        : theme.palette.action.disabled,
                    }}
                  >
                    <ViewKanbanIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span>
                      {template.name}
                      {template.isGlobal && (
                        <LockIcon className={classes.lockIcon} />
                      )}
                    </span>
                  }
                  secondary={`${template.fields.length} columns`}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
