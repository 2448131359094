import { MapMode } from '@pn/core/domain/map';
import { elevationContours } from '@pn/services/map/mapbox/style/pn/elevationContours';
import { grids } from '@pn/services/map/mapbox/style/pn/grids';
import { clone, isNil, set } from 'lodash-es';
import type { MapboxIMap } from '..';

export const updateMode: MapboxIMap['updateMode'] = async function (
  this: MapboxIMap,
  newMode
) {
  const style = this._native.getStyle();

  const updatedStyle = await updateStyleLayers(style, newMode);
  this._native.setStyle(updatedStyle);
};

export async function updateStyleLayers(
  style: mapboxgl.Style,
  mapMode: MapMode
): Promise<mapboxgl.Style> {
  let newStyleLayers: mapboxgl.AnyLayer[] = [];
  let satellite = false;

  switch (mapMode) {
    case MapMode.Standard: {
      newStyleLayers = (
        await import('@pn/services/map/mapbox/style/standardMapboxLayers')
      ).standardMapboxLayers;
      break;
    }
    case MapMode.Light: {
      newStyleLayers = (
        await import('@pn/services/map/mapbox/style/lightMapboxLayers')
      ).lightMapboxLayers;
      break;
    }
    case MapMode.Dark: {
      newStyleLayers = (
        await import('@pn/services/map/mapbox/style/darkMapboxLayers')
      ).darkMapboxLayers;
      break;
    }
    case MapMode.Satellite: {
      satellite = true;
      break;
    }
    default:
      throw new Error('Invalid map mode');
  }

  const mode = [MapMode.Standard, MapMode.Light].includes(mapMode)
    ? 'light'
    : 'dark';

  const dynamicallyStyledLayers = new Map<string, mapboxgl.Layer>(
    [...elevationContours(mode), ...grids(mode)].map((layer) => [
      layer.id,
      layer as mapboxgl.Layer,
    ])
  );

  const updatedStyle = clone(style);

  for (let i = updatedStyle.layers.length - 1; i >= 0; i--) {
    const layer = updatedStyle.layers[i] as mapboxgl.Layer;

    if (dynamicallyStyledLayers.has(layer.id)) {
      set(layer, 'paint', dynamicallyStyledLayers.get(layer.id)!.paint);
    }

    if (layer.id === 'mapbox-satellite') {
      set(layer, 'layout.visibility', satellite ? 'visible' : 'none');
    }

    if (!isNil(layer.metadata) && layer.metadata['pn:built-in']) {
      updatedStyle.layers.splice(i, 1);
    }
  }

  updatedStyle.layers.unshift(...newStyleLayers);

  return updatedStyle;
}
