import {
  formatDataType,
  type DataItem,
  type DataItemId,
} from '@pn/core/domain/data';
import { getDataItemSelected, WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { dataDetailsActions } from '@pn/core/storage';
import { apiDataInfoProvider } from '@pn/services/api/data-info/apiDataInfoProvider';
import { localDataInfoProvider } from '@pn/services/local/data-info/localDataInfoProvider';
import { isNil } from 'lodash-es';
import React from 'react';

let lastIdRequested: DataItemId = '';
async function getApiDataDetails(params: {
  itemId: WorkspaceItem['id'];
  dataType: string;
  id: DataItemId;
}) {
  const { itemId, dataType, id } = params;

  try {
    lastIdRequested = id;
    dataDetailsActions(itemId).request();

    const details = await apiDataInfoProvider.getDataDetails(dataType, id);

    if (lastIdRequested !== id) return;
    dataDetailsActions(itemId).receive(details);
  } catch (error) {
    handleError({
      error,
      onError: dataDetailsActions(itemId).error,
      userFriendlyMessage: `Failed to load ${formatDataType(dataType)} details`,
    });
  }
}

async function getLocalDataDetails(
  item: WorkspaceItem,
  dataItem: DataItem | undefined
) {
  if (isNil(dataItem)) {
    throw new Error('Unable to fetch details when data item is undefined');
  }

  try {
    dataDetailsActions(item.id).request();

    const details = await localDataInfoProvider.getDataDetails(
      dataItem,
      item.mapping
    );

    dataDetailsActions(item.id).receive(details);
  } catch (error) {
    handleError({
      error,
      onError: dataDetailsActions(item.id).error,
      userFriendlyMessage: `Failed to load ${formatDataType(
        item.dataType
      )} details`,
    });
  }
}

export function useAutoGetDataDetails(
  item: WorkspaceItem,
  id: DataItemId | undefined
) {
  React.useEffect(() => {
    if (item.detailsSource !== 'api' || isNil(id)) return;

    getApiDataDetails({ itemId: item.id, dataType: item.dataType, id });
  }, [id, item.detailsSource, item.id, item.dataType]);

  React.useEffect(() => {
    if (item.detailsSource !== 'local') return;

    getLocalDataDetails(item, getDataItemSelected(item));
  }, [item]);
}
