import { deflateSync, inflateSync } from 'fflate';

export function compressAndEncode<T>(param: T): string {
  const compressed = deflateSync(
    new TextEncoder().encode(JSON.stringify(param))
  );

  const base64 = btoa(String.fromCharCode(...compressed));

  /**
   * Make the result URL-safe by replacing '+' with '-', '/' with '_',
   * and by stripping '='
   */
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function decodeAndDecompress<T>(encodedString: string): T {
  const base64 = encodedString.replace(/-/g, '+').replace(/_/g, '/');

  const compressed = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  const decompressed = new TextDecoder().decode(inflateSync(compressed));

  return JSON.parse(decompressed);
}
