import { Box, ButtonBase, Paper, Typography } from '@mui/material';
import React from 'react';

type Props = {
  onClick: (prompt: string) => void;
};

const examples = [
  'Find all wells licensed to Tourmaline drilled since 2020',
  'Find all lateral wells in the Duvernay formation',
  'Get top 10 wells in Alberta by recent gas production',
  'Find all active mineral rights with a bonus greater than $10M',
];

export const PromptExamples = ({ onClick }: Props) => {
  const randomExamples = React.useMemo(() => pickRandom(examples, 2), []);

  return (
    <Box sx={{ display: 'grid', gap: 2 }}>
      {randomExamples.map((example) => (
        <ExampleCard key={example} prompt={example} onClick={onClick} />
      ))}
    </Box>
  );
};

type ExampleCardProps = {
  prompt: string;
  onClick: (prompt: string) => void;
};

const ExampleCard = ({ prompt, onClick }: ExampleCardProps) => {
  return (
    <Paper variant="outlined">
      <ButtonBase
        onClick={() => onClick(prompt)}
        sx={{ p: 2, textAlign: 'left' }}
      >
        <Typography variant="body2" color="textSecondary">
          "{prompt}"
        </Typography>
      </ButtonBase>
    </Paper>
  );
};

function pickRandom(options: string[], count: number) {
  const shuffled = options.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}
