import { hashFeatureId } from '@pn/core/domain/map';
import type { MapboxIMap } from '..';

export const setFeatureState: MapboxIMap['setFeatureState'] = function (
  this: MapboxIMap,
  params
) {
  const { sourceId, sourceLayer = undefined, featureId, featureState } = params;

  if (!this._native.getSource(sourceId)) return;

  this._native.setFeatureState(
    {
      source: sourceId,
      sourceLayer,
      id: hashFeatureId(featureId),
    },
    featureState
  );
};
