import type { DataItemId } from '@pn/core/domain/data';
import { ApiError } from '@pn/core/errors';
import type { IDataInfoProvider } from '@pn/core/providers/data-info/ports';
import { apiStackCompanyDocumentMapper } from '@pn/services/api/data-info/apiStackCompanyDocumentMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { stackApiClient } from '@pn/services/api/stackApiClient';
import { apiDataInfoCountsMapper } from './apiDataInfoCountsMapper';
import { apiDataInfoDetailsMapper } from './apiDataInfoDetailsMapper';
import { apiDataInfoDocumentMapper } from './apiDataInfoDocumentMapper';
import type {
  ApiDataCounts,
  ApiDetails,
  ApiDocument,
  ApiStackCompanyDocument,
} from './types';

const getDataDetails = async (dataType: string, id: DataItemId) => {
  const apiDetails = await pnApiClient.request<ApiDetails>({
    url: `v2/${dataType}/${id}/details`,
  });

  const details = apiDataInfoDetailsMapper.toDomainDetails(apiDetails);

  return details;
};

const getDataDocuments = async (dataType: string, id: DataItemId) => {
  const apiDocuments = await pnApiClient.request<ApiDocument[]>({
    url: `v2/${dataType}/${id}/documents`,
  });

  const documents = apiDocuments.map(
    apiDataInfoDocumentMapper.toDomainDocument
  );

  return documents;
};

const getStackCompanyDocuments = async (dataType: string, id: DataItemId) => {
  if (!['wells', 'wells_usa'].includes(dataType)) {
    throw new Error('Unsupported data type for Stack company documents');
  }

  try {
    const apiDocuments = await stackApiClient.request<
      ApiStackCompanyDocument[]
    >({
      url: `PetroNinjaUserApi/AssetDocsForUwi/${id}`,
    });

    const documents = apiDocuments.map(
      apiStackCompanyDocumentMapper.toDomainDocument
    );

    return documents;
  } catch (error) {
    if (error instanceof ApiError && error.code === 404) return [];
    else throw error;
  }
};

const getDataCounts = async (dataType: string, id: DataItemId) => {
  const apiCounts = await pnApiClient.request<ApiDataCounts>({
    url: `v2/${dataType}/${id}/counts`,
  });

  const counts = apiDataInfoCountsMapper.toDomainCounts(apiCounts);

  return counts;
};

const getDataReport = async (dataType: string, id: DataItemId) => {
  let url: string;

  switch (dataType) {
    case 'wells':
      url = `v1/wells/${id}/detailed`;
      break;
    case 'wells_usa':
      url = `v1/usa/wells/${id}/detailed`;
      break;
    case 'mineral_rights':
      url = `v2/mineral_rights/${id}/details`;
      break;
    default:
      throw new Error('Unsupported report data type');
  }

  const apiReport = await pnApiClient.request({ url });

  return apiReport; // no domain mapping needed
};

export const apiDataInfoProvider: IDataInfoProvider = {
  getDataDetails,
  getDataDocuments,
  getStackCompanyDocuments,
  getDataCounts,
  getDataReport,
};
