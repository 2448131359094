import { cachePolicy } from '@pn/core/cachePolicy';
import type { Mapping } from '@pn/core/domain/data';
import type { ApiDataSource } from '@pn/core/domain/workspace';
import { ApiError } from '@pn/core/errors';
import type { IDataProvider } from '@pn/core/providers/data/ports';
import { getCurrentUser } from '@pn/core/storage/user/currentUserStorage';
import { intelMappings } from '@pn/resources/intel-mappings';
import { pnMappings } from '@pn/resources/pn-mappings';
import { stackMappings } from '@pn/resources/stack-mappings';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiQueryMapper } from '@pn/services/api/query/apiQueryMapper';
import { isEmpty } from 'lodash-es';
import { apiDataItemMapper } from './apiDataItemMapper';
import type { ApiDataItem } from './types';

const mappingsCache = new Map<string, Mapping>();

export const apiDataProvider: IDataProvider<ApiDataSource> = (dataSource) => ({
  getDataMapping: async (item) => {
    if (item.isMappingInitialized) return item.mapping;

    if (cachePolicy.mappings && mappingsCache.has(item.dataType)) {
      return mappingsCache.get(item.dataType)!;
    }

    let mapping = (() => {
      switch (dataSource.source) {
        case 'parquet':
          return pnMappings[item.dataType];
        case 'postgres':
          return intelMappings[item.dataType];
      }
    })();

    const stackLayers = getCurrentUser()?.enterprise?.stackLayers ?? {}; // HACK

    if (stackLayers[item.dataType]) {
      const stackMapping = stackMappings[item.dataType] ?? [];

      if (!isEmpty(stackMapping)) {
        mapping = { ...stackMapping, ...mapping };

        console.log(
          `%cAdded Stack mapping for ${item.dataType}`,
          'color: #5C6BC0'
        );
      }
    }

    if (cachePolicy.mappings) mappingsCache.set(item.dataType, mapping);

    return mapping;
  },

  getDataByQuery: async ({ query, mapping }) => {
    const apiQuery = apiQueryMapper(mapping).toTargetQuery(query);

    const {
      data: apiData,
      total_hits,
      page,
      retrieval_time,
    } = await pnApiClient.request<{
      data: ApiDataItem[];
      total_hits: number;
      page: number;
      retrieval_time: number;
    }>({
      method: 'POST',
      url: dataSource.url + '/scroll',
      urlTemplate: dataSource.url + '/scroll', // preserve :data_type
      payload: {
        query: apiQuery,
      },
    });

    const data = apiData.map(apiDataItemMapper(mapping).toDomainDataItem);

    return {
      data,
      totalCount: total_hits,
      page,
      retrievalTime: retrieval_time,
    };
  },

  streamDataByQuery: async ({
    query,
    mapping,
    receiveTotalCount,
    receiveChunk,
    signal,
  }) => {
    const apiQuery = apiQueryMapper(mapping).toTargetQuery(query);

    switch (dataSource.source) {
      case 'parquet':
        return pnApiClient.stream({
          method: 'POST',
          url: dataSource.url + '/stream',
          urlTemplate: dataSource.url + '/stream', // preserve :data_type
          payload: {
            query: apiQuery,
            ignore_limit: query.ignoreLimit,
            total_count: true,
          },
          signal,
          onReceiveTotalCount: receiveTotalCount,
          onReceiveDataChunk: (apiData: ApiDataItem[]) => {
            const data = apiData.map(
              apiDataItemMapper(mapping).toDomainDataItem
            );
            receiveChunk(data);
          },
        });
      case 'postgres':
        try {
          const { data: apiData, total_count } = await pnApiClient.request<{
            data: ApiDataItem[];
            total_count: number;
          }>({
            method: 'POST',
            url: dataSource.url + '/stream',
            urlTemplate: dataSource.url + '/stream', // preserve :data_type
            payload: {
              query: apiQuery,
              ignore_limit: query.ignoreLimit,
            },
          });

          const data = apiData.map(apiDataItemMapper(mapping).toDomainDataItem);

          receiveTotalCount(total_count);
          receiveChunk(data);
        } catch (error) {
          if (error instanceof ApiError) {
            if (error.code === 400) {
              return; // exceeded safe limit, do nothing
            }
          }
          throw error;
        }
    }
  },
});
