import React from 'react';

function getDeviceOrientation() {
  if (window.screen && window.screen.orientation) {
    // New API (all major browsers and webviews)
    if (window.screen.orientation.type.startsWith('portrait')) {
      return 'portrait';
    } else {
      return 'landscape';
    }
  } else {
    // Fallback
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }
}

export function useDeviceOrientation() {
  const [isInPortraitMode, setIsInPortraitMode] = React.useState(
    getDeviceOrientation()
  );

  React.useEffect(() => {
    const handleOrientationChange = () => {
      setIsInPortraitMode(getDeviceOrientation());
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    setIsInPortraitMode(getDeviceOrientation());

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return isInPortraitMode;
}
