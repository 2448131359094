import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Typography } from '@mui/material';
import {
  TreeItem,
  type TreeViewBaseItem,
  useTreeItemModel,
  type TreeItemProps,
} from '@mui/x-tree-view';
import { useAccess } from '@pn/core/permissions/access';
import { STACKDX_URL } from '@pn/core/urls';
import { isNil } from 'lodash-es';
import React from 'react';
import { mobileDownload } from 'src/ui/main-panel/panels/documents/file';
import type { CustomTreeItem } from 'src/ui/main-panel/panels/documents/utils';

export type DocumentTreeItemProps = TreeItemProps & { isMobile?: boolean };

export const DocumentTreeItem = React.forwardRef(function DocumentTreeItem(
  props: DocumentTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const item = useTreeItemModel<CustomTreeItem>(props.itemId);

  return (
    <TreeItem
      {...props}
      ref={ref}
      slots={{
        label: CustomLabel,
      }}
      slotProps={{
        label: {
          isMobile: props.isMobile,
          item,
        } as CustomLabelProps,
        checkbox: {
          color: 'secondary',
        },
      }}
    />
  );
});

type CustomLabelProps = {
  children: string;
  className: string;
  isMobile?: boolean;
  item: TreeViewBaseItem<CustomTreeItem>;
};

function CustomLabel({
  children,
  className,
  isMobile = false,
  item,
}: CustomLabelProps) {
  const access = useAccess();

  const handleMobileDownload = () => {
    if (access('documents.export').notify().denied()) return;

    mobileDownload(item.downloadUrl!);
  };

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        wordBreak: 'break-word',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" component="span">
          {children}
        </Typography>
        {item.secondaryLabel && (
          <Typography variant="caption" color="textSecondary">
            {item.secondaryLabel}
          </Typography>
        )}
      </Box>

      {!isNil(item.openUrl) ? (
        <IconButton
          size="small"
          component="a"
          href={STACKDX_URL + item.openUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowOutwardIcon color="action" />
        </IconButton>
      ) : (
        isMobile &&
        !isNil(item.downloadUrl) && (
          <IconButton size="small" onClick={handleMobileDownload}>
            <DownloadIcon color="action" />
          </IconButton>
        )
      )}
    </Box>
  );
}
