import type { Mapping } from '@pn/core/domain/data';
import dispositionsMapping from './dispositions.jsonc';
import dispositionsHistoricalMapping from './dispositions_historical.jsonc';
import facilitiesMapping from './facilities.jsonc';
import gridsMapping from './grids.jsonc';
import landPostingsMapping from './land_postings.jsonc';
import mineralRightsMapping from './mineral_rights.jsonc';
import openMineralRightsMapping from './open_mineral_rights.jsonc';
import pipelinesMapping from './pipelines.jsonc';
import poolsMapping from './pools.jsonc';
import unitsMapping from './units.jsonc';
import wellsMapping from './wells.jsonc';
import wellsUsaMapping from './wells_usa.jsonc';

export const pnMappings: Record<string, Mapping> = setFields({
  wells: wellsMapping,
  wells_usa: wellsUsaMapping,
  pipelines: pipelinesMapping,
  facilities: facilitiesMapping,
  mineral_rights: mineralRightsMapping,
  open_mineral_rights: openMineralRightsMapping,
  land_postings: landPostingsMapping,
  units: unitsMapping,
  pools: poolsMapping,
  dispositions: dispositionsMapping,
  dispositions_historical: dispositionsHistoricalMapping,
  grids: gridsMapping,
});

/**
 * @deprecated this is a hack! No easy way to fix though.
 */
export function setFields(
  record: Record<string, Mapping>
): Record<string, Mapping> {
  Object.values(record).forEach((mapping) => {
    Object.entries(mapping).forEach(([field, item]) => {
      item!.field = field;
    });
  });

  return record;
}
