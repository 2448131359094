import { ApplicationError } from '@pn/core/errors';
import {
  type GptDataTypeResult,
  GptDataTypeResultSchema,
} from '@pn/core/services/gpt/GptDataTypeResult';
import type { IGptService } from '@pn/core/services/gpt/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import assert from 'minimalistic-assert';
import OpenAI from 'openai';
import { zodToJsonSchema } from 'zod-to-json-schema';

export const inferDataType: IGptService['inferDataType'] = async (messages) => {
  const request: OpenAI.Chat.ChatCompletionCreateParams = {
    model: 'gpt-4o-2024-08-06',
    temperature: 0.1,
    top_p: 0.1,
    seed: 0,
    messages: [
      {
        role: 'system',
        content: getSystemMessage(),
      },
      ...messages,
    ],
    response_format: {
      type: 'json_schema',
      json_schema: {
        name: 'schema',
        schema: zodToJsonSchema(GptDataTypeResultSchema),
      },
    },
  };

  const start = Date.now();

  const response = await pnApiClient.request<OpenAI.Chat.ChatCompletion>({
    method: 'POST',
    url: 'v2/gpt/chat_completions',
    payload: { request },
  });

  assert(response.choices[0].message.content, 'No response content present');
  const gptResult: GptDataTypeResult = JSON.parse(
    response.choices[0].message.content
  );

  if (gptResult.dataType === null) {
    throw new ApplicationError('Cannot infer data type from prompt.');
  }

  return {
    dataType: gptResult.dataType,
    took: Date.now() - start,
  };
};

function getSystemMessage(): string {
  return `You are an oil & gas expert tasked with generating JSON output based on a schema.\
  Infer the 'dataType' from user prompt. Set to null if the prompt does not provide sufficient/relevant information.`;
}
