import { hashFeatureId } from '@pn/core/domain/map';
import type { MapboxIMap } from '..';

export const unsetFeatureState: MapboxIMap['unsetFeatureState'] = function (
  this: MapboxIMap,
  params
) {
  const { sourceId, sourceLayer, featureId, featureStateFields } = params;

  if (!this._native.getSource(sourceId)) return;

  featureStateFields.forEach((featureStateField) => {
    this._native.removeFeatureState(
      {
        source: sourceId,
        sourceLayer,
        id: hashFeatureId(featureId),
      },
      featureStateField
    );
  });
};
