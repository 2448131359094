import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import type { UnitSystem } from '@pn/core/domain/types';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { EXPORT_LIMIT } from '@pn/core/limits';
import { useMultiExport } from '@pn/core/operations/export';
import { UnauthorizedAlert } from '@pn/ui/custom-components/UnauthorizedAlert';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { ExportsTable } from '@pn/ui/data-table/components/ExportsTable';
import { isEmpty, isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fadedLabel: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: `${theme.spacing(2)} !important`,
  },
  selectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: 420,
  },
  button: {
    height: 40,
    minWidth: 115,
  },
  infoIcon: {
    position: 'absolute',
    top: 7,
    right: -38,
  },
}));

type Props = {
  item: WorkspaceItem;
  isDownloadDisabled: boolean;
  open: boolean;
  onClose: () => void;
};

export const ExportsDialog = ({
  item,
  isDownloadDisabled,
  open,
  onClose,
}: Props) => {
  const { classes } = useStyles();

  const {
    validExportOptions,
    selectedExportOption,
    setSelectedExportOption,
    isExporting,
    exportUnits,
    setExportUnits,
    handleMultiExport,
  } = useMultiExport({
    item,
    includeTableExport: true,
  });

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <span className={classes.fadedLabel}>
          {formatDataType(item.dataType, {
            case: 'sentence',
          })}{' '}
          /
        </span>{' '}
        Export
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.selectorContainer}>
          <UnauthorizedAlert path="dataTable.export" />

          <Box position="relative">
            <Autocomplete
              fullWidth
              size="small"
              disableClearable
              value={selectedExportOption}
              options={validExportOptions}
              getOptionLabel={(option) => `${option.name} (.${option.format})`}
              onChange={(_e, value) => setSelectedExportOption(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Export type"
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                />
              )}
            />

            {!isEmpty(selectedExportOption?.description) && (
              <Tooltip title={selectedExportOption?.description}>
                <InfoIcon color="action" className={classes.infoIcon} />
              </Tooltip>
            )}
          </Box>

          <Box display="flex" gap={2}>
            <Box flex={1}>
              <FormControl fullWidth>
                <InputLabel id="export-units-select-label">
                  Export units
                </InputLabel>
                <Select
                  size="small"
                  labelId="export-units-select-label"
                  label="Export units"
                  value={exportUnits}
                  onChange={(e) => setExportUnits(e.target.value as UnitSystem)}
                >
                  <MenuItem value="metric">Metric</MenuItem>
                  <MenuItem value="imperial">Imperial</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <PermissionButton
              permissionPath="dataTable.export"
              isCustom
              className={classes.button}
              disabled={isDownloadDisabled || isExporting}
              onClick={handleMultiExport}
            >
              Download
            </PermissionButton>
          </Box>
          {(isNil(item.totalCount) || item.totalCount === 0) && (
            <Alert severity="warning">
              Select up to {EXPORT_LIMIT.toLocaleString()}{' '}
              {formatDataType(item.dataType)} to export.
            </Alert>
          )}
          {!isNil(item.totalCount) && item.totalCount > EXPORT_LIMIT && (
            <Alert severity="warning">
              Cannot export more than {EXPORT_LIMIT.toLocaleString()}{' '}
              {formatDataType(item.dataType)}.
            </Alert>
          )}
        </Box>

        <ExportsTable />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
