import CloseIcon from '@mui/icons-material/Close';
import {
  alpha,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import React from 'react';
import { ProjectsGrid } from 'src/ui/workspace/library/LibraryData/ProjectsGrid';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';
import { LibraryBreadcrumbs } from './LibraryBreadcrumbs';
import { LibraryData } from './LibraryData';
import { useLibrary } from './LibraryProvider';
import { LibrarySearch } from './LibrarySearch';
import { LibrarySidePanel } from './LibrarySidePanel';

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'grid',
    gridTemplateAreas: `
      "app-bar app-bar"
      "side-panel content"
    `,
    gridTemplateRows: 'min-content',
    gridTemplateColumns: 'min-content',
    width: 'calc(100% - 300px)',
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).library,
    '@media print': {
      display: 'none',
    },
  },
  appBar: {
    gridArea: 'app-bar',
    background: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.secondary.main,
  },
  content: {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    overflow: 'auto',
  },
}));

const Library = () => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const {
    isLibraryOpen,
    searchText,
    setSearchText,
    skipSearchValueDebounce,
    closeLibrary,
  } = useLibrary();

  if (smScreen) {
    return (
      <Dialog fullScreen open={isLibraryOpen} onClose={closeLibrary}>
        <DialogTitle>Projects</DialogTitle>
        <DialogContent>
          <ProjectsGrid showAll />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLibrary}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (!isLibraryOpen) return null;

  return (
    <Box className={classes.container}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          <IconButton
            aria-label="menu"
            size="large"
            edge="start"
            sx={{ mr: 1 }}
            onClick={closeLibrary}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            component="div"
            variant="h6"
            color="textSecondary"
            sx={{ flex: '0 0 auto' }}
          >
            Petro Ninja <span className={classes.title}>Library</span>
          </Typography>
          <Box ml="40px" />
          <LibrarySearch
            searchText={searchText}
            setSearchText={setSearchText}
            skipDebounce={skipSearchValueDebounce}
          />
        </Toolbar>
      </AppBar>

      <LibrarySidePanel />

      <Box className={classes.content}>
        <LibraryBreadcrumbs />
        <LibraryData />
      </Box>
    </Box>
  );
};

export default React.memo(Library);
