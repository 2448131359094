import type { DataItemId } from '@pn/core/domain/data';
import type { ZonalQuery } from '@pn/core/domain/zone';
import { hasKey } from '@pn/core/utils/logic';
import { isObject } from 'lodash-es';

export type KeyValueRow = {
  key: string;
  value: unknown;
  isButton: boolean;
  url?: string;
};
export type MultiColumnRow = Record<string, unknown>;

export type Column = {
  field: string;
  value: string;
  isButton: boolean;
  symbol?: string;
  module?: 'visualize_source_layer';
};

type FormationsViewerModule = {
  type: 'formations_viewer';
  province: 'ab' | 'bc' | 'sk';
  zonalQueries: ZonalQuery[];
  regions: number[];
};

export type Table = {
  title: string;
  tableType?: string;
  professional: boolean;
  noExport: boolean;
  disablePagination: boolean;
  module?: FormationsViewerModule;
};
export type KeyValueTable = Table & {
  rows: KeyValueRow[];
};
export function isKeyValueTable(arg: unknown): arg is KeyValueTable {
  return isObject(arg) && !hasKey(arg, 'columns');
}
export type MultiColumnTable = Table & {
  rows: MultiColumnRow[];
  columns: Column[];
  transpose: boolean;
};
export function isMultiColumnTable(arg: unknown): arg is MultiColumnTable {
  return isObject(arg) && hasKey(arg, 'columns');
}
export type LinkedIdsTable = Table & {
  rows: MultiColumnRow[];
  columns: Column[];
  linkedCoordinates: [number, number][];
  linkedIds: DataItemId[];
  linkedIdsType: string;
  transpose: boolean;
};
export function isLinkedIdsTable(arg: unknown): arg is LinkedIdsTable {
  return (
    isObject(arg) && hasKey(arg, 'linkedIds') && hasKey(arg, 'linkedIdsType')
  );
}

export type DetailsTable = KeyValueTable | MultiColumnTable | LinkedIdsTable;
export type Details = {
  tabName: string;
  tables: DetailsTable[];
}[];

export const dataTypesWithCounts: string[] = [
  'wells',
  'wells_usa',
  'pipelines',
];

export type Document = {
  source: 'pn' | 'stack';
  id: string;
  name: string;
  title?: string;
  category?: string;
  folder?: string;
  url?: string;
  downloadUrl: string;
};

export type DataCounts = {
  documents: number;
};
