import type { Point } from '@pn/core/domain/point';
import mapboxgl from 'mapbox-gl';

export function toMapboxPoint(point: Point): mapboxgl.Point {
  return new mapboxgl.Point(point.x, point.y);
}

export function toMapboxBBox(
  bbox: [Point, Point]
): [mapboxgl.Point, mapboxgl.Point] {
  return [toMapboxPoint(bbox[0]), toMapboxPoint(bbox[1])];
}

export function bBoxToMapboxBBox(
  mapboxMap: mapboxgl.Map,
  bbox: GeoJSON.BBox
): [mapboxgl.Point, mapboxgl.Point] {
  const sw = mapboxMap.project([bbox[0], bbox[1]]);
  const ne = mapboxMap.project([bbox[2], bbox[3]]);

  return [sw, ne];
}
