import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import stack_logo_grey from '@pn/ui/images/StackDX_LOGO_Grey60p_WhiteBG.svg';
import stack_logo_white from '@pn/ui/images/StackDX_LOGO_White_TransparentBG.svg';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItemButton: {
    paddingLeft: theme.spacing(4),
  },
  stackListItemButton: {
    paddingLeft: 20,
    paddingTop: 0,
    paddingBottom: 0,
  },
  categoryLogo: {
    height: 48,
  },
  sharedIcon: {
    position: 'relative',
    top: 3.5,
    marginRight: theme.spacing(1),
    fontSize: 18,
    color: theme.palette.text.disabled,
  },
}));

type Props = {
  folder: string;
  isSelected: boolean;
  onClick: () => void;
};

export const LibraryFolder = ({ folder, isSelected, onClick }: Props) => {
  const { classes, theme } = useStyles();

  return (
    <ListItem disablePadding>
      <ListItemButton
        className={
          folder === 'StackDX'
            ? classes.stackListItemButton
            : classes.listItemButton
        }
        selected={isSelected}
        onClick={onClick}
      >
        {folder === 'StackDX' ? (
          <img
            src={
              theme.palette.mode === 'light'
                ? stack_logo_grey
                : stack_logo_white
            }
            alt="StackDX"
            className={classes.categoryLogo}
          />
        ) : (
          <ListItemText primary={folder} />
        )}
      </ListItemButton>
    </ListItem>
  );
};
