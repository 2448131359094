import { pnTokenManager } from '@pn/core/services/authentication/tokenManager';
import { isEmbedded } from '@pn/core/utils/embedded';
import React from 'react';

export function useAutoTriggerAuth() {
  const [isAuthTriggered, setIsAuthTriggered] = React.useState(false);

  React.useEffect(() => {
    if (isEmbedded()) return;

    (async () => {
      try {
        await pnTokenManager.fetchToken(); // triggers authentication flow
      } catch {
        /* Do nothing, user is not logged in */
      }
      setIsAuthTriggered(true);
    })();
  }, []);

  return isAuthTriggered;
}
