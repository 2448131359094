import {
  Checkbox,
  ClassNameMap,
  colors,
  TableCell,
  TableCellProps,
} from '@mui/material';
import { Column } from '@pn/core/domain/data-info';
import { isMapboxLayer } from '@pn/core/domain/layer';
import { UnitSystem } from '@pn/core/domain/types';
import { useAccess } from '@pn/core/permissions/access';
import { useAppStorage } from '@pn/core/storage';
import { formatDomainValue } from '@pn/core/utils/format';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { DynamicLink } from '@pn/ui/custom-components/DynamicLink';
import assert from 'minimalistic-assert';
import { isNil, isObject, isString } from 'lodash-es';
import {
  map,
  useAuthenticationService,
} from 'src/application/externalDependencies';
import { CustomLink } from 'src/ui/components/CustomLink';
import { useExtraDataContext } from 'src/ui/main-panel/components/dense-components/ExtraDataProvider';
import { makeStyles } from 'tss-react/mui';
import { isApiButton } from '@pn/services/api/data-info/types';

const useStyles = makeStyles()((theme) => ({
  url: {
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
  intelUrl: {
    color:
      theme.palette.mode === 'light' ? colors.orange[700] : colors.amber[700],
    textDecoration: 'none',
  },
}));

type Props = {
  classes: ClassNameMap;
  value: unknown;
  isButton: boolean;
  module?: Column['module'];
  url?: string;
  inlineSymbol?: boolean;
  onClick?: () => void;
} & TableCellProps;

export const FormattedTableCell = ({
  classes,
  value,
  module,
  isButton,
  url,
  inlineSymbol,
  onClick,
  ...rest
}: Props) => {
  const { classes: extraClasses, cx } = useStyles();

  const access = useAccess();
  const { isAuthenticated } = useAuthenticationService();
  const { unitSystem } = useAppStorage();

  return (
    <TableCell
      className={
        isAuthenticated
          ? classes.tableCell
          : cx(classes.tableCell, classes.blur)
      }
      style={access('details.selection').denied() ? { userSelect: 'none' } : {}}
      {...rest}
    >
      {module === 'visualize_source_layer'
        ? isMapboxLayer(value) && <SourceLayerCheckbox layer={value} />
        : isAuthenticated
          ? format({
              classes: extraClasses,
              value,
              isButton,
              url,
              unitSystem,
              inlineSymbol,
              onClick,
            })
          : obfuscate(value, unitSystem)}
    </TableCell>
  );
};

const SourceLayerCheckbox = (props: { layer: mapboxgl.Layer }) => {
  const { layer } = props;

  const {
    dispatch,
    state: { visualizedSourceLayers },
  } = useExtraDataContext();

  const handleChange = (_event: unknown, checked: boolean) => {
    const sourceId = `${layer.id}-source`;

    if (checked) {
      assert(
        isObject(layer.source),
        'visualize_source_layer module error: layer source must contain a full definition'
      );

      map._native.addSource(sourceId, layer.source);
      map._native.addLayer({
        ...layer,
        source: sourceId,
      } as mapboxgl.AnyLayer);

      dispatch({
        type: 'ADD_SOURCE_LAYER',
        payload: { layerId: layer.id },
      });
    } else {
      map._native.removeLayer(layer.id);
      map._native.removeSource(sourceId);

      dispatch({
        type: 'REMOVE_SOURCE_LAYER',
        payload: { layerId: layer.id },
      });
    }
  };

  return (
    <Checkbox
      checked={visualizedSourceLayers[layer.id] ?? false}
      onChange={handleChange}
    />
  );
};

function format(params: {
  classes: ClassNameMap;
  value: unknown;
  isButton: boolean;
  url: string | undefined;
  unitSystem: UnitSystem;
  inlineSymbol?: boolean;
  onClick?: () => void;
}) {
  const { classes, value, isButton, url, unitSystem, inlineSymbol, onClick } =
    params;

  /* Handle special cases */
  if (isButton && isApiButton(value) && !isNil(onClick)) {
    return (
      <CustomButton size="small" onClick={onClick}>
        {value.label}
      </CustomButton>
    );
  } else if (isString(value) && !isNil(url)) {
    const regex = new RegExp(/intel..+.com/);
    const className = regex.test(url) ? classes.intelUrl : classes.url;

    return (
      <DynamicLink link={url} target="_blank" className={className}>
        {value}
      </DynamicLink>
    );
  } else if (
    isString(value) &&
    (value.startsWith('http://') || value.startsWith('https://'))
  ) {
    return (
      <CustomLink to={value} target="_blank">
        {value}
      </CustomLink>
    );
  } else {
    /* Handle domain types */
    return formatDomainValue({
      value,
      unitSystem,
      displaySymbol: inlineSymbol,
    });
  }
}

export function obfuscate(value: unknown, unitSystem: UnitSystem): string {
  return formatDomainValue({ value, unitSystem }).replace(/./g, 'X');
}
