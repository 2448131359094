import StraightIcon from '@mui/icons-material/Straight';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { Box, IconButton, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { useDrawing } from '@pn/services/drawing';
import assert from 'minimalistic-assert';
import { isNil } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const Order = ({ featureLive }: Props) => {
  const { drawingState, historyManager, redraw } = useDrawing();

  const handleChange = (direction: 'top' | 'up' | 'down' | 'bottom') => {
    assert(featureLive, 'featureLive must be defined');
    const id = featureLive.id;

    switch (direction) {
      case 'top':
        drawingState.order = drawingState.order.filter((i) => i !== id);
        drawingState.order.push(id);
        break;
      case 'up': {
        const index = drawingState.order.indexOf(id);
        if (index === drawingState.order.length - 1) return;
        drawingState.order[index] = drawingState.order[index + 1];
        drawingState.order[index + 1] = id;
        break;
      }
      case 'down': {
        const index = drawingState.order.indexOf(id);
        if (index === 0) return;
        drawingState.order[index] = drawingState.order[index - 1];
        drawingState.order[index - 1] = id;
        break;
      }
      case 'bottom':
        drawingState.order = drawingState.order.filter((i) => i !== id);
        drawingState.order.unshift(id);
        break;
    }

    redraw();

    historyManager.add(drawingState);
  };

  if (isNil(featureLive)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Actions
      </Typography>
      <Box display="flex" gap={1}>
        <IconButton onClick={() => handleChange('top')}>
          <VerticalAlignTopIcon />
        </IconButton>
        <IconButton onClick={() => handleChange('up')}>
          <StraightIcon />
        </IconButton>
        <IconButton onClick={() => handleChange('down')}>
          <StraightIcon sx={{ transform: 'scaleY(-1)' }} />
        </IconButton>
        <IconButton onClick={() => handleChange('bottom')}>
          <VerticalAlignBottomIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
