import type { DataItemId } from '@pn/core/domain/data';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { getLength } from '@pn/services/utils/geojson';
import { murmurhash3 } from '@pn/services/utils/hash';
import { isString, uniq } from 'lodash-es';

export enum MapMode {
  Standard = 'standard',
  Light = 'light',
  Dark = 'dark',
  Satellite = 'satellite',
}

export function isMapMode(arg: unknown): arg is MapMode {
  return Object.values(MapMode).includes(arg as MapMode);
}

export type MapFeature = {
  layerId: string;
  itemId: WorkspaceItem['id'];
  dataType: string;
  internalId: DataItemId;
  geometry: GeoJSON.Geometry;
};

export function getMapFeatureIds(features: MapFeature[]): DataItemId[] {
  return uniq(features.map((f) => f.internalId));
}

export function hashFeatureId(featureId: DataItemId): number {
  return isString(featureId) ? murmurhash3(featureId) : featureId;
}

/**
 * First, this function finds a single closest feature which is then used as an
 * anchor point for further calculations. Then, all other features that are less
 * than `radius` meters away from the anchor are returned.
 */
export function getClosestFeatures(
  centerPoint: GeoJSON.Position,
  features: MapFeature[],
  radius = 0
): MapFeature[] {
  const anchorFeature = getClosestFeature(centerPoint, features);

  const closestFeatures: MapFeature[] = [];
  features.forEach((feature) => {
    if (
      feature.geometry.type === 'Point' &&
      anchorFeature.geometry.type === 'Point'
    ) {
      const dist = getLength([
        feature.geometry.coordinates,
        anchorFeature.geometry.coordinates,
      ]);

      if (dist <= radius) {
        closestFeatures.push(feature);
      }
    } else {
      closestFeatures.push(feature);
    }
  });

  return closestFeatures;
}

function getClosestFeature(
  centerPoint: GeoJSON.Position,
  features: MapFeature[]
): MapFeature {
  let closestFeature: MapFeature;
  let closestDistance = Number.MAX_VALUE;

  features.forEach((feature) => {
    if (feature.geometry.type === 'Point') {
      const dist = getLength([centerPoint, feature.geometry.coordinates]);
      if (dist < closestDistance) {
        closestFeature = feature;
        closestDistance = dist;
      }
    } else {
      closestFeature = feature;
    }
  });

  return closestFeature!;
}
