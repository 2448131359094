import type { Mapping } from '@pn/core/domain/data';
import { setFields } from '@pn/resources/pn-mappings';
import aerTransfersMapping from './aer_transfers.jsonc';
import confidentialWellsMapping from './confidential_wells.jsonc';
import expiredMinerals from './expired_minerals.jsonc';
import landPostingsMapping from './land_postings.jsonc';
import landResultsMapping from './land_results.jsonc';
import mineralRightsTransfersMapping from './mineral_rights_transfers.jsonc';
import wellLicenceMismatches from './well_licence_mismatches.jsonc';
import wellLicenseTransfersMapping from './well_license_transfers.jsonc';
import wellLicensesMapping from './well_licenses.jsonc';
import wellsByInitialProduction from './wells_by_initial_production.jsonc';
import wellsByProduction from './wells_by_production.jsonc';

export const intelMappings: Record<string, Mapping> = setFields({
  well_licenses: wellLicensesMapping,
  well_license_transfers: wellLicenseTransfersMapping,
  aer_transfers: aerTransfersMapping,
  land_postings: landPostingsMapping,
  land_results: landResultsMapping,
  mineral_rights_transfers: mineralRightsTransfersMapping,
  confidential_wells: confidentialWellsMapping,
  declassified_wells: confidentialWellsMapping,
  well_licence_mismatches: wellLicenceMismatches,
  wells_by_gas_production: wellsByProduction,
  wells_by_liquid_production: wellsByProduction,
  wells_by_production: wellsByProduction,
  liquid_ip3: wellsByInitialProduction,
  liquid_ip12: wellsByInitialProduction,
  gas_ip3: wellsByInitialProduction,
  gas_ip12: wellsByInitialProduction,
  expired_minerals: expiredMinerals,
});
