import bbox from '@turf/bbox';
import { isArray } from 'lodash-es';

/**
 * A GeoJSON BBox represents the bounding box of a geometry.
 *
 * Format: `[minLon, minLat, maxLon, maxLat]`
 * - `minLon` → SW lon (left)
 * - `minLat` → SW lat (bottom)
 * - `maxLon` → NE lon (right)
 * - `maxLat` → NE lat (top)
 */
export function getBBox(
  arg: GeoJSON.Geometry | GeoJSON.Geometry[]
): GeoJSON.BBox {
  const min = [180, 90];
  const max = [-180, -90];

  const geometries = isArray(arg) ? arg : [arg];

  for (const geometry of geometries) {
    const [shapeMinLon, shapeMinLat, shapeMaxLon, shapeMaxLat] = bbox({
      type: 'Feature',
      geometry,
      properties: {},
    });

    min[0] = Math.min(min[0], shapeMinLon);
    min[1] = Math.min(min[1], shapeMinLat);
    max[0] = Math.max(max[0], shapeMaxLon);
    max[1] = Math.max(max[1], shapeMaxLat);
  }

  return [min[0], min[1], max[0], max[1]];
}

export function combineBBoxes(bboxes: GeoJSON.BBox[]): GeoJSON.BBox {
  const min = [180, 90];
  const max = [-180, -90];

  for (const [minLon, minLat, maxLon, maxLat] of bboxes) {
    min[0] = Math.min(min[0], minLon);
    min[1] = Math.min(min[1], minLat);
    max[0] = Math.max(max[0], maxLon);
    max[1] = Math.max(max[1], maxLat);
  }

  return [min[0], min[1], max[0], max[1]];
}
