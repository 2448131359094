import type { User } from '@pn/core/domain/user';
import type { IErrorLogger } from '@pn/core/services/loggers/ports';
import type { INotificationService } from '@pn/core/services/notifications/ports';
import { hasKeyWithType } from '@pn/core/utils/logic';
import { isObject, isString } from 'lodash-es';
import { CustomError as TSCustomError } from 'ts-custom-error';

type HandleParams = {
  error: Error;
  errorLogger: IErrorLogger;
  notificationService: INotificationService;
  userFriendlyMessage?: string;
  category?: string;
  userId?: User['id'];
  onError: () => void;
};

export abstract class CustomError extends TSCustomError {
  abstract handle(params: HandleParams): void;
}

/**
 * Used for any system errors that render the application unusable.
 * E.g. bad mapping file or missing environment variables.
 */
export class ConfigurationError extends CustomError {
  public constructor(message: string) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'ConfigurationError' });
  }

  handle({
    notificationService,
    error,
    errorLogger,
    userId,
    onError,
  }: HandleParams) {
    console.log('>>>', this);

    notificationService.error(
      'System error occurred, our team has been notified'
    );
    // TODO figure out a way to actually crash the app and trigger the error page instead
    onError();
    errorLogger.logConfigurationError(this, userId);

    throw error; // rethrow to crash the app
  }
}

/**
 * Used to handle errors when making requests to the server.
 */
export class ApiError extends CustomError {
  public code: number;
  public method: string;
  public url: string;
  public urlTemplate?: string;
  public responseData?: unknown;

  public constructor(params: {
    message: string;
    code: number;
    method: string;
    url: string;
    urlTemplate: string | undefined;
    responseData?: unknown;
  }) {
    super(params.message);
    this.code = params.code;
    this.method = params.method;
    this.url = params.url;
    this.urlTemplate = params.urlTemplate;
    this.responseData = params.responseData;

    Object.defineProperty(this, 'name', { value: 'ApiError' });
  }

  handle({
    notificationService,
    errorLogger,
    userFriendlyMessage,
    userId,
    onError,
  }: HandleParams) {
    console.log('>>>', this);

    if (userFriendlyMessage) notificationService.error(userFriendlyMessage);
    onError();
    errorLogger.logApiError(this, userId);
  }
}

/**
 * Used for expected/recoverable errors that should only generate a warning.
 * This error will NOT trigger an onError callback.
 * E.g. trying to export data when there are over MAX_LIMIT results.
 */
export class ApplicationError extends CustomError {
  public constructor(message: string) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'ApplicationError' });
  }

  handle({ notificationService }: HandleParams) {
    notificationService.warning(this.message);
  }
}

/**
 * An abstract wrapper around the default Error class that adds a handle method.
 * We call the handle method on it instead of extending the default error.
 */
export abstract class GenericError extends CustomError {
  static handle({
    error,
    errorLogger,
    notificationService,
    userFriendlyMessage,
    category,
    userId,
    onError,
  }: HandleParams) {
    console.log('>>>', error);

    if (userFriendlyMessage) notificationService.error(userFriendlyMessage);
    onError();
    errorLogger.logGenericError(error, userId, category);
  }
}

export function isGenericError(error: unknown): error is Error {
  return error instanceof Error;
}

export function isCustomError(error: unknown): error is CustomError {
  return error instanceof CustomError;
}

export function getApiErrorMessage(error: ApiError): string {
  const responseData = error.responseData;

  if (
    isObject(responseData) &&
    hasKeyWithType(responseData, 'error', isString)
  ) {
    return responseData.error;
  } else {
    return 'API error occurred';
  }
}
