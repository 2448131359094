import { adminUsersSlice } from '@pn/admin/src/storage/user/adminUsersSlice'; // ?? unsure how to work around this
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiUserMapper } from '@pn/services/api/user/apiUserMapper';
import type { ReducerSync } from '.';

export const adminUsers: ReducerSync<typeof adminUsersSlice.actions> = {
  update: (user) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v2/admin/users/${user.id}`,
      payload: apiUserMapper.toTargetUser(user),
    });
  },
  remove: () => {
    throw new Error('Remove operation should never be invoked on all users');
  },
};
