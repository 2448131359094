import { alpha, Box, colors, Paper } from '@mui/material';
import { MessageMarkdown } from '@pn/ui/chat/MessageMarkdown';
import type { ChatMessage } from '@pn/ui/chat/useChatReducer';
import { isString } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  message: {
    overflow: 'auto',
  },
}));

type Props = {
  messages: ChatMessage[];
};

export const Messages = ({ messages }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        gap: 2,
      }}
    >
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </Box>
  );
};

type MessageProps = {
  message: ChatMessage;
};

export const Message = ({ message }: MessageProps) => {
  const { classes } = useStyles();

  switch (message.role) {
    case 'user':
      return (
        <Paper
          elevation={0}
          className={classes.message}
          sx={(theme) => ({
            alignSelf: 'flex-end',
            p: 2,
            width: '80%',
            backgroundColor: alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            ),
            fontFamily:
              isString(message) && message.startsWith('/')
                ? 'monospace'
                : 'inherit',
          })}
        >
          <MessageMarkdown message={message} />
        </Paper>
      );
    case 'system':
      return (
        <Paper
          elevation={0}
          className={classes.message}
          sx={(theme) => ({
            p: 2,
            backgroundColor: alpha(
              colors.deepPurple[300],
              theme.palette.action.selectedOpacity
            ),
            fontFamily: 'monospace',
          })}
        >
          <MessageMarkdown message={message} />
        </Paper>
      );
    case 'assistant':
      return (
        <Paper
          elevation={0}
          className={classes.message}
          sx={(theme) => ({
            pt: 1,
            backgroundColor: theme.palette.background.default,
          })}
        >
          <MessageMarkdown message={message} />
        </Paper>
      );
  }
};
