import type {
  GridColDef,
  GridSingleSelectColDef,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import {
  isDropdownAttributes,
  type Mapping,
  type MappingItem,
} from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import React from 'react';

/**
 * See useDefaultColumnAttributes.ts for example implementation.
 */
export type ColumnAttributesSetter = (params: {
  column: GridColDef;
  mappingItem: MappingItem;
}) => void;

export function useColumns<T extends GridValidRowModel>(params: {
  mapping: Mapping;
  fields: string[];
  setColumnAttributes: ColumnAttributesSetter;
}): GridColDef<T>[] {
  const { mapping, fields, setColumnAttributes } = params;

  return React.useMemo(() => {
    const renderableMapping = Object.values(mapping).filter(
      (mappingItem): mappingItem is NonNullable<typeof mappingItem> =>
        !mappingItem?.ui.isNotRenderable
    );

    const columns = renderableMapping.map((mappingItem) => {
      const column: GridColDef = {
        field: mappingItem.field,
        type: domainTypeToDataGridType(
          mappingItem.domainType,
          mappingItem.domainTypeAttributes
        ),
        width: mappingItem.ui.width ?? 150,
        sortable: !mappingItem.ui.isNotSortable,
      };

      if (
        column.type === 'singleSelect' &&
        isDropdownAttributes(mappingItem.domainTypeAttributes)
      ) {
        (column as GridSingleSelectColDef).valueOptions =
          mappingItem.domainTypeAttributes.options;
      }

      setColumnAttributes({ column, mappingItem });

      return column;
    });

    /* Reorder columns to match the order of `query.fields` */
    fields
      .slice()
      .reverse()
      .forEach((field) => {
        const columnToBeMoved = columns.find(
          (column) => column.field === field
        );

        if (isNil(columnToBeMoved)) return; // this happens with outdated templates with fields that are no longer in the mapping

        const index = columns.findIndex((column) => column.field === field);

        columns.splice(index, 1);
        columns.unshift(columnToBeMoved);
      });

    return columns;
  }, [fields, mapping, setColumnAttributes]);
}

function domainTypeToDataGridType(
  domainType: MappingItem['domainType'],
  domainTypeAttributes: MappingItem['domainTypeAttributes']
): GridColDef['type'] {
  switch (domainType) {
    case 'string':
      if (isDropdownAttributes(domainTypeAttributes)) {
        return 'singleSelect';
      } else {
        return 'string';
      }
    case 'number':
      return 'number';
    case 'boolean':
      return 'boolean';
    case 'SIUnit':
      return 'number';
    case 'DateString':
      return 'date';
    default:
      return 'string';
  }
}
