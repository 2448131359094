import { Box, Grid, Paper } from '@mui/material';
import { isEmbedded } from '@pn/core/utils/embedded';
import { CustomSvgIcon } from '@pn/ui/icons/CustomSVGIcon';
import {
  abandonedGasIcon,
  abandonedInjectionIcon,
  abandonedOilIcon,
  abandonedSourceIcon,
  dryOrAbandonedIcon,
  gasIcon,
  injectionIcon,
  locationIcon,
  oilIcon,
  sourceIcon,
  suspendedGasIcon,
  suspendedInjectionIcon,
  suspendedOilIcon,
  suspendedSourceIcon,
} from '@pn/ui/icons/wellIcons';
import pn_image_logo_dark from 'src/images/pn_full_logo_2024_03_06.webp';
import pn_image_logo_light from 'src/images/pn_full_logo_white_2024_03_06.webp';
import { makeStyles } from 'tss-react/mui';

const legend = [
  [
    { icon: oilIcon, description: 'Active Oil' },
    { icon: suspendedOilIcon, description: 'Suspended Oil' },
    { icon: abandonedOilIcon, description: 'Abandoned Oil' },
    { icon: dryOrAbandonedIcon, description: 'Dry Or Abandoned' },
  ],
  [
    { icon: gasIcon, description: 'Active Gas' },
    { icon: suspendedGasIcon, description: 'Suspended Gas' },
    { icon: abandonedGasIcon, description: 'Abandoned Gas' },
    { icon: locationIcon, description: 'Location' },
  ],
  [
    { icon: sourceIcon, description: 'Source' },
    { icon: suspendedSourceIcon, description: 'Suspended Source' },
    { icon: abandonedSourceIcon, description: 'Abandoned Source' },
  ],
  [
    { icon: injectionIcon, description: 'Active Injection' },
    { icon: suspendedInjectionIcon, description: 'Suspended Injection' },
    { icon: abandonedInjectionIcon, description: 'Abandoned Injection' },
  ],
];

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  logo: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    height: 40,
    opacity: 0.25,
    filter: 'grayscale(100%)',
  },
  legend: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  },
  icon: {
    fontSize: 16,
  },
  iconLabel: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
    lineHeight: 1,
  },
}));

export default function PrintComponents() {
  const { classes, theme } = useStyles();

  if (isEmbedded()) return null;

  return (
    <Box className={classes.container}>
      <img
        className={classes.logo}
        src={
          theme.palette.mode === 'light'
            ? pn_image_logo_dark
            : pn_image_logo_light
        }
        alt="Petro Ninja logo"
      />
      <Paper variant="outlined" square className={classes.legend}>
        <Grid container spacing={2} columns={4}>
          {legend.map((col, index) => (
            <Grid item key={index}>
              {col.map((icon, iconIndex) => (
                <Box key={iconIndex} display="flex" alignItems="center">
                  <CustomSvgIcon {...icon.icon} className={classes.icon} />
                  <span className={classes.iconLabel}>{icon.description}</span>
                </Box>
              ))}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
}
