/**
 * Allows us to convert a stream of text into JS objects on the fly.
 * Supports incomplete lines even when they are split across multiple chunks.
 */
export class NDJsonParser {
  private lastIncompleteLine: string | undefined;

  parseChunk(chunk: string | undefined) {
    if (!chunk) return [];

    const parsedData: any[] = [];
    const lines = chunk.split(/\r?\n/);

    if (this.lastIncompleteLine) {
      lines[0] = this.lastIncompleteLine + lines[0];
      this.lastIncompleteLine = undefined;
    }

    lines.forEach((line) => {
      try {
        const parsedObject = JSON.parse(line);
        parsedData.push(parsedObject);
      } catch {
        this.lastIncompleteLine = line;
      }
    });

    return parsedData;
  }
}
