import { ApiError } from '@pn/core/errors';
import { handleError } from '@pn/core/errors/handleError';
import { useStackCompanies } from '@pn/core/operations/stackdx/stackCompanies';
import { stackTokenManager } from '@pn/core/services/authentication/tokenManager';
import { apiStackTokenProvider } from '@pn/services/api/stackdx/apiStackTokenProvider';
import { isNil } from 'lodash-es';
import React from 'react';

async function getStackToken(
  stackCompanyId: string
): Promise<string | undefined> {
  try {
    const token = await apiStackTokenProvider.getStackToken(stackCompanyId);

    return token;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.code === 401 || error.code === 403) {
        return undefined; // not a Stack user
      }
    }

    handleError({ error });

    return undefined;
  }
}

export function useAutoGetStackToken() {
  const [stackToken, setStackToken] = React.useState<string | undefined>();

  const { companyId } = useStackCompanies();

  React.useEffect(() => {
    if (isNil(companyId)) return;

    (async () => {
      const stackToken = await getStackToken(companyId);
      setStackToken(stackToken);

      if (!isNil(stackToken)) {
        stackTokenManager.set(async () => stackToken);
      }
    })();
  }, [companyId]);

  return stackToken;
}
