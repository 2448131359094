import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';

type Props = {
  value: 'category' | 'folder';
  onChange: (value: 'category' | 'folder') => void;
};

export const StackGroupBy = ({ value, onChange }: Props) => {
  const { xsScreen } = useScreenSize();

  return (
    <FormControl>
      <FormLabel id="stack-group-by-label">Group by</FormLabel>
      <RadioGroup
        aria-labelledby="stack-group-by-label"
        row={!xsScreen}
        value={value}
        onChange={(event) =>
          onChange(event.target.value as 'category' | 'folder')
        }
      >
        <FormControlLabel
          value="category"
          control={<Radio size="small" />}
          label="Category"
        />
        <FormControlLabel
          value="folder"
          control={<Radio size="small" />}
          label="Folder"
        />
      </RadioGroup>
    </FormControl>
  );
};
