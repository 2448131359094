import { useMapStorage } from '@pn/core/storage';
import { useDrawing } from '@pn/services/drawing';
import assert from 'minimalistic-assert';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 0, // will be set in useEffect
    height: 0, // will be set in useEffect
    pointerEvents: 'none',
    '@media print': {
      display: 'none', // TEMP
    },
  },
}));

export function DrawingCanvas() {
  const { classes } = useStyles();

  const { isInitialized } = useMapStorage();
  const { staticCanvasRef, liveCanvasRef, redraw } = useDrawing();

  React.useEffect(() => {
    if (!isInitialized) return;

    const onMove = () => {
      redraw();
    };

    map._native.on('move', onMove);

    return () => {
      map._native.off('move', onMove);
    };
  }, [
    isInitialized,
    // the following never change:
    staticCanvasRef,
    liveCanvasRef,
    redraw,
  ]);

  React.useEffect(() => {
    if (!isInitialized) return;

    const onResize = () => {
      const { clientWidth, clientHeight } = map._native.getContainer();
      const { width, height } = map._native.getCanvas();

      const staticCanvas = staticCanvasRef.current;
      const liveCanvas = liveCanvasRef.current;
      assert(staticCanvas, 'staticCanvas must be defined');
      assert(liveCanvas, 'liveCanvas must be defined');

      staticCanvas.style.width = `${clientWidth}px`;
      staticCanvas.style.height = `${clientHeight}px`;
      liveCanvas.style.width = `${clientWidth}px`;
      liveCanvas.style.height = `${clientHeight}px`;

      staticCanvas.width = width;
      staticCanvas.height = height;
      liveCanvas.width = width;
      liveCanvas.height = height;

      redraw();
    };

    onResize(); // set initial dimensions

    map._native.on('resize', onResize);

    return () => {
      map._native.off('resize', onResize);
    };
  }, [
    isInitialized,
    // the following never change:
    staticCanvasRef,
    liveCanvasRef,
    redraw,
  ]);

  return (
    <>
      <canvas
        id="canvas-static"
        className={classes.canvas}
        ref={staticCanvasRef}
      />
      <canvas id="canvas-live" className={classes.canvas} ref={liveCanvasRef} />
    </>
  );
}
