import { enterprisesSlice } from '@pn/core/storage/enterprise/enterprisesSlice';
import { apiEnterpriseMapper } from '@pn/services/api/enterprise/apiEnterpriseMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import type { ReducerSync } from '.';

const { actions } = enterprisesSlice;

export const enterprises: ReducerSync<typeof actions> = {
  create: async ({ enterprise, ownerId }, dispatch) => {
    /**
     * We have to combine enterprise form with a user_id for the enterprise owner.
     * This is just a one-off thing which should never happen with any other objects.
     */
    const form = {
      ...apiEnterpriseMapper.toTargetEnterprise(enterprise),
      user_id: ownerId,
    };

    const response = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: `v2/admin/enterprises`,
      payload: form,
    });

    dispatch(
      actions._updateId({
        currentId: enterprise.id,
        newId: response.id,
      })
    );
  },
  update: (enterprise) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v2/admin/enterprises/${enterprise.id}`,
      payload: apiEnterpriseMapper.toTargetEnterprise(enterprise),
    });
  },
};
