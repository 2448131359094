import { dependencies } from '@pn/core/dependencies';
import { useAccess } from '@pn/core/permissions/access';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useChat() {
  const access = useAccess();

  const [isChatOpen, setIsChatOpen] = React.useState(false);

  useHotkeys(
    ['ctrl+shift+i'],
    (e) => {
      e.preventDefault();
      if (access('gpt').denied()) return;

      setIsChatOpen((prev) => !prev);
      setTimeout(() => dependencies.map.resize(), 0);
    },
    []
  );

  return {
    isChatOpen,
    openChat: () => setIsChatOpen(true),
    closeChat: () => setIsChatOpen(false),
  };
}
