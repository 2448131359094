import { TextField } from '@mui/material';
import type { ChatMessage } from '@pn/ui/chat/useChatReducer';
import { isEmpty } from 'lodash-es';
import React from 'react';

type Props = {
  disabled: boolean;
  messages: ChatMessage[];
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
};

export const PromptInput = ({
  disabled,
  messages,
  value,
  onChange,
  onSubmit,
}: Props) => {
  const [pointer, setPointer] = React.useState<number | undefined>();

  return (
    <TextField
      fullWidth
      size="small"
      multiline
      rows={4}
      placeholder={
        isEmpty(messages)
          ? 'Enter your prompt or use the examples above\nStart with / to use commands'
          : 'Enter your next prompt\nStart with / to use commands'
      }
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        switch (e.key) {
          case 'Enter':
            if (e.shiftKey) return;

            e.preventDefault();
            if (disabled) return;

            onSubmit();
            setPointer(undefined);

            break;
          case 'ArrowUp': {
            e.preventDefault();
            if (isEmpty(messages)) return;

            const userMessages = removeConsecutiveDuplicates(
              messages.filter((m) => m.role === 'user')
            );
            const index =
              pointer === undefined
                ? userMessages.length - 1
                : Math.max(0, pointer - 1);
            onChange(userMessages[index].content);
            setPointer(index);

            break;
          }
          case 'ArrowDown': {
            e.preventDefault();
            if (isEmpty(messages)) return;

            const userMessages = removeConsecutiveDuplicates(
              messages.filter((m) => m.role === 'user')
            );
            const index =
              pointer === undefined
                ? userMessages.length - 1
                : Math.min(userMessages.length, pointer + 1); // the last position will yield an empty string
            onChange(userMessages[index]?.content ?? '');
            setPointer(index);

            break;
          }
          default:
            setPointer(undefined);
        }
      }}
      slotProps={{
        input: {
          sx: (theme) => ({
            fontSize: theme.typography.body2.fontSize,
            fontFamily: value.startsWith('/') ? 'monospace' : 'inherit',
          }),
        },
      }}
    />
  );
};

function removeConsecutiveDuplicates<T extends ChatMessage>(arr: T[]): T[] {
  return arr.filter((item, index) => item.content !== arr[index - 1]?.content);
}
