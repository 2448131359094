import { projectsSlice } from '@pn/core/storage/project/projectsSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiProjectMapper } from '@pn/services/api/project/apiProjectMapper';
import type { ReducerSync } from '.';

export const projects: ReducerSync<typeof projectsSlice.actions> = {
  update: (project) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v2/projects/${project.id}`,
      payload: apiProjectMapper.toTargetProjectPayload(project),
    });
  },
  remove: (projectId) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v2/projects/${projectId}`,
    });
  },
  share: (projectId) => {
    return pnApiClient.request({
      method: 'POST',
      url: `v2/projects/${projectId}/share`,
    });
  },
  unshare: (projectId) => {
    return pnApiClient.request({
      method: 'POST',
      url: `v2/projects/${projectId}/unshare`,
    });
  },
};
