import {
  isPolyClosed,
  type CircleFeature,
  type PolyFeature,
} from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import {
  computeMapTransformation,
  REFERENCE_PT,
  REFERENCE_ZOOM,
  transformPoint,
} from '@pn/services/drawing';
import {
  canvasPointToPosition,
  getArea,
  getLength,
} from '@pn/services/utils/geojson';
import { isNil } from 'lodash-es';

type Options = {
  local?: boolean;
  cursor?: Point;
};

export function generateFeatureMeasurements(
  feature: PolyFeature,
  options?: Options
): NonNullable<PolyFeature['measurements']>;
export function generateFeatureMeasurements(
  feature: CircleFeature,
  options?: Options
): NonNullable<CircleFeature['measurements']>;
export function generateFeatureMeasurements(
  feature: PolyFeature | CircleFeature,
  options?: Options
): NonNullable<PolyFeature['measurements'] | CircleFeature['measurements']>;

export function generateFeatureMeasurements(
  feature: PolyFeature | CircleFeature,
  options: Options = {}
) {
  switch (feature.type) {
    case 'poly':
      return generatePolyMeasurements(feature, options);
    case 'circle':
      return generateCircleMeasurements(feature, options);
  }
}

function generatePolyMeasurements(
  feature: PolyFeature,
  { local = false }: Options
): NonNullable<PolyFeature['measurements']> {
  const transformation = computeMapTransformation(REFERENCE_PT);
  const inverseTransformation = {
    dx: -transformation.dx,
    dy: -transformation.dy,
    scale: 1 / transformation.scale,
  };

  const coordinates = local
    ? feature.coordinates.map((p) => transformPoint(p, inverseTransformation))
    : feature.coordinates;

  const geoPositions = coordinates.map((point) =>
    canvasPointToPosition(point, REFERENCE_PT, REFERENCE_ZOOM)
  );

  const positionCoordinateIndex =
    feature.subType === 'rectangle'
      ? feature.coordinates.length - 3
      : feature.coordinates.length - 1;

  return {
    distance: getLength(geoPositions),
    area: isPolyClosed(feature) ? getArea(geoPositions) : undefined,
    position: feature.coordinates[positionCoordinateIndex],
    fontSize: local ? 14 : 14 / transformation.scale,
  };
}

function generateCircleMeasurements(
  feature: CircleFeature,
  { local = false, cursor }: Options
): NonNullable<CircleFeature['measurements']> {
  const { center, radius } = feature;

  const transformation = computeMapTransformation(REFERENCE_PT);
  const inverseTransformation = {
    dx: -transformation.dx,
    dy: -transformation.dy,
    scale: 1 / transformation.scale,
  };

  /**
   * For the most accurate measurement, we calculate circle radius by following
   * the latitude line from its center.
   */
  const radiusPoint = {
    x: feature.center.x + feature.radius,
    y: feature.center.y,
  };

  const points = local
    ? [center, radiusPoint].map((p) => transformPoint(p, inverseTransformation))
    : [center, radiusPoint];

  const geoPositions = points.map((point) =>
    canvasPointToPosition(point, REFERENCE_PT, REFERENCE_ZOOM)
  );

  return {
    position: getCircleLabelPosition(
      center,
      cursor ?? radiusPoint, // if cursor position is not provided, use 0° angle
      radius
    ),
    radius: getLength(geoPositions),
    fontSize: local ? 14 : 14 / transformation.scale,
  };
}

function getCircleLabelPosition(
  center: Point,
  cursor: Point,
  radius: number
): Point {
  const dx = cursor.x - center.x;
  const dy = cursor.y - center.y;

  const angle = Math.atan2(dy, dx);

  return {
    x: center.x + radius * Math.cos(angle),
    y: center.y + radius * Math.sin(angle),
  };
}
export function updateMeasurements(feature: PolyFeature): PolyFeature {
  if (isNil(feature.measurements)) return feature;

  const points = feature.coordinates;

  const geoPositions = points.map((point) =>
    canvasPointToPosition(point, REFERENCE_PT, REFERENCE_ZOOM)
  );

  feature.measurements.distance = getLength(geoPositions);
  if (!isNil(feature.measurements.area)) {
    feature.measurements.area = getArea(geoPositions);
  }

  feature.measurements.position = points[points.length - 1];

  return feature;
}
