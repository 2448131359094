import ColorizeIcon from '@mui/icons-material/Colorize';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@mui/material';
import type { MappingItem } from '@pn/core/domain/data';
import { convertUnit, toSIUnit, unitToString } from '@pn/core/domain/units';
import type { ColorLegend, ColorType } from '@pn/core/domain/workspace';
import { useAppStorage } from '@pn/core/storage';
import { DEFAULT_COLOR } from '@pn/services/styles';
import { ColorPicker } from '@pn/ui/custom-components/color-picker';
import {
  SMALL_SQ_GAP,
  SMALL_SQ_SIZE,
} from '@pn/ui/custom-components/color-picker/ColorSelector';
import assert from 'minimalistic-assert';
import { isNumber, isString } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export const LEGEND_LIST_ITEM_HEIGHT = 36;

const useStyles = makeStyles()((theme) => ({
  legendListItem: {
    height: LEGEND_LIST_ITEM_HEIGHT,
    paddingLeft: 0,
  },
  legendIconButton: {
    marginRight: theme.spacing(1),
    '&:hover': {
      '& .edit-icon': {
        display: 'block',
      },
    },
  },
  legendColorBox: {
    position: 'relative',
    width: 18,
    height: 18,
    margin: 3,
    borderRadius: theme.shape.borderRadius,
  },
  editIcon: {
    display: 'none',
    position: 'absolute',
    top: 9,
    left: 9,
    fontSize: 16,
    color: theme.palette.common.white,
  },
  count: {
    color: theme.palette.text.secondary,
  },
  legendColorGradient: {
    width: '100%',
    height: 24,
    borderRadius: theme.shape.borderRadius,
  },
}));

type Props = {
  disabled: boolean;
  type: ColorType;
  legend: ColorLegend;
  counts: Record<string, number>;
  mappingItem: MappingItem;
  staggeredColors: Record<number, string>;
  setStaggeredColors: (staggeredColors: Record<number, string>) => void;
  applyDynamicStyling: (options?: {
    _staggeredColors?: Record<number, string>;
  }) => Promise<void>;
};

export function StylingLegend({
  disabled,
  type,
  legend,
  counts,
  mappingItem,
  staggeredColors,
  setStaggeredColors,
  applyDynamicStyling,
}: Props) {
  const { classes, cx, theme } = useStyles();

  const { unitSystem } = useAppStorage();

  const isColorPickerEnabled = type === 'match';

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [indexToEdit, setIndexToEdit] = React.useState<number>(0);

  const handleOpenColorPicker = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setIndexToEdit(index);
  };

  const handleEditColor = (index: number, color: string) => {
    const newStaggeredColors = { ...staggeredColors, [index]: color };
    setStaggeredColors(newStaggeredColors);

    applyDynamicStyling({
      _staggeredColors: newStaggeredColors,
    });
  };

  return (
    <List dense disablePadding>
      {legend.map(({ labelPrefix = '', label, color }, index) => {
        const formattedLabel = (() => {
          if (mappingItem.domainType === 'SIUnit') {
            assert(isNumber(label), 'label must be a number');

            const unit = toSIUnit({
              value: label,
              symbol: mappingItem.domainTypeAttributes.symbol,
            });
            const convertedUnit = convertUnit(unit, unitSystem);
            return (
              labelPrefix + unitToString(convertedUnit, { displaySymbol: true })
            );
          } else {
            return labelPrefix + formatLabel(label);
          }
        })();

        return (
          <ListItem key={index} className={classes.legendListItem}>
            <IconButton
              size="small"
              className={classes.legendIconButton}
              disabled={disabled || !isColorPickerEnabled}
              onClick={(event) => handleOpenColorPicker(event, index)}
            >
              <Box
                className={classes.legendColorBox}
                style={{ backgroundColor: color }}
              />
              <ColorizeIcon className={cx(classes.editIcon, 'edit-icon')} />
            </IconButton>
            <ListItemText
              primary={
                <span>
                  {formattedLabel}{' '}
                  {type === 'match' && (
                    <span className={classes.count}>
                      ({(counts[label] ?? 0).toLocaleString()})
                    </span>
                  )}
                </span>
              }
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>
        );
      })}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box
          width={
            SMALL_SQ_SIZE * 10 + SMALL_SQ_GAP * 9 + parseInt(theme.spacing(4))
          }
          p={2}
        >
          <ColorPicker
            variant="compact"
            color={staggeredColors[indexToEdit] ?? DEFAULT_COLOR}
            defaultColor={DEFAULT_COLOR}
            onChange={(color) => handleEditColor(indexToEdit, color as string)}
          />
        </Box>
      </Popover>
    </List>
  );
}

function formatLabel(label: string | number): string {
  return isString(label)
    ? label
    : label.toLocaleString(undefined, {
        maximumSignificantDigits: 4,
      });
}
