import { Button, alpha } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type V3Type = 'filled_tonal';

const useStyles = makeStyles()((theme) => ({
  root: {
    // height: 36,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    // borderRadius: 18,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 'unset',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
    },
    '&.Mui-disabled': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
    '&.MuiButton-colorSecondary': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
      color:
        theme.palette.mode === 'light'
          ? theme.palette.secondary.dark
          : theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.3),
      },
    },
    '&.MuiButton-colorSuccess': {
      backgroundColor: alpha(theme.palette.success.main, 0.2),
      color:
        theme.palette.mode === 'light'
          ? theme.palette.success.dark
          : theme.palette.success.light,
      '&:hover': {
        backgroundColor: alpha(theme.palette.success.main, 0.3),
      },
    },
  },
}));

type Props = any & {
  v3type?: V3Type;
};

export const CustomButton = React.forwardRef<HTMLButtonElement, Props>(
  function CustomButton({ v3type: _v3type, ...rest }, ref) {
    const { classes, cx } = useStyles();

    return (
      <Button
        ref={ref}
        classes={{
          ...rest.classes,
          root: cx(classes.root, rest.classes?.root),
        }}
        {...rest}
      />
    );
  }
);
