import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { type TreeViewBaseItem } from '@mui/x-tree-view';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { useAutoGetDataDocuments } from '@pn/core/providers/data-info/getDataDocuments';
import { useAutoGetStackCompanyDocuments } from '@pn/core/providers/data-info/getStackCompanyDocuments';
import { useDataDocumentsStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { useWebStorageState } from '@pn/core/utils/hooks';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import download from 'downloadjs';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { AuthLink } from 'src/ui/components/AuthLink';
import { FullHeightCircularLoader } from 'src/ui/components/FullHeightCircularLoader';
import { DocumentSearch } from 'src/ui/main-panel/panels/documents/DocumentSearch';
import { DocumentTree } from 'src/ui/main-panel/panels/documents/DocumentTree';
import {
  zipFiles,
  getDocumentFile,
} from 'src/ui/main-panel/panels/documents/file';
import { StackGroupBy } from 'src/ui/main-panel/panels/documents/StackGroupBy';
import type { CustomTreeItem } from 'src/ui/main-panel/panels/documents/utils';
import { buildTree } from 'src/ui/main-panel/panels/documents/utils';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      "controls"
      "documents"
      "panel"
    `,
    gridTemplateRows: 'min-content fit-content(100%) min-content',
    gap: theme.spacing(2),
    height: '100%',
    [`${theme.breakpoints.down('md')}`]: {
      gridTemplateAreas: `
        "controls"
        "documents"
      `,
      gridTemplateRows: 'min-content auto',
    },
  },
}));

type Props = {
  item: WorkspaceItem;
};

export default function Documents({ item }: Props) {
  const { xsScreen, smScreen } = useScreenSize();
  const { classes } = useStyles();

  const isMobile = smScreen || isCordova();

  const { dataItemRequested } = useWorkspaceStorage();
  const { isFetching, documents = [] } = useDataDocumentsStorage(item.dataType);
  const { isFetching: isFetchingStack, documents: stackDocuments = [] } =
    useDataDocumentsStorage(`stack-company-${item.dataType}`);

  const { isAuthenticated } = useAuthenticationService();

  useAutoGetDataDocuments(item.dataType, dataItemRequested.id);
  useAutoGetStackCompanyDocuments(item.dataType, dataItemRequested.id);

  const [searchValue, setSearchValue] = React.useState('');
  const [stackGroupBy, setStackGroupBy] = useWebStorageState<
    'category' | 'folder'
  >('pn', 'group-docs-by', 'category');

  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [isLoadingFiles, setIsLoadingFiles] = React.useState(false);
  const [progressStage, setProgressStage] = React.useState('');
  const [progressCount, setProgressCount] = React.useState(0);

  const items: TreeViewBaseItem<CustomTreeItem>[] = React.useMemo(
    () => buildTree(documents, 'folder', searchValue),
    [documents, searchValue]
  );
  const stackItems: TreeViewBaseItem<CustomTreeItem>[] = React.useMemo(
    () => buildTree(stackDocuments, stackGroupBy, searchValue),
    [stackDocuments, stackGroupBy, searchValue]
  );

  const noResults = isEmpty(items) && isEmpty(stackItems);

  const allDocuments = React.useMemo(
    () => new Map([...documents, ...stackDocuments].map((d) => [d.id, d])),
    [documents, stackDocuments]
  );

  const selectedDocuments = React.useMemo(
    () =>
      selectedItems.map((id) => allDocuments.get(id)).filter((d) => !isNil(d)),
    [selectedItems, allDocuments]
  );

  const handleSelectedItemsChange = (
    event: React.SyntheticEvent | null, // FIXME ESLint doesn't flag this as unused
    ids: string[]
  ) => {
    setSelectedItems(ids);
  };

  const handleDownload = async () => {
    setIsLoadingFiles(true);
    setProgressCount(0);

    try {
      setProgressStage('Fetching files...');

      const fileRequests = selectedDocuments.map((document) =>
        getDocumentFile(document, () => setProgressCount((count) => count + 1))
      );
      const files = await Promise.all(fileRequests);

      setProgressStage('Generating .zip archive...');

      const zip = await zipFiles(files);
      download(zip, dataItemRequested.id + '.zip', 'application/zip');
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: String(error),
      });
    }

    setIsLoadingFiles(false);
  };

  if (!isAuthenticated) {
    return (
      <EmptyStateWithIcon
        variant="filled"
        icon={LockIcon}
        text={
          <span>
            Please <AuthLink type="login">login</AuthLink> or{' '}
            <AuthLink type="signup">sign up</AuthLink> to view documents
          </span>
        }
      />
    );
  }

  if (isFetching || isFetchingStack) return <FullHeightCircularLoader />;
  if (isEmpty(documents) && isEmpty(stackDocuments))
    return (
      <EmptyStateWithIcon
        variant="filled"
        icon={InsertDriveFileIcon}
        text="No documents found"
      />
    );

  return (
    <>
      <Box className={classes.container}>
        <Box
          gridArea="controls"
          display="flex"
          justifyContent="space-between"
          alignItems={xsScreen ? 'flex-start' : 'center'}
          gap={4}
        >
          <StackGroupBy value={stackGroupBy} onChange={setStackGroupBy} />
          <DocumentSearch value={searchValue} onChange={setSearchValue} />
        </Box>

        {noResults ? (
          <EmptyStateWithIcon
            variant="filled"
            icon={InsertDriveFileIcon}
            text="No results found"
            sx={{ gridArea: 'documents' }}
          />
        ) : (
          <Box
            gridArea="documents"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              overflow: 'auto',
              scrollbarGutter: 'stable',
            }}
          >
            {!isEmpty(items) && (
              <DocumentTree
                isMobile={isMobile}
                title="Public documents"
                items={items}
                selectedItems={selectedItems}
                onSelectedItemsChange={handleSelectedItemsChange}
              />
            )}
            {!isEmpty(stackItems) && (
              <DocumentTree
                isMobile={isMobile}
                title="Company documents"
                items={stackItems}
                selectedItems={selectedItems}
                onSelectedItemsChange={handleSelectedItemsChange}
              />
            )}
          </Box>
        )}

        {!isMobile && !noResults && (
          <Box
            gridArea="panel"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={4}
          >
            <Typography color="textSecondary">
              Files selected: {selectedDocuments.length.toLocaleString()}
            </Typography>
            <PermissionButton
              permissionPath="documents.export"
              variant="contained"
              color="secondary"
              disabled={isEmpty(selectedDocuments) || isLoadingFiles}
              onClick={handleDownload}
            >
              Download
            </PermissionButton>
          </Box>
        )}
      </Box>

      <Dialog fullWidth open={isLoadingFiles}>
        <DialogTitle>Preparing</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>{progressStage}</Typography>
          <LinearProgress
            variant="determinate"
            value={(progressCount / selectedItems.length) * 100}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
