import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { UnitSystem } from '@pn/core/domain/types';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useMultiExport } from '@pn/core/operations/export';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { isEmpty } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  button: {
    height: 40,
  },
  buttonIcon: {
    position: 'relative',
    marginLeft: theme.spacing(0.5),
    fontSize: 20,
  },
  unitsSelector: {
    flex: 1,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const ListExports = ({ item }: Props) => {
  const { classes } = useStyles();

  const {
    validExportOptions,
    selectedExportOption,
    setSelectedExportOption,
    exportUnits,
    setExportUnits,
    handleMultiExport,
  } = useMultiExport({ item });

  if (isEmpty(validExportOptions)) {
    return null;
  }

  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
        <Autocomplete
          fullWidth
          size="small"
          disableClearable
          value={selectedExportOption}
          options={validExportOptions}
          getOptionLabel={(option) => option.name}
          onChange={(_e, value) => setSelectedExportOption(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Export type"
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          )}
        />
        <Box display="flex" gap={1}>
          <FormControl className={classes.unitsSelector}>
            <InputLabel id="export-units-select-label">Export units</InputLabel>
            <Select
              size="small"
              labelId="export-units-select-label"
              value={exportUnits}
              label="Export units"
              onChange={(e) => setExportUnits(e.target.value as UnitSystem)}
            >
              <MenuItem value="imperial">Imperial</MenuItem>
              <MenuItem value="metric">Metric</MenuItem>
            </Select>
          </FormControl>
          <PermissionButton
            permissionPath="lists.export"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleMultiExport}
            startIcon={<FileDownloadIcon />}
          >
            Export
          </PermissionButton>
        </Box>
      </Box>
    </>
  );
};
