import { dependencies } from '@pn/core/dependencies';
import { useMapStorage, workspaceActions } from '@pn/core/storage';
import { symbolItemIds } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { webStorageService } from '@pn/services/storage/webStorageService';
import { mapModeDefaultColors } from '@pn/services/styles';
import React from 'react';

export function useMapMode() {
  const { mapMode } = useMapStorage();

  React.useEffect(() => {
    dependencies.map.updateMode(mapMode).then(() => {
      /**
       * Update colors of all symbol PN layers.
       */
      symbolItemIds.forEach((itemId) =>
        workspaceActions().updateAllLayerStyles(itemId, {
          color: mapModeDefaultColors[mapMode],
        })
      );
    });
  }, [mapMode]);

  React.useEffect(() => {
    webStorageService('pn').set('map-mode', mapMode);
  }, [mapMode]);
}
