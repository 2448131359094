import type { ISODateTimeString } from '@pn/core/domain/types';
import { format as formatDate } from 'date-fns';
import { uniq } from 'lodash-es';

export const DISPLAY_DATE_STRING_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_TIME_STRING_FORMAT = "yyyy-MM-dd 'at' h:mm aa";

export function formatISODateTimeString(string: ISODateTimeString) {
  return formatDate(new Date(string), DISPLAY_DATE_TIME_STRING_FORMAT);
}

export function formatISODateTimeStringAsDate(string: ISODateTimeString) {
  return formatDate(new Date(string), DISPLAY_DATE_STRING_FORMAT);
}

export function formatUTCAsUsersLocalTime(utc: string | null) {
  if (!utc) return '';
  const date = new Date(utc);
  return date.toLocaleString();
}

export function generateAllPossibleMonths(
  startDate: string | undefined,
  endDate: string | undefined
): string[] {
  if (!startDate || !endDate) return [];
  if (startDate > endDate) return [];

  const start = new Date(startDate);
  const end = new Date(endDate);
  const months: string[] = [];

  while (start <= end) {
    // Add the current month in 'YYYY-MM' format to the array
    months.push(start.toISOString().slice(0, 7));

    // Move to the next month
    start.setMonth(start.getMonth() + 1);
  }
  return uniq(months);
}
