import { Box, type SxProps, type Theme, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type EmptyStateVariant = 'standard' | 'filled';

const useStyles = makeStyles<{
  variant: EmptyStateVariant;
  height: number | undefined;
}>()((theme, { variant, height }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    height,
    padding: theme.spacing(4),
    background: variant === 'filled' ? theme.palette.action.hover : undefined,
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    color: theme.palette.action.disabled,
    fontSize: 48,
  },
}));

type Props = {
  variant?: EmptyStateVariant;
  text: string | React.ReactNode;
  icon: React.ComponentType<any>;
  height?: number;
  sx?: SxProps<Theme>;
};

export function EmptyStateWithIcon({
  variant = 'standard',
  text,
  icon: Icon,
  height,
  sx,
}: Props) {
  const { classes } = useStyles({ variant, height });

  return (
    <Box className={classes.container} sx={sx}>
      <Icon className={classes.icon} />
      <Typography color="textSecondary">{text}</Typography>
    </Box>
  );
}
