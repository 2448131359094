import type { MapboxIMap } from '..';
import { getBBox } from '@pn/services/utils/geojson';

export function resize(this: MapboxIMap) {
  const canvas = this._native.getCanvas();
  const dpr = window.devicePixelRatio ?? 1;
  const w = canvas.width / dpr;
  const h = canvas.height / dpr;
  const cUL = this._native.unproject([0, 0]).toArray();
  const cUR = this._native.unproject([w, 0]).toArray();
  const cLR = this._native.unproject([w, h]).toArray();
  const cLL = this._native.unproject([0, h]).toArray();
  const coordinates = [cUL, cUR, cLR, cLL, cUL];
  const b = getBBox({
    type: 'Polygon',
    coordinates: [coordinates],
  });

  this._native.fitBounds([b[0], b[1], b[2], b[3]]);
  this._native.resize();
}
