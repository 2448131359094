import {
  createWorkspaceItem,
  getItemColor,
  isDynamicWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { ApiError, ApplicationError } from '@pn/core/errors';
import {
  applyDynamicStyling,
  getDefaultBinCount,
  getDefaultGradientColorPalette,
  getDefaultRadiusRange,
  getDefaultStaggeredColors,
} from '@pn/core/operations/workspace/hooks/useDynamicStyling';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import {
  type GptSqlResult,
  logResult,
} from '@pn/core/services/gpt/GptSqlResult';
import type { PromptMessage } from '@pn/core/services/gpt/ports';
import {
  tableActions,
  useCurrentUserStorage,
  useTableStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import { openaiGptService } from '@pn/services/gpt/openaiGptService';
import { isEmpty, isNil } from 'lodash-es';

// let counter = 0;

export type PromptResult = GptSqlResult & {
  dataType: string;
  item: WorkspaceItem;
};

export function usePromptToWorkspaceItem() {
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();
  const { tableFields } = useTableStorage();

  return async (messages: PromptMessage[]): Promise<PromptResult | string> => {
    try {
      const { dataType, took } = await openaiGptService.inferDataType(messages);
      console.log(`dataType inferred: ${dataType} (${took / 1000} s)`);

      const sourceItem = findOrThrow(
        allWorkspaceItems,
        (item) => item.id === dataType
      );

      const mapping = await dynamicDataProvider(
        sourceItem.dataSource
      ).getDataMapping(sourceItem);

      const gptResult = await openaiGptService.toSql(mapping, messages);
      logResult(gptResult);

      /* Ensure all returned columns are shown in the bottom table */

      const fields = tableFields[dataType];

      if (!isNil(fields)) {
        const fieldsToAdd = gptResult.postQueryAnalysis.columnsUsed.filter(
          (f) => !fields.includes(f)
        );
        if (!isEmpty(fieldsToAdd)) {
          tableActions().addTableFields(dataType, fieldsToAdd);
          console.log('%cAdded fields', 'color: #FF9800', fieldsToAdd);
        }
      } else {
        // TODO combine with force-reset somehow?
      }

      /* Visualize the query */

      const temporaryDynamicItems = allWorkspaceItems.filter(
        (item) => item.isTemporary && isDynamicWorkspaceItem(item)
      );

      // TODO implement a toggle to let the user decide whether to overwrite the last layer
      const newItem = createWorkspaceItem(
        {
          // id: `gpt-${gptResult.overwriteLastLayer ? counter : ++counter}`,
          source: 'item',
          sourceItem,
          name: gptResult.name,
          extraStyle: {
            color:
              gptResult.color ??
              muiColorPalette.getFilterColor(
                temporaryDynamicItems.map(
                  (item) => getItemColor(item) as string
                )
              ),
          },
          queryOptions: {
            sql: gptResult.sql,
          },
        },
        user
      );
      console.log('newItem', newItem.id);

      workspaceActions().add(newItem);
      workspaceActions().addToWorkspace(newItem.id);
      workspaceActions().select(newItem.id);

      if (!isNil(gptResult.colorByProperty)) {
        workspaceActions().revisualize(newItem.id);

        const errorMessage = await applyDynamicStyling({
          item: {
            ...newItem,
            mapping,
            isMappingInitialized: true,
          },
          field: gptResult.colorByProperty,
          staggeredColors: getDefaultStaggeredColors(),
          gradientColorPalette: getDefaultGradientColorPalette(),
          desiredBinCount: getDefaultBinCount(),
          radiusRange: getDefaultRadiusRange(),
          isBubbleMapEnabled: false,
          wasBubbleMapEnabled: false,
          updateAll: true,
        });

        if (errorMessage !== '') workspaceActions().revisualize(newItem.id);
      }

      return { ...gptResult, dataType, item: newItem };
    } catch (error) {
      if (error instanceof ApplicationError) {
        return error.message;
      } else if (error instanceof ApiError && [401, 403].includes(error.code)) {
        return 'Error: You do not have access to GPT features.';
      } else {
        console.error(error);
        return 'Unrecoverable error.';
      }
    }
  };
}
