import type { User } from '@pn/core/domain/user';
import type { IErrorLogger } from '@pn/core/services/loggers/ports';
import { urlToTemplate } from '@pn/services/utils/url';
import { isNil } from 'lodash-es';
import { appsignal } from './appSignalManager';

export function stringifyUserId(userId: User['id'] | undefined): string {
  if (!isNil(userId)) return userId.toString();
  else return 'N/A';
}

export const appSignalErrorLogger: IErrorLogger = {
  logConfigurationError: (error, userId) => {
    appsignal.sendError(error, (span) => {
      span.setNamespace('configuration');
      span.setTags({
        userId: stringifyUserId(userId),
      });
    });
  },
  logApiError: (error, userId) => {
    appsignal.sendError(error, (span) => {
      span.setNamespace('api');
      span.setAction(
        `${error.method} ${error.urlTemplate ? error.urlTemplate : urlToTemplate(error.url)}`
      );
      span.setTags({
        userId: stringifyUserId(userId),
        code: error.code.toString(),
        route: `${error.method} ${error.url}`,
      });
      span.setParams({
        responseData: error.responseData ?? {},
      });
    });
  },
  logGenericError: (error, userId, category) => {
    appsignal.sendError(error, (span) => {
      if (!isNil(category)) span.setAction(category);
      span.setTags({
        userId: stringifyUserId(userId),
      });
    });
  },
};
