import { noop } from 'lodash-es';
import React from 'react';
import { CustomAppBar } from 'src/ui/layout/CustomAppBar';
import { MenuDrawer } from 'src/ui/layout/MenuDrawer';

export type HeaderProps = {
  showSearchbar?: boolean;
  extraTitle?: string;
  isWorkspaceOpen?: boolean;
  setWorkspaceOpen?: (open: boolean) => void;
};

export const Header = React.memo(function Header({
  showSearchbar = false,
  extraTitle,
  isWorkspaceOpen = false,
  setWorkspaceOpen = noop,
}: HeaderProps) {
  const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);

  const onMenuOpen = React.useCallback(
    () => setMenuDrawerOpen(true),
    [setMenuDrawerOpen]
  );
  const onMenuClose = React.useCallback(
    () => setMenuDrawerOpen(false),
    [setMenuDrawerOpen]
  );

  const toggleMenuDrawer = React.useCallback(
    () => setMenuDrawerOpen(!menuDrawerOpen),
    [menuDrawerOpen, setMenuDrawerOpen]
  );

  return (
    <>
      <CustomAppBar
        showSearchbar={showSearchbar}
        isWorkspaceOpen={isWorkspaceOpen}
        extraTitle={extraTitle}
        toggleMenuDrawer={toggleMenuDrawer}
        setWorkspaceOpen={setWorkspaceOpen}
      />

      <MenuDrawer
        open={menuDrawerOpen}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
      />
    </>
  );
});
