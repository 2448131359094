import { colors } from '@mui/material';
import type { ProductionItem } from '@pn/core/domain/production';
import { hasKey } from '@pn/core/utils/logic';
import randomColor from 'randomcolor';
import type { ChartSeriesStyles } from '../types';

const staticColors: Record<string, string> = {
  Oil: colors.green[500],
  Gas: colors.pink[500],
  Water: colors.blue[500],
  Condensate: colors.amber[500], // TODO set to amber[600] in dark mode
  GOR: colors.brown[500],
  WGR: colors.deepOrange[500],
  CGR: colors.amber[500],
  'Water Cut': colors.blueGrey[500],
};

export function getLineStyles(
  key: string,
  seriesStyles: ChartSeriesStyles = {}
  // TODO pass in the theme
) {
  return {
    backgroundColor: seriesStyles[key]?.borderColor ?? getBorderColor(key),
    borderColor: seriesStyles[key]?.borderColor ?? getBorderColor(key),
    borderWidth: seriesStyles[key]?.borderWidth ?? 1.5,
    borderDash: getBorderDash(
      seriesStyles[key]?.borderStyle ?? 'solid',
      seriesStyles[key]?.borderWidth ?? 1.5
    ),
  };
}

function getBorderColor(
  productionGrouping: ProductionItem['productionGrouping']
): string {
  if (hasKey(staticColors, productionGrouping)) {
    return staticColors[productionGrouping];
  } else {
    return randomColor({
      seed: productionGrouping,
      luminosity: 'bright',
    });
  }
}

function getBorderDash(
  borderStyle: 'solid' | 'dashed' | 'dotted',
  borderWidth: number
): [number, number] | undefined {
  switch (borderStyle) {
    case 'dashed':
      return [borderWidth * 3, borderWidth * 2];
    case 'dotted':
      return [borderWidth, borderWidth];
    default:
      return undefined;
  }
}
