import type { DataItemId } from '@pn/core/domain/data';
import { decomposeLayerId, isDataLayerId } from '@pn/core/domain/workspace';
import type { IMapFeatureMapper } from '@pn/core/mappers/map';
import assert from 'minimalistic-assert';
import { isNil } from 'lodash-es';

export const mapboxMapFeatureMapper: IMapFeatureMapper<mapboxgl.MapboxGeoJSONFeature> =
  {
    toDomainMapFeature: (mapboxGeoJSONFeature) => {
      return {
        layerId: mapboxGeoJSONFeature.layer.id,
        internalId: getMapboxFeatureId(mapboxGeoJSONFeature),
        geometry: mapboxGeoJSONFeature.geometry,
        ...decomposeLayerId(mapboxGeoJSONFeature.layer.id),
      };
    },
    toTargetMapFeature: () => {
      throw new Error('Not implemented');
    },
  };

export function isMapboxDataFeature(feature: mapboxgl.MapboxGeoJSONFeature) {
  return (
    isDataLayerId(feature.layer.id) &&
    (!isNil(feature.id) || !isNil(feature.properties?.internal_id))
  );
}

export function getMapboxFeatureId(
  mapboxGeoJSONFeature: mapboxgl.MapboxGeoJSONFeature
): DataItemId {
  const featureId =
    mapboxGeoJSONFeature.properties?.internal_id ?? mapboxGeoJSONFeature.id;
  assert(!isNil(featureId), 'MapboxGeoJSONFeature is missing ID');

  return featureId;
}
