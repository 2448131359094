import type { WKTString } from '@pn/core/domain/types';
import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt';

export function wktToGeometry(wkt: WKTString): GeoJSON.Geometry {
  return wktToGeoJSON(wkt) as GeoJSON.Geometry;
}

export function geometryToWkt(geometry: GeoJSON.Geometry): WKTString {
  return geojsonToWKT(geometry);
}
