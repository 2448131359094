import area from '@turf/area';
import booleanDisjoint from '@turf/boolean-disjoint';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import center from '@turf/center';
import flatten from '@turf/flatten';
import { lineString, polygon } from '@turf/helpers';
import length from '@turf/length';
import assert from 'minimalistic-assert';
import { isArray } from 'lodash-es';

/**
 * @returns length in meters
 */
export function getLength(positions: GeoJSON.Position[]): number;
export function getLength(
  geometry: GeoJSON.LineString | GeoJSON.MultiLineString
): number;
export function getLength(
  feature: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString>
): number;
export function getLength(
  arg:
    | GeoJSON.Position[]
    | GeoJSON.LineString
    | GeoJSON.MultiLineString
    | GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString>
): number {
  if (isArray(arg)) {
    return length(lineString(arg), { units: 'meters' });
  } else if (arg.type === 'Feature') {
    return length(arg, { units: 'meters' });
  } else {
    return length(
      {
        type: 'Feature',
        properties: {},
        geometry: arg,
      },
      { units: 'meters' }
    );
  }
}

/**
 * @returns area in square meters
 */
export function getArea(positions: GeoJSON.Position[]): number;
export function getArea(
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon
): number;
export function getArea(
  feature: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>
): number;
export function getArea(
  arg:
    | GeoJSON.Position[]
    | GeoJSON.Polygon
    | GeoJSON.MultiPolygon
    | GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>
): number {
  if (isArray(arg)) {
    if (arg.length < 3) return 0;
    return area(polygon([arg]));
  } else if (arg.type === 'Feature') {
    return area(arg);
  } else {
    return area({
      type: 'Feature',
      properties: {},
      geometry: arg,
    });
  }
}

export function getCenterPoint(geometry: GeoJSON.Geometry): GeoJSON.Position {
  const centerPoint = center(geometry);
  return centerPoint.geometry.coordinates;
}

export function isWithin(
  container: GeoJSON.Geometry,
  candidate: GeoJSON.Geometry
): boolean {
  assert(
    container.type === 'Polygon' || container.type === 'MultiPolygon',
    'Container must be a Polygon or a MultiPolygon'
  );

  switch (candidate.type) {
    case 'Point':
      return isPointInPolygon(container, candidate);
    case 'MultiPoint':
      return isMultiPointInPolygon(container, candidate);
    case 'LineString':
      return isLineIntersectingPolygon(container, candidate);
    case 'MultiLineString':
      return isMultiLineIntersectingPolygon(container, candidate);
    case 'Polygon':
    case 'MultiPolygon':
      return arePolygonsIntersecting(container, candidate);
    default:
      return false;
  }
}

function isPointInPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  point: GeoJSON.Point
): boolean {
  return booleanPointInPolygon(point.coordinates, polygon);
}

function isMultiPointInPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  multiPoint: GeoJSON.MultiPoint
): boolean {
  return multiPoint.coordinates.every((pt) =>
    isPointInPolygon(polygon, { type: 'Point', coordinates: pt })
  );
}

// FIXME won't work if the line runs through the polygon but doesn't have any points inside it
function isLineIntersectingPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  line: GeoJSON.LineString
): boolean {
  return line.coordinates.some((pt) => booleanPointInPolygon(pt, polygon));
}

function isMultiLineIntersectingPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  multiLine: GeoJSON.MultiLineString
): boolean {
  const lines = flatten(multiLine);
  return lines.features.some((line) =>
    isLineIntersectingPolygon(polygon, line.geometry)
  );
}

function arePolygonsIntersecting(
  polygon1: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  polygon2: GeoJSON.Polygon | GeoJSON.MultiPolygon
): boolean {
  return !booleanDisjoint(polygon1, polygon2);
}
