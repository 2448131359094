import type { IDataInfoDocumentMapper } from '@pn/core/mappers/data-info';
import { drop } from 'lodash-es';
import type { ApiStackCompanyDocument } from './types';

export const apiStackCompanyDocumentMapper: IDataInfoDocumentMapper<ApiStackCompanyDocument> =
  {
    toDomainDocument: (apiDocument) => {
      return {
        source: 'stack',
        id: apiDocument.assetDocPathId.toString(),
        name: apiDocument.fileName,
        title: apiDocument.documentTitle,
        category: apiDocument.assetDocCategory,
        folder: apiDocument.folderPathShort ?? deduceFolder(apiDocument),
        url: apiDocument.detailsUrl,
        downloadUrl: apiDocument.downloadUrl,
      };
    },
    toTargetDocument: () => {
      throw new Error('Not implemented');
    },
  };

function deduceFolder(apiDocument: ApiStackCompanyDocument): string {
  const pathArray = apiDocument.folderPath.split('/');
  return drop(pathArray, pathArray.lastIndexOf('Well') + 2).join('/');
}
