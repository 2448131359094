import { webStorageService } from '@pn/services/storage/webStorageService';
import React from 'react';

export function usePrevious<T>(
  value: T,
  initialValue: NonNullable<T>
): NonNullable<T>;
export function usePrevious<T>(value: T): T | undefined;
export function usePrevious<T>(value: T, initialValue?: T): T | undefined {
  const ref = React.useRef<T | undefined>(initialValue);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useWebStorageState<T>(
  namespace: string,
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const storage = React.useMemo(
    () => webStorageService(namespace),
    [namespace]
  );

  const [value, setValue] = React.useState<T>(() => {
    const stored = storage.get<T>(key);
    return stored === undefined ? defaultValue : stored;
  });

  React.useEffect(() => {
    storage.set(key, value);
  }, [value, key, storage]);

  return [value, setValue];
}
