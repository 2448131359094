import queryString from 'query-string';

type Message<T extends string, V extends object | void = void> = {
  action: T;
} & (V extends object ? { payload: V } : { payload?: never });

export type IncomingMessage<
  T extends string,
  V extends object | void = void,
> = Message<T, V>;
export type OutgoingMessage<
  T extends string,
  V extends object | void = void,
> = Message<T, V>;

export function isEmbedded() {
  return (
    typeof window !== 'undefined' &&
    window.location.hostname.split('.')[0] === 'embedded'
  );
}

export function isInIFrame() {
  return typeof window !== 'undefined' && window !== window.parent;
}

export function isInDebugMode() {
  return queryString.parse(window.location.search).debug === '1';
}

export const log = {
  debug: (message: string, ...args: any[]) => {
    if (isInDebugMode()) {
      console.log('%c[embedded]', 'color: #009688', message, ...args);
    }
  },
  error: (message: string, ...args: any[]) =>
    console.log('%c[embedded error]', 'color: #F44336', message, ...args),
};
