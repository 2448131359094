import { dependencies } from '@pn/core/dependencies';
import type { DataItem } from '@pn/core/domain/data';
import { isAnnotationSource } from '@pn/core/domain/layer';
import { DataSelectionReason } from '@pn/core/domain/query';
import {
  getDataItemSelected,
  getItemBBox,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useRouter } from '@pn/core/operations/router';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { useWorkspaceStorage } from '@pn/core/storage';
import {
  getCanvasBBox,
  REFERENCE_PT,
  REFERENCE_ZOOM,
  toBBox,
} from '@pn/services/drawing';
import {
  combineBBoxes,
  getBBox,
  getCenterPoint,
} from '@pn/services/utils/geojson';
import assert from 'minimalistic-assert';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

export const centerMapOnWorkspaceItem = (item: WorkspaceItem | undefined) => {
  const { map } = dependencies;

  if (isNil(item)) return;

  if (item.itemType === 'annotation') {
    assert(
      isAnnotationSource(item.map.layers[0].source),
      'Invalid annotation source'
    );

    const bbox = getBBox(
      item.map.layers[0].source.data.features.map((f) => f.geometry)
    );
    map.fitToBBox(bbox);

    return;
  }

  if (item.itemType === 'drawing') {
    const layer = item.map.layers[0];
    assert(layer.metadata?.type === 'drawing', 'Invalid drawing layer');
    if (isEmpty(layer.metadata.features)) return;

    const canvasBBox = getCanvasBBox(layer.metadata.features);
    const bbox = toBBox(canvasBBox, REFERENCE_PT, REFERENCE_ZOOM);
    map.fitToBBox(bbox, { maxZoom: 18 });

    return;
  }

  const dataItemSelected = getDataItemSelected(item);

  if (!isNil(dataItemSelected) && !isNil(dataItemSelected.geometry)) {
    centerMapOnDataItems([dataItemSelected]);
  } else {
    centerMapOnDataItems(getMapDataItems(item));
  }
};

export function centerMapOnWorkspaceItems(items: WorkspaceItem[]): void {
  const { map } = dependencies;

  if (isEmpty(items)) return;

  const boundingBoxes = items.reduce<GeoJSON.BBox[]>((acc, item) => {
    const bbox = getItemBBox(item);
    if (bbox) acc.push(bbox);
    return acc;
  }, []);

  if (isEmpty(boundingBoxes)) return;

  const combinedBBox = combineBBoxes(boundingBoxes);

  map.fitToBBox(combinedBBox, {
    padding: 100,
    maxZoom: 11.75,
  });
}

export function centerMapOnDataItems(dataItems: DataItem[]): void {
  const { map } = dependencies;

  if (isEmpty(dataItems)) return;

  const geometries = dataItems
    .map((item) => item.geometry)
    .filter((g) => !isNil(g));
  map.fitToBBox(getBBox(geometries), {
    padding: 50,
    maxZoom: 11.75,
  });
}

export function useAutoCenterMapOnSelectedItem() {
  const {
    query: { zoom: queryZoom },
  } = useRouter();

  const { dataItemSelected, dataItemRequested } = useWorkspaceStorage();

  React.useEffect(() => {
    const { map } = dependencies;

    if (
      dataItemSelected?.geometry &&
      !isNil(dataItemRequested.reason) &&
      dataItemRequested.reason !== DataSelectionReason.MapClick &&
      dataItemRequested.reason !== DataSelectionReason.Related &&
      dataItemRequested.reason !== DataSelectionReason.Search
    ) {
      if (
        dataItemRequested.reason === DataSelectionReason.Url &&
        dataItemSelected.geometry.type !== 'Point'
      ) {
        map.fitToBBox(getBBox(dataItemSelected.geometry), {
          padding: 50,
          maxZoom: 11.75,
        });
      } else {
        const zoom = isNil(queryZoom)
          ? Math.max(map._native.getZoom(), 9.75) // clamp zoom to the old default zoom level
          : Number(queryZoom);
        map.moveTo(getCenterPoint(dataItemSelected.geometry), zoom);
      }
    }
  }, [dataItemSelected, dataItemRequested.reason, queryZoom]);
}
