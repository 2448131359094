import { companyQuarterlyStatsSlice } from '@pn/core/storage/intel/companyQuarterlyStatsSlice';
import { apiCompanyInfoMapper } from '@pn/services/api/intel/apiCompanyInfoMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import type { ReducerSync } from '.';

const { actions } = companyQuarterlyStatsSlice;

export const companyQuarterlyStats: ReducerSync<typeof actions> = {
  add: async (stat, dispatch) => {
    const response = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: `v2/admin/intel`,
      payload: apiCompanyInfoMapper.toTargetStat(stat),
    });
    dispatch(actions._updateId({ currentId: stat.id, newId: response.id }));
  },
  update: ({ statId, stat }) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v2/admin/intel/${statId}`,
      payload: apiCompanyInfoMapper.toTargetStat(stat),
    });
  },
  remove: (statId) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v2/admin/intel${statId}`,
    });
  },
};
