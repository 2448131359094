import { useKeyboardHotkeys } from '@pn/core/operations/keyboardHotkeys';
import { useAutoGetCurrentEnterpriseCombined } from '@pn/core/providers/enterprise/getCurrentEnterprise';
import { useAutoGetCurrentUserProjects } from '@pn/core/providers/project/getCurrentUserProjects';
import { useAutoGetStackProjects } from '@pn/core/providers/stackdx/getStackProjects';
import { useAutoGetTableTemplates } from '@pn/core/providers/table/getTableTemplates';
import { useAutoGetCurrentUser } from '@pn/core/providers/user/getCurrentUser';
import { useAutoGetCurrentEnterpriseInvitedUsers } from '@pn/core/providers/user/getInvitedUsersByEnterpriseId';
import { useAutoGetStackToken } from '@pn/core/providers/user/getStackToken';
import { useAutoGetWorkspaceItems } from '@pn/core/providers/workspace/getWorkspaceItems';
import { useAutoInitializeFreshDesk } from '@pn/services/support/useAutoInitializeFreshDesk';
import { useAutoSetUrlFromCurrentItem } from 'src/ui/effects/useAutoSetUrlFromCurrentItem';
import { useForceResetTableFields } from 'src/ui/effects/useForceResetTableFields';
import { usePersistWorkspace } from 'src/ui/effects/usePersistWorkspace';
import { usePNMapsKeyboardHotkeys } from 'src/ui/effects/usePNMapsKeyboardHotkeys';
import { useEmbedded } from 'src/ui/embedded/useEmbedded';

/**
 * System-wide effects that run on every page of the app.
 */
export function Effects() {
  useAutoGetCurrentUser();
  const stackToken = useAutoGetStackToken();
  useAutoGetWorkspaceItems();
  useAutoGetStackProjects(stackToken);
  useAutoGetCurrentUserProjects();
  useAutoGetTableTemplates();

  useAutoGetCurrentEnterpriseCombined();
  useAutoGetCurrentEnterpriseInvitedUsers();

  useKeyboardHotkeys();
  usePNMapsKeyboardHotkeys();
  useAutoInitializeFreshDesk('petro-ninja');

  return null;
}

/**
 * Only run when the map and/or the data table are visible.
 */
export function MainEffects() {
  useAutoSetUrlFromCurrentItem();
  useForceResetTableFields();
  usePersistWorkspace();

  return null;
}

export function EmbeddedEffects() {
  useEmbedded();
  useAutoSetUrlFromCurrentItem();

  return null;
}
