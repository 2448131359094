import CloseIcon from '@mui/icons-material/Close';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { formatDataType, getDirectionsPosition } from '@pn/core/domain/data';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { unselectDataItem } from '@pn/core/operations/dataSelection';
import { useRouter } from '@pn/core/operations/router';
import { useDataCounts } from '@pn/core/providers/data-info/useDataCounts';
import { useCurrentUserStorage, useWorkspaceStorage } from '@pn/core/storage';
import { PN_LOG_VIEWER_URL } from '@pn/core/urls';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import { getDirectionsUrl } from '@pn/services/map/google/directions';
import { NavRail } from '@pn/ui/custom-components/nav-rail';
import { NavRailItem } from '@pn/ui/custom-components/nav-rail/NavRailItem';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isNil } from 'lodash-es';
import { notificationService } from 'src/application/externalDependencies';
import { APPBAR_HEIGHT } from 'src/ui/layout/CustomAppBar';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { QuickInfo } from 'src/ui/main-panel/quick-info';
import { getMainPanelRoutes } from 'src/ui/main-panel/routes';
import { getMainPanelUri, openInNewTab } from 'src/ui/main-panel/utils';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mainTray: {
    position: 'absolute',
    top: APPBAR_HEIGHT,
    left: 0,
    width: MAIN_TRAY_WIDTH,
    height: `calc(100% - ${APPBAR_HEIGHT}px)`,
    border: 'none',
    overflowX: 'hidden',
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).mainTray,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: 0,
      width: '100%',
      height: 'auto',
      border: 'none',
    },
    '@media print': {
      display: 'none',
    },
  },
  drawerTray: {
    height: '100%',
  },
}));

type Props = {
  isMainTrayOpen: boolean;
};

export default function MainTray({ isMainTrayOpen }: Props) {
  const { classes } = useStyles();
  const { xsScreen } = useScreenSize();

  const { workspaceItemSelected } = useWorkspaceStorage();

  if (!isMainTrayOpen || isNil(workspaceItemSelected)) return null;

  return (
    <Paper square variant="outlined" className={classes.mainTray}>
      {xsScreen && <QuickInfo item={workspaceItemSelected} />}
      <Box className={classes.drawerTray}>
        <Tray item={workspaceItemSelected} />
      </Box>
    </Paper>
  );
}

type TrayProps = {
  item: WorkspaceItem;
};

export const Tray = ({ item }: TrayProps) => {
  const { location } = useRouter();
  const { xsScreen } = useScreenSize();

  const { user, isStackUser } = useCurrentUserStorage();

  const dataItemSelected = getDataItemSelected(item);
  const counts = useDataCounts({
    dataType: item.dataType,
    id: item.requestedDataItem.id,
  });

  const handleOpenDirections = () => {
    if (isNil(dataItemSelected)) {
      notificationService.info('Please wait for the data to load');
      return;
    }

    if (isNil(dataItemSelected.geometry)) {
      notificationService.info(
        `${formatDataType(item.dataType, {
          form: 'singular',
          case: 'sentence',
        })} coordinates not found`
      );
      return;
    }

    const position = getDirectionsPosition(dataItemSelected, item.dataType);
    openInNewTab(getDirectionsUrl(position));
  };

  const handleOpenPNLogViewer = () => {
    openInNewTab(PN_LOG_VIEWER_URL + '/?wellId=' + item.requestedDataItem.id);
  };

  return (
    <NavRail width={MAIN_TRAY_WIDTH}>
      {!xsScreen && (
        <NavRailItem
          Icon={CloseIcon}
          label="Close"
          onClick={() => unselectDataItem(item)}
        />
      )}
      <NavRailItem
        Icon={DirectionsOutlinedIcon}
        label="Directions"
        onClick={handleOpenDirections}
      />
      {getMainPanelRoutes(item.dataType)
        .filter(({ shouldShow = () => true }) => shouldShow({ item, user }))
        .map((route) => {
          const subpanel = getLastRouteSegment(route.path);
          const selected = getLastRouteSegment(location.pathname) === subpanel;

          return (
            <NavRailItem
              key={route.path}
              badgeActive={counts[subpanel] > 0}
              Icon={selected ? route.IconSelected : route.Icon}
              label={route.title}
              selected={selected}
              to={getMainPanelUri({
                dataType: item.dataType,
                dataItemId: item.requestedDataItem.id,
                subpanel,
              })}
            />
          );
        })}
      {!isFreeCordova(user) && !xsScreen && item.dataType === 'wells' && (
        <NavRailItem
          Icon={QueryStatsIcon}
          label="Log Viewer"
          onClick={handleOpenPNLogViewer}
        />
      )}
      {!isCordova() &&
        isStackUser &&
        ['wells', 'wells_usa'].includes(item.dataType) && (
          <Tooltip title="StackDX moved to Documents" placement="right">
            <span>
              <NavRailItem
                Icon={ErrorOutlineIcon}
                label="StackDX"
                to={getMainPanelUri({
                  dataType: item.dataType,
                  dataItemId: item.requestedDataItem.id,
                  subpanel: 'documents',
                })}
              />
            </span>
          </Tooltip>
        )}
    </NavRail>
  );
};

function getLastRouteSegment(path: string): string {
  const segments = path.split('/').filter((segment) => segment !== '');
  return segments[segments.length - 1];
}
