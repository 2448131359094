import FunctionsIcon from '@mui/icons-material/Functions';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import type { Layer } from '@pn/core/domain/layer';
import {
  getColorComponents,
  isNonStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import {
  getDefaultStaggeredColors,
  type DynamicStyling,
} from '@pn/core/operations/workspace/hooks/useDynamicStyling';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import { workspaceActions } from '@pn/core/storage';
import { muiGradientColorPalettes } from '@pn/services/color/colorPalettes';
import { getStepColor } from '@pn/services/utils/color';
import { generateSliderMarks } from '@pn/ui/utils';
import assert from 'minimalistic-assert';
import { isArray, isEmpty, isNil, isString, uniq } from 'lodash-es';
import React from 'react';
import { SliderForm } from 'src/ui/workspace/components/SliderForm';
import { useMapAttributeViewer } from 'src/ui/workspace/components/useMapAttributeViewer';
import {
  LEGEND_LIST_ITEM_HEIGHT,
  StylingLegend,
} from 'src/ui/workspace/styling/StylingLegend';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  error: {
    marginTop: theme.spacing(2),
  },
  stepsContainer: {
    display: 'flex',
    width: `calc(100% - ${theme.spacing(1)})`,
  },
  step: {
    flex: 1,
    height: 23,
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-of-type': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  stylingLegendContainer: {
    marginTop: theme.spacing(2),
    maxHeight: LEGEND_LIST_ITEM_HEIGHT * 10,
    overflowY: 'auto',
  },
  progressIndicator: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const binNumbers = [3, 5, 7, 10, 50];

type Props = {
  disabled: boolean;
  item: WorkspaceItem;
  layer: Layer;
} & DynamicStyling;

export const StyleByAttributeSelector = ({
  disabled,
  item,
  layer,
  isLoading,
  errorMessage,
  isBubbleMapEnabled,
  radiusRange,
  setRadiusRange,
  gradientColorPalette,
  setGradientColorPalette,
  desiredBinCount,
  setDesiredBinCount,
  staggeredColors,
  setStaggeredColors,
  applyDynamicStyling,
}: Props) => {
  const { classes } = useStyles();

  const colorComponents = React.useMemo(
    () => getColorComponents(layer.style.color),
    [layer.style.color]
  );

  const mappingItem = React.useMemo(
    () => item.mapping[colorComponents.field ?? ''],
    [item.mapping, colorComponents.field]
  );

  const [isInitializing, setIsInitializing] = React.useState(false);

  useMapAttributeViewer(item, mappingItem);

  React.useEffect(() => {
    if (!item.isMappingInitialized) {
      setIsInitializing(true);

      (async () => {
        const mapping = await dynamicDataProvider(
          item.dataSource
        ).getDataMapping(item);
        workspaceActions().updateMapping(item.id, mapping);

        setIsInitializing(false);
      })();
    }
  }, [item]);

  const { fields: tableFields } = useTableFields(item.dataType);
  const fields = React.useMemo(
    () =>
      colorComponents.field
        ? uniq([...tableFields, colorComponents.field])
        : tableFields,
    [tableFields, colorComponents.field]
  );

  const optionLabels = React.useMemo(() => {
    return fields.reduce<Record<string, string>>((acc, field) => {
      const mappingItem = item.mapping[field];
      return { ...acc, [field]: mappingItem?.ui.label ?? field };
    }, {});
  }, [fields, item.mapping]);

  const counts: Record<string, number> = React.useMemo(() => {
    if (colorComponents.type !== 'match' || isNil(colorComponents?.field)) {
      return {};
    }

    const counts: Record<string, number> = {};

    getMapDataItems(item).forEach((dataItem) => {
      const value =
        mappingItem?.domainType === 'DateString'
          ? (dataItem[colorComponents.field!] ?? 'N/A').toString().slice(0, 4)
          : (dataItem[colorComponents.field!] ?? 'N/A').toString();
      if (!isString(value)) return;

      if (counts[value]) {
        counts[value] += 1;
      } else {
        counts[value] = 1;
      }
    });

    return counts;
  }, [item, colorComponents.type, colorComponents.field, mappingItem]);

  return (
    <>
      {colorComponents.type === 'step' && (
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
          {isBubbleMapEnabled && (
            <SliderForm
              aria-labelledby="radius-range-slider"
              getAriaValueText={(value) => value.toString()}
              debounced
              title="Radius"
              size="small"
              step={2}
              min={4}
              max={32}
              valueLabelDisplay="auto"
              marks={generateSliderMarks({
                step: 2,
                min: 4,
                max: 32,
              })}
              disabled={disabled}
              value={radiusRange}
              onChange={(_e, range) => {
                assert(isArray(range), 'radius range has to be an array');
                setRadiusRange(range);
                applyDynamicStyling({ _radiusRange: range });
              }}
            />
          )}
          <Box display="flex" gap={2}>
            <Box flex={1.5}>
              <TextField
                fullWidth
                select
                label="Palette"
                value={`${gradientColorPalette.colorLower.toUpperCase()}-${gradientColorPalette.colorUpper.toUpperCase()}`}
                onChange={(event) => {
                  const palette = {
                    colorLower: event.target.value.split('-')[0],
                    colorUpper: event.target.value.split('-')[1],
                  };
                  setGradientColorPalette(palette);
                  applyDynamicStyling({
                    _gradientColorPalette: palette,
                  });
                }}
                slotProps={{
                  select: {
                    renderValue: (palette) => (
                      <Box className={classes.stepsContainer}>
                        {[...Array(5).keys()].map((i) => (
                          <Box
                            key={i}
                            className={classes.step}
                            style={{
                              background: getStepColor({
                                index: i,
                                total: 5,
                                min: (palette as string).split('-')[0],
                                max: (palette as string).split('-')[1],
                              }),
                            }}
                          />
                        ))}
                      </Box>
                    ),
                  },
                }}
              >
                {muiGradientColorPalettes.map((palette) => (
                  <MenuItem
                    key={`${palette.colorLower.toUpperCase()}-${palette.colorUpper.toUpperCase()}`}
                    value={`${palette.colorLower.toUpperCase()}-${palette.colorUpper.toUpperCase()}`}
                  >
                    <Box className={classes.stepsContainer} paddingRight={2}>
                      {[...Array(5).keys()].map((i) => (
                        <Box
                          key={i}
                          className={classes.step}
                          style={{
                            background: getStepColor({
                              index: i,
                              total: 5,
                              min: palette.colorLower,
                              max: palette.colorUpper,
                            }),
                          }}
                        />
                      ))}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box flex={1}>
              <TextField
                fullWidth
                select
                label="Number of steps"
                value={desiredBinCount}
                onChange={(event) => {
                  const numberOfSteps = parseInt(event.target.value);
                  setDesiredBinCount(numberOfSteps);
                  applyDynamicStyling({
                    _desiredBinCount: numberOfSteps,
                  });
                }}
              >
                {binNumbers.map((noOfSteps) => (
                  <MenuItem key={noOfSteps} value={noOfSteps}>
                    {noOfSteps}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
      )}
      <Autocomplete
        disabled={disabled || isLoading}
        value={colorComponents.field ?? null}
        options={fields}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Color by"
            placeholder="Select attribute"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        getOptionLabel={(field) => optionLabels[field]}
        onChange={(_e, field) => applyDynamicStyling({ _field: field })}
      />
      {(isInitializing || isLoading) && (
        <LinearProgress className={classes.progressIndicator} />
      )}
      {isNonStreamableItem(item) && (
        <Alert severity="warning" className={classes.error}>
          Cannot apply dynamic styling to tiled layers. Apply filters/selection
          first.
        </Alert>
      )}
      {!isEmpty(errorMessage) && (
        <Alert severity="warning" className={classes.error}>
          {errorMessage}
        </Alert>
      )}
      {!isEmpty(colorComponents) && !isNil(mappingItem) && (
        <Box className={classes.stylingLegendContainer}>
          <StylingLegend
            disabled={disabled}
            type={colorComponents.type!}
            legend={colorComponents.legend!}
            counts={counts}
            mappingItem={mappingItem}
            staggeredColors={staggeredColors}
            setStaggeredColors={setStaggeredColors}
            applyDynamicStyling={applyDynamicStyling}
          />
        </Box>
      )}
      {!isEmpty(colorComponents) && (
        <Box display="flex" justifyContent="center" gap={2} mt={1} mb={-1}>
          <Button
            disabled={disabled}
            onClick={() => {
              const defaultStaggeredColors = getDefaultStaggeredColors();
              setStaggeredColors(defaultStaggeredColors);
              applyDynamicStyling({
                _staggeredColors: defaultStaggeredColors,
              });
            }}
            startIcon={<FunctionsIcon />}
          >
            Recalculate
          </Button>
          {colorComponents.type === 'match' && (
            <Button
              disabled={disabled}
              onClick={() => {
                setStaggeredColors({});
                applyDynamicStyling({
                  _staggeredColors: {},
                });
              }}
              startIcon={<InvertColorsOffIcon />}
            >
              Unset colors
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
