import { Box } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { BOE_INTEL_URL } from '@pn/core/urls';
import { isCordova, isProduction } from '@pn/core/utils/env';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import React from 'react';
import { Link } from 'react-router-dom';

const dataTypesWithListProduction = ['wells'];

type Props = {
  item: WorkspaceItem;
};

export const ListActions = ({ item }: Props) => {
  const [isInProgress, setIsInProgress] = React.useState(false);

  if (isCordova()) return null;

  const handleViewInBoeIntel = async () => {
    setIsInProgress(true);

    // Open the new tab
    const newTab = window.open(
      `${BOE_INTEL_URL}/dashboards/list_report/${item.id}`,
      '_blank'
    );

    if (!newTab) {
      console.error('Failed to open new tab.');
      setIsInProgress(false);
      return;
    }

    const sendMessage = () => {
      if (!newTab.closed) {
        newTab.postMessage(item, BOE_INTEL_URL);
        setIsInProgress(false);
      } else {
        console.error('New tab closed before sending message.');
        setIsInProgress(false);
      }
    };

    const interval = setInterval(() => {
      if (newTab && !newTab.closed) {
        sendMessage();
        clearInterval(interval);
      }
    }, 500);
  };

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
      {!item.isTemporary &&
        dataTypesWithListProduction.includes(item.dataType) && (
          <PermissionButton
            permissionPath="lists.production"
            isCustom
            component={Link}
            to={`/production/list_aggregate/${item.id}`}
            target="_blank"
          >
            View List Production
          </PermissionButton>
        )}
      {!isProduction() && (
        <>
          {item.isTemporary ? (
            <PermissionButton
              permissionPath="lists.export"
              isCustom
              disabled={isInProgress}
              onClick={handleViewInBoeIntel}
            >
              View in BOE Intel
            </PermissionButton>
          ) : (
            <PermissionButton
              permissionPath="lists.export"
              isCustom
              component="a"
              href={`${BOE_INTEL_URL}/dashboards/list_report/${item.id}`}
              target="_blank"
            >
              View in BOE Intel
            </PermissionButton>
          )}
        </>
      )}
    </Box>
  );
};
