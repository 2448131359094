import circle from '@turf/circle';

export async function generateBuffer(
  geometry:
    | GeoJSON.Point
    | GeoJSON.MultiPoint
    | GeoJSON.LineString
    | GeoJSON.MultiLineString
    | GeoJSON.Polygon
    | GeoJSON.MultiPolygon,
  distance: number
): Promise<
  GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon> | undefined
> {
  const { default: buffer } = await import('@turf/buffer'); // HACK have to import asynchronously to stop @turf/jsts from being included in the main bundle
  return buffer(geometry, distance, { units: 'meters' });
}

export function generateCircle(
  center: GeoJSON.Position,
  radius: number,
  steps = 256
): GeoJSON.Feature<GeoJSON.Polygon> {
  return circle(center, radius, {
    steps,
    units: 'meters',
  });
}
