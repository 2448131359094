import {
  generateStepStyle,
  mappingItemColorGenerator,
  supportedDomainTypes,
} from '@pn/core/domain/data';
import type { Layer } from '@pn/core/domain/layer';
import {
  WorkspaceItem,
  getColorComponents,
  isNonStreamableItem,
  type ColorLegend,
  type ColorType,
} from '@pn/core/domain/workspace';
import type { GradientColorPalette } from '@pn/core/services/color/ports';
import { workspaceActions } from '@pn/core/storage';
import {
  muiColorPalette,
  muiGradientColorPalettes,
} from '@pn/services/color/colorPalettes';
import assert from 'minimalistic-assert';
import { isArray, isEmpty, isNil, last, take } from 'lodash-es';
import React from 'react';
import { getMapDataWithExtraFields } from '../getMapDataWithExtraFields';

export type DynamicStyling = {
  isLoading: boolean;
  errorMessage: string;
  isDynamicStylingDisabled: boolean;
  isBubbleMapEnabled: boolean;
  setIsBubbleMapEnabled: (enabled: boolean) => void;
  staggeredColors: Record<number, string>;
  setStaggeredColors: (colors: Record<number, string>) => void;
  gradientColorPalette: GradientColorPalette;
  setGradientColorPalette: (palette: GradientColorPalette) => void;
  desiredBinCount: number;
  setDesiredBinCount: (count: number) => void;
  radiusRange: number[];
  setRadiusRange: (range: number[]) => void;
  applyDynamicStyling: (options?: {
    _item?: WorkspaceItem;
    _field?: string | null;
    _staggeredColors?: Record<number, string>;
    _gradientColorPalette?: GradientColorPalette;
    _desiredBinCount?: number;
    _radiusRange?: number[];
    _isBubbleMapEnabled?: boolean;
  }) => Promise<void>;
};

export function useDynamicStyling(params: {
  item: WorkspaceItem;
  layer: Layer;
  updateAll: boolean;
}): DynamicStyling {
  const { item, layer, updateAll } = params;

  const colorComponents = React.useMemo(
    () => getColorComponents(layer.style.color),
    [layer.style.color]
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const [isBubbleMapEnabled, setIsBubbleMapEnabled] = React.useState(
    isArray(layer.style.radius)
  );
  const isDynamicStylingDisabled = isNonStreamableItem(item);

  const [staggeredColors, setStaggeredColors] = React.useState<
    Record<number, string>
  >(getDefaultStaggeredColors());
  const [gradientColorPalette, setGradientColorPalette] = React.useState(
    getDefaultGradientColorPalette(colorComponents.type, colorComponents.legend)
  );
  const [desiredBinCount, setDesiredBinCount] = React.useState(
    getDefaultBinCount(colorComponents.type, colorComponents.legend)
  );
  const [radiusRange, setRadiusRange] = React.useState<number[]>(
    getDefaultRadiusRange(layer.style.radius)
  ); // should be [number, number] but MUI stupid

  React.useLayoutEffect(() => {
    const { type, legend } = getColorComponents(layer.style.color);
    setIsBubbleMapEnabled(isArray(layer.style.radius));
    setGradientColorPalette(getDefaultGradientColorPalette(type, legend));
    setDesiredBinCount(getDefaultBinCount(type, legend));
    setRadiusRange(getDefaultRadiusRange(layer.style.radius));
  }, [layer.style.color, layer.style.radius]);

  const _applyDynamicStyling = async ({
    _item = item,
    _field = colorComponents.field as string | null | undefined,
    _staggeredColors = staggeredColors,
    _gradientColorPalette = gradientColorPalette,
    _desiredBinCount = desiredBinCount,
    _radiusRange = radiusRange,
    _isBubbleMapEnabled = isBubbleMapEnabled,
  } = {}) => {
    setIsLoading(true);

    const errorMessage = await applyDynamicStyling({
      item: _item,
      field: _field,
      staggeredColors: _staggeredColors,
      gradientColorPalette: _gradientColorPalette,
      desiredBinCount: _desiredBinCount,
      radiusRange: _radiusRange,
      isBubbleMapEnabled: _isBubbleMapEnabled,
      wasBubbleMapEnabled: isBubbleMapEnabled,
      updateAll,
      layerId: layer.id,
    });

    setIsLoading(false);
    setErrorMessage(errorMessage);
  };

  return {
    isLoading,
    errorMessage,
    isDynamicStylingDisabled,
    isBubbleMapEnabled,
    setIsBubbleMapEnabled,
    staggeredColors,
    setStaggeredColors,
    gradientColorPalette,
    setGradientColorPalette,
    desiredBinCount,
    setDesiredBinCount,
    radiusRange,
    setRadiusRange,
    applyDynamicStyling: _applyDynamicStyling,
  };
}

export function getDefaultGradientColorPalette(
  type?: ColorType | undefined,
  legend?: ColorLegend | undefined
): GradientColorPalette {
  if (type !== 'step' || isNil(legend) || legend.length < 2) {
    return muiGradientColorPalettes[0];
  }

  return {
    colorLower: legend[0].color,
    colorUpper: legend[legend.length - 1].color,
  };
}

const binNumbers = [3, 5, 7, 10, 50];
export function getDefaultBinCount(
  type?: ColorType | undefined,
  legend?: ColorLegend | undefined
): number {
  if (type !== 'step' || isNil(legend) || isEmpty(legend)) {
    return 5;
  }

  for (const binNumber of binNumbers) {
    if (binNumber >= legend.length) {
      return binNumber;
    }
  }

  return binNumbers[binNumbers.length - 1];
}

export function getDefaultRadiusRange(radius?: unknown): number[] {
  if (!isArray(radius)) {
    return [6, 24];
  }

  return [radius[2], last(radius)];
}

export function getDefaultStaggeredColors(): Record<number, string> {
  return take(muiColorPalette.colorsStaggered, 5).reduce<
    Record<number, string>
  >((acc, color, index) => {
    return {
      ...acc,
      [index]: color,
    };
  }, {});
}

export async function applyDynamicStyling(
  params: {
    item: WorkspaceItem;
    field: string | null | undefined;
    staggeredColors: Record<number, string>;
    gradientColorPalette: GradientColorPalette;
    desiredBinCount: number;
    radiusRange: number[];
    isBubbleMapEnabled: boolean;
    wasBubbleMapEnabled: boolean;
  } & (
    | { updateAll: true; layerId?: never }
    | { updateAll: boolean; layerId: string }
  )
): Promise<string> {
  const {
    item,
    field,
    staggeredColors,
    gradientColorPalette,
    desiredBinCount,
    radiusRange,
    isBubbleMapEnabled,
    wasBubbleMapEnabled,
    updateAll,
    layerId,
  } = params;

  if (isNil(field)) {
    const newStyle = { color: muiColorPalette.getStaggeredColorByIndex(0) };
    if (updateAll) {
      workspaceActions().updateAllLayerStyles(item.id, newStyle);
    } else {
      workspaceActions().updateLayerStyle(item.id, layerId, newStyle);
    }

    if (isBubbleMapEnabled && wasBubbleMapEnabled) {
      workspaceActions().disableBubbleMapping(item.id);
    }

    return '';
  }

  const mappingItem = item.mapping[field];
  assert(
    mappingItem,
    `Mapping not found for field ${field}; isMappingInitialized: ${item.isMappingInitialized}`
  );

  if (!supportedDomainTypes.includes(mappingItem.domainType))
    return `Styling by ${mappingItem.domainType} attributes is not supported`;

  const mapData = await getMapDataWithExtraFields(item, [field]);
  if (isEmpty(mapData.data)) {
    return 'No data available';
  }

  const colorGeneratorFn = mappingItemColorGenerator(
    mappingItem,
    muiColorPalette
  );
  const color = colorGeneratorFn(mapData.data, {
    staggeredColors,
    gradientColorPalette,
    desiredBinCount,
  });

  if (updateAll) {
    workspaceActions().updateAllLayerStyles(item.id, { color });
  } else {
    workspaceActions().updateLayerStyle(item.id, layerId, { color });
  }

  if (isBubbleMapEnabled) {
    if (['number', 'SIUnit'].includes(mappingItem.domainType)) {
      const radius = generateStepStyle(mappingItem, mapData.data, {
        minValue: radiusRange[0],
        maxValue: radiusRange[1],
        desiredBinCount,
      });

      workspaceActions().enableBubbleMapping(item.id, { radius });
    } else {
      workspaceActions().disableBubbleMapping(item.id);
    }
  } else if (wasBubbleMapEnabled) {
    workspaceActions().disableBubbleMapping(item.id);
  }

  return '';
}
