import { Paper } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import {
  getProductionGroupings,
  type ProductionChartType,
  type ProductionItem,
} from '@pn/core/domain/production';
import {
  prettyPrintUnitSymbol,
  unitToString,
  type SIUnit,
} from '@pn/core/domain/units';
import { groupedProductionItemsMapper } from '@pn/core/mappers/production/groupedProductionItemsMapper';
import type { ProductionChartMode } from '@pn/services/charts/types';
import { CustomTable } from '@pn/ui/custom-table';
import { isNil } from 'lodash-es';
import React from 'react';

type Props = {
  productionChartType: ProductionChartType;
  productionItems: ProductionItem[];
  chartMode: ProductionChartMode;
  pageSize?: number;
  columnWidthFactor?: number;
  isLoading?: boolean;
};

export const ProductionTable = ({
  productionChartType,
  productionItems,
  chartMode,
  pageSize = 10,
  columnWidthFactor = 1.0,
  isLoading = false,
}: Props) => {
  const rows = React.useMemo(
    () =>
      groupedProductionItemsMapper
        .toGroupedProductionItems(productionItems, chartMode)
        .map((item) => ({
          ...item,
          id: item.date, // DataGrid requires an `id` field
        })),
    [productionItems, chartMode]
  );

  const columns = React.useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'date',
        headerName: 'Date',
        width: 115 * columnWidthFactor,
        disableColumnMenu: true,
      },
      ...getProductionGroupings(productionItems, chartMode).map(
        (productionGrouping): GridColDef => {
          const symbol = productionGrouping.symbol;

          return {
            field: productionGrouping.label,
            type: 'number',
            headerName: symbol
              ? `${productionGrouping.label} (${prettyPrintUnitSymbol(symbol)})`
              : productionGrouping.label,
            width: 110 * columnWidthFactor,
            disableColumnMenu: true,
            valueFormatter: (value: SIUnit | undefined) =>
              !isNil(value) ? unitToString(value) : '-',
            sortComparator: (a: SIUnit | undefined, b: SIUnit | undefined) =>
              (a?.value ?? 0) - (b?.value ?? 0),
          };
        }
      ),
      {
        field: 'hours',
        type: 'number',
        headerName: 'Hours',
        width: 100 * columnWidthFactor,
        disableColumnMenu: true,
        valueFormatter: (value: number | undefined) => value ?? '-',
      },
    ];

    if (['list_aggregate', 'aggregate'].includes(productionChartType)) {
      columns.push({
        field: 'count',
        type: 'number',
        headerName: 'Number of Wells',
        width: 100 * columnWidthFactor,
        disableColumnMenu: true,
      });
    }

    return columns;
  }, [productionChartType, productionItems, chartMode, columnWidthFactor]);

  return (
    <Paper variant="elevation" elevation={0} sx={{ height: '100%' }}>
      <CustomTable
        loading={isLoading}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        areRowClicksEnabled={false}
        rowHeight={32}
        pagination
        pageSizeOptions={[pageSize]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
      />
    </Paper>
  );
};
