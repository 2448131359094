import { ApplicationError } from '@pn/core/errors';
import { openaiGptService } from '@pn/services/gpt/openaiGptService';

export function useGptInferCommand() {
  return async (...args: string[]): Promise<string> => {
    const prompt = args.join(' ');

    try {
      const { dataType } = await openaiGptService.inferDataType([
        { role: 'user', content: prompt },
      ]);

      return `Data type inferred: ${dataType}`;
    } catch (error) {
      if (error instanceof ApplicationError) {
        return error.message;
      } else {
        console.error(error);
        return 'Unrecoverable error.';
      }
    }
  };
}
