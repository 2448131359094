import type { Mapping, MappingItem } from '@pn/core/domain/data';
import {
  DataMultiSelectionReason,
  isSingleSelectionReason,
  type Filter,
  type LinkOperator,
  type Sort,
} from '@pn/core/domain/query';
import { convertSymbol, toSIUnit, unitToString } from '@pn/core/domain/units';
import {
  createWorkspaceItem,
  getItemColor,
  isDynamicWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useVisualizeWorkspaceItem } from '@pn/core/operations/workspace';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { log } from '@pn/core/utils/debug';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import { smartTitlecase } from '@pn/services/utils/string';
import assert from 'minimalistic-assert';
import { isEmpty, isEqual, isNil, isNumber, isString } from 'lodash-es';

export function useFilterWorkspaceItem(tableOnly: boolean) {
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const visualizeWorkspaceItem = useVisualizeWorkspaceItem(tableOnly);

  const applyFilters = async (
    filters: Filter[],
    filtersLinkOperator: LinkOperator,
    item: WorkspaceItem,
    sorts: Sort[] = []
  ) => {
    const areAllFiltersEmpty = filters.every(
      (f) => isNil(f.value) || f.value === ''
    );

    const sourceItem = item.sourceItem ?? item;

    const temporaryItem = (() => {
      const existingTemporaryItem = allWorkspaceItems.find(
        (i) => i.isTemporary && i.id === item.id
      );

      if (!isNil(existingTemporaryItem)) {
        let autogeneratedName = filtersToTitle(filters, item.mapping);
        if (!isEmpty(item.query.requestedIds) && !isEmpty(autogeneratedName))
          autogeneratedName += ' from selection';

        // const str1 = autogeneratedName.replace(/[^0-9a-zA-Z]/g, '');
        // const str2 = existingTemporaryItem.name.replace(/[^0-9a-zA-Z]/g, '');
        // console.log('str1 and str2', str1, str2);

        return {
          ...existingTemporaryItem,
          // name: hasPartialOverlap(str1, str2)
          //   ? autogeneratedName
          //   : existingTemporaryItem.name,
          name: autogeneratedName,
          query: {
            ...existingTemporaryItem.query,
            filters,
            filtersLinkOperator,
            sorts,
          },
          isProcessed: true,
          isRendered: true,
        };
      }

      const temporaryDynamicItems = allWorkspaceItems.filter(
        (item) => item.isTemporary && isDynamicWorkspaceItem(item)
      );

      let autogeneratedName = filtersToTitle(filters, item.mapping);
      if (!isEmpty(item.query.requestedIds) && !isEmpty(autogeneratedName))
        autogeneratedName += ' from selection';

      const newItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem,
          temporarySourceItemId: item.id,
          name: autogeneratedName,
          extraStyle: {
            color: muiColorPalette.getFilterColor(
              temporaryDynamicItems.map((item) => getItemColor(item) as string)
            ),
          },
          queryOptions: {
            requestedIds: item.query.requestedIds,
            multiSelectionReason: item.query.multiSelectionReason,
            filters,
            filtersLinkOperator,
            sorts,
          },
          isVisualized: true,
        },
        user
      );

      return newItem;
    })();

    if (areAllFiltersEmpty) {
      if (item.id === temporaryItem.id) {
        if (
          isEmpty(temporaryItem.query.requestedIds) ||
          (isEmpty(temporaryItem.query.filters) &&
            temporaryItem.query.multiSelectionReason ===
              DataMultiSelectionReason.List)
        ) {
          workspaceActions().remove(temporaryItem);
          workspaceActions().select(
            temporaryItem.temporarySourceItemId ?? sourceItem.id
          );
        } else {
          workspaceActions().update({
            ...temporaryItem,
            query: {
              ...temporaryItem.query,
              filters,
              filtersLinkOperator,
            },
            numberOfElements: temporaryItem.query.requestedIds.length,
            isRendered: false,
            isProcessed: false,
            /**
             * Will only clear the current requested ID if all filters were
             * cleared. Otherwise, the undefined filters will not affect the
             * selection.
             */
            requestedDataItem: isEmpty(temporaryItem.query.filters)
              ? {
                  id: undefined,
                  reason: undefined,
                }
              : temporaryItem.requestedDataItem,
          });
        }
      } else if (!isEqual(filters, item.query.filters)) {
        /* Optimization: avoid triggering a new visualization if filters are the same */
        workspaceActions().updateFiltering(
          item.id,
          filters,
          filtersLinkOperator
        );
      }
    } else {
      if (
        !isEmpty(temporaryItem.query.requestedIds) &&
        (isNil(temporaryItem.query.multiSelectionReason) ||
          isSingleSelectionReason(temporaryItem.query.multiSelectionReason))
      ) {
        /**
         * Reset single selection when applying non-empty filters.
         * Multi-selection (box selection, list, etc.) will not be affected.
         */
        temporaryItem.query.requestedIds = [];
        workspaceActions().updateRequestedIds(sourceItem.id, {
          ids: [],
          reason: undefined,
        });
        workspaceActions().updatePage(sourceItem.id, 0);
      }

      temporaryItem.query.page = 0;

      workspaceActions().add(temporaryItem);
      workspaceActions().addToWorkspace(temporaryItem.id);

      log.info('visualize temporary filtered workspace item', temporaryItem);
      await visualizeWorkspaceItem(temporaryItem);

      workspaceActions().select(temporaryItem.id);
    }
  };

  return { applyFilters };
}

function filtersToTitle(filters: Filter[], mapping: Mapping): string {
  return filters
    .filter((filter) => !isNil(filter.value))
    .map((f) => {
      const mappingItem = mapping[f.field];
      assert(mappingItem, `Mapping not found for field ${f.field}`);
      const value = formatFilterValue(f, mappingItem);

      switch (f.operator) {
        case '=':
          return `=${value}`;
        case '<=':
        case 'onOrBefore':
          return `<${value}`;
        case '>=':
        case 'onOrAfter':
          return `>${value}`;
        case 'notContains':
          return `NOT ${value}`;
        case 'isNil':
          return `${mappingItem.ui.label} is not set`;
        default:
          return value;
      }
    })
    .join(', ');
}

function formatFilterValue(filter: Filter, mappingItem: MappingItem): string {
  if (isNil(filter.value)) return '-';

  if (mappingItem.domainType === 'boolean') {
    const label = truncateBooleanLabel(mappingItem.ui.label);
    return filter.value ? `✔ ${label}` : `⨉ ${label}`;
  } else if (mappingItem.domainType === 'SIUnit') {
    assert(isNumber(filter.value), 'filter value must be a number');
    assert(filter.unitSystem, 'unitSystem must be defined');

    const unit = toSIUnit({
      value: filter.value, // this value must not be converted
      symbol: convertSymbol(
        mappingItem.domainTypeAttributes.symbol,
        filter.unitSystem
      ),
    });

    return unitToString(unit, { displaySymbol: true });
  } else if (isString(filter.value)) {
    return smartTitlecase(filter.value);
  } else {
    return filter.value.toString();
  }
}

function truncateBooleanLabel(label: string): string {
  return label.replace(/^(Has|Is) /, '');
}
