import {
  isPolyClosed,
  type CanvasFeature,
  type CircleFeature,
  type PolyFeature,
} from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import { REFERENCE_PT, REFERENCE_ZOOM } from '@pn/services/drawing/map';
import { generateCircle, getLength } from '@pn/services/utils/geojson';

export function canvasFeatureToGeoJSON(
  feature: CanvasFeature
): GeoJSON.Feature {
  switch (feature.type) {
    case 'circle':
      return circleToGeoJSON(feature);
    case 'poly':
      return polyToGeoJSON(feature);
    default:
      throw new Error(`Unsupported GeoJSON conversion: ${feature.type}`);
  }
}

function circleToGeoJSON(
  feature: CircleFeature
): GeoJSON.Feature<GeoJSON.Polygon> {
  const center = canvasPointToPosition(
    feature.center,
    REFERENCE_PT,
    REFERENCE_ZOOM
  );
  const rightmostPoint = canvasPointToPosition(
    {
      x: feature.center.x + feature.radius,
      y: feature.center.y,
    },
    REFERENCE_PT,
    REFERENCE_ZOOM
  );

  return generateGeoJSONCircle(center, rightmostPoint);
}

function generateGeoJSONCircle(
  center: GeoJSON.Position,
  rightmostPoint: GeoJSON.Position
): GeoJSON.Feature<GeoJSON.Polygon> {
  const radius = getLength([center, rightmostPoint]);
  return generateCircle(center, radius);
}

function polyToGeoJSON(
  feature: PolyFeature
): GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Polygon> {
  const coordinates = feature.coordinates.map((point) =>
    canvasPointToPosition(point, REFERENCE_PT, REFERENCE_ZOOM)
  );

  return {
    type: 'Feature',
    properties: {},
    geometry: isPolyClosed(feature)
      ? { type: 'Polygon', coordinates: [coordinates] }
      : { type: 'LineString', coordinates },
  };
}

export function canvasPointToPosition(
  point: Point,
  origin: GeoJSON.Position,
  zoom: number
): GeoJSON.Position {
  return [
    xToLongitude(point.x, origin, zoom),
    yToLatitude(point.y, origin, zoom),
  ];
}

const TILE_SIZE = 512; // Mapbox GL JS default tile size

function xToLongitude(
  x: number,
  origin: GeoJSON.Position,
  zoom: number
): number {
  // Calculate the map width in pixels at the given zoom level
  const mapWidth = TILE_SIZE * Math.pow(2, zoom);

  // Convert the origin longitude to the Mercator x coordinate
  const mercatorXRef = ((origin[0] + 180) / 360) * mapWidth;

  // Adjust the x-coordinate to account for the origin longitude
  const finalX = x + (mercatorXRef - mapWidth / 2);

  // Convert the x-coordinate to a longitude
  const mercatorX = finalX / mapWidth;
  const longitude = mercatorX * 360;

  return longitude;
}

function yToLatitude(
  y: number,
  origin: GeoJSON.Position,
  zoom: number
): number {
  const MAX_LATITUDE = 85.0511287798; // The maximum latitude for the Mercator projection
  const RADIANS_TO_DEGREES = 180 / Math.PI;
  const DEGREES_TO_RADIANS = Math.PI / 180;

  // Calculate the map height in pixels at the given zoom level
  const mapHeight = TILE_SIZE * Math.pow(2, zoom);

  // Calculate the Mercator Y value for the reference latitude at reference zoom level
  const latRad = origin[1] * DEGREES_TO_RADIANS;
  const mercatorYRef = Math.log(Math.tan(Math.PI / 4 + latRad / 2));
  const yOffset = mapHeight / 2 - (mapHeight * mercatorYRef) / (2 * Math.PI);

  // Adjust the y-coordinate from reference zoom level to the actual zoom level
  const adjustedY = y + yOffset;

  // Convert the y-coordinate to a latitude
  // The origin (0, 0) is at the top-left of the map
  // Convert y from pixels to the Mercator coordinate system
  const mercatorY = 2 * Math.PI * (0.5 - adjustedY / mapHeight);
  const latitude =
    RADIANS_TO_DEGREES * (2 * Math.atan(Math.exp(mercatorY)) - Math.PI / 2);

  // Clamp the latitude to the maximum latitude
  return Math.min(Math.max(latitude, -MAX_LATITUDE), MAX_LATITUDE);
}
