import { safeMap } from '@pn/core/utils/logic';
import { apiAnnotationMapper } from '@pn/services/api/annotation/apiAnnotationMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import type { ApiAnnotation } from './types';

const getAnnotationWorkspaceItemById = async (id: string) => {
  const apiAnnotation = await pnApiClient.request<ApiAnnotation>({
    url: `v2/annotations/${id}`,
  });

  const annotationWorkspaceItem =
    apiAnnotationMapper.toWorkspaceItem(apiAnnotation);

  return annotationWorkspaceItem;
};

const getAnnotationWorkspaceItems = async () => {
  const apiAnnotations = await pnApiClient.request<ApiAnnotation[]>({
    url: `v2/annotations`,
  });

  const annotationWorkspaceItems = safeMap(
    apiAnnotations,
    apiAnnotationMapper.toWorkspaceItem
  );

  return annotationWorkspaceItems;
};

const getAnnotationAreaWorkspaceItems = async () => {
  const apiAnnotationAreas = await pnApiClient.request<ApiAnnotation[]>({
    url: `v2/annotations?is_area=true`,
  });

  const annotationAreaWorkspaceItems = safeMap(
    apiAnnotationAreas,
    apiAnnotationMapper.toWorkspaceItem
  );

  return annotationAreaWorkspaceItems;
};

export const apiAnnotationProvider = {
  getAnnotationWorkspaceItemById,
  getAnnotationWorkspaceItems,
  getAnnotationAreaWorkspaceItems,
};
