import { Box, LinearProgress } from '@mui/material';
import { useCliCommands } from '@pn/core/operations/cli';
import { usePromptToWorkspaceItem } from '@pn/core/operations/gpt/promptToWorkspaceItem';
import { useChatReducer } from '@pn/ui/chat/useChatReducer';
import { isEmpty, isString } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Messages } from './Messages';
import { PromptExamples } from './PromptExamples';
import { PromptInput } from './PromptInput';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'chat',
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    borderLeft: `1px solid ${theme.palette.divider}`,
    fontSize: theme.typography.body2.fontSize,
    '@media print': {
      display: 'none',
    },
  },
  messages: {
    display: 'flex',
    flexDirection: 'column-reverse',
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingRight: 0,
    overflow: 'auto',
    overflowAnchor: 'auto', // https://stackoverflow.com/questions/72636816/scroll-to-bottom-when-new-message-added/72644230#72644230
    scrollbarGutter: 'stable',
  },
}));

// TODO display access alert to non-authorized users

type Props = {
  open: boolean;
};

export const Chat = ({ open }: Props) => {
  const { classes } = useStyles();

  const [prompt, setPrompt] = React.useState('');
  const [inProgress, setInProgress] = React.useState(false);

  const { messages, addMessages, clearMessages } = useChatReducer();

  const commands = useCliCommands({
    clear: {
      access: { denied: false, message: '' },
      description: 'Clear the chat history.',
      fn: () => {
        clearMessages();
        return '';
      },
    },
    help: {
      access: { denied: false, message: '' },
      description: 'Show a list of available commands.',
      fn: () => {
        return Object.entries(commands)
          .map(([key, command]) => `**${key}** - ${command.description}`)
          .join('<br>');
      },
    },
  });
  const perform = usePromptToWorkspaceItem();

  const handlePromptChange = (value: string) => {
    setPrompt(value);
  };

  const handlePromptSubmit = async () => {
    if (prompt === '') return;

    addMessages({ role: 'user', content: prompt });
    setPrompt('');

    const commandCandidate = prompt.split(' ')[0];

    if (prompt.startsWith('/')) {
      const [command, ...args] = prompt.slice(1).split(' ');
      const commandFn = commands[command]?.fn;
      if (commandFn) {
        const result = await commandFn(...args);
        if (result !== '') {
          addMessages({ role: 'system', content: result });
        }
      } else {
        addMessages({
          role: 'system',
          content: `Command not found: ${command}.`,
        });
      }

      return;
    } else if (Object.keys(commands).includes(commandCandidate)) {
      addMessages({
        role: 'system',
        content: `Did you forget to add a / before the command?`,
      });

      return;
    }

    makeRequest(prompt);
  };

  const handleExampleClick = (example: string) => {
    addMessages({ role: 'user', content: example });
    makeRequest(example);
  };

  async function makeRequest(prompt: string) {
    setInProgress(true);

    const promptMessages = messages
      .filter((m) => m.role === 'user' || m.role === 'assistant')
      .map((m) => ({
        role: m.role,
        content: isString(m.content) ? m.content : m.content.sql,
      }));

    const result = await perform([
      ...promptMessages,
      { role: 'user', content: prompt },
    ]);

    addMessages({ role: 'assistant', content: result });
    setInProgress(false);
  }

  if (!open) return null;

  return (
    <Box className={classes.container}>
      <Box className={classes.messages}>
        <Messages messages={messages} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2,
          pt: 0,
        }}
      >
        {isEmpty(messages) && <PromptExamples onClick={handleExampleClick} />}
        {inProgress && <LinearProgress />}
        <PromptInput
          disabled={inProgress}
          messages={messages}
          value={prompt}
          onChange={handlePromptChange}
          onSubmit={handlePromptSubmit}
        />
      </Box>
    </Box>
  );
};
