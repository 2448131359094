import { Box, Typography } from '@mui/material';
import { RichTreeView, type TreeViewBaseItem } from '@mui/x-tree-view';
import {
  DocumentTreeItem,
  type DocumentTreeItemProps,
} from 'src/ui/main-panel/panels/documents/DocumentTreeItem';
import type { CustomTreeItem } from 'src/ui/main-panel/panels/documents/utils';

type Props = {
  isMobile: boolean;
  title: string;
  items: TreeViewBaseItem<CustomTreeItem>[];
  selectedItems: string[];
  onSelectedItemsChange: (
    event: React.SyntheticEvent | null,
    items: string[]
  ) => void;
};

export const DocumentTree = ({
  isMobile,
  title,
  items,
  selectedItems,
  onSelectedItemsChange,
}: Props) => {
  return (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>
      {isMobile ? (
        <RichTreeView
          items={items}
          slots={{ item: DocumentTreeItem }}
          slotProps={{
            item: { isMobile: true } as DocumentTreeItemProps,
          }}
        />
      ) : (
        <RichTreeView
          checkboxSelection
          multiSelect
          selectionPropagation={{
            descendants: true,
            parents: true,
          }}
          items={items}
          selectedItems={selectedItems}
          onSelectedItemsChange={onSelectedItemsChange}
          slots={{ item: DocumentTreeItem }}
        />
      )}
    </Box>
  );
};
