import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'minimalistic-assert';
import { isNil } from 'lodash-es';
import React from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddToListDialog = ({ open, onClose }: Props) => {
  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected, allWorkspaceItems } = useWorkspaceStorage();

  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();

  const [listSelected, setListSelected] = React.useState<WorkspaceItem | null>(
    null
  );

  const lists = React.useMemo(() => {
    return allWorkspaceItems.filter(
      (item) =>
        item.itemType === 'list' &&
        workspaceItemSelected?.dataType === item.dataType &&
        isEditable(item, user)
    );
  }, [allWorkspaceItems, workspaceItemSelected?.dataType, user]);

  if (isNil(workspaceItemSelected)) return null;

  const handleAdd = () => {
    assert(listSelected, 'listSelected is required');
    assert(
      workspaceItemSelected.requestedDataItem.id,
      'workspaceItemSelected.requestedDataItem.id is required'
    );

    workspaceActions().updateRequestedIds(listSelected.id, {
      ids: [
        ...listSelected.query.requestedIds,
        workspaceItemSelected.requestedDataItem.id,
      ],
      reason: DataMultiSelectionReason.List,
    });

    openWorkspaceItemPanel({ item: listSelected });
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>
        Add{' '}
        {formatDataType(workspaceItemSelected.dataType, { form: 'singular' })}{' '}
        to a list
      </DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <Autocomplete
            fullWidth
            options={lists}
            value={listSelected}
            renderOption={({ key, ...rest }, item) => (
              <li key={key} {...rest}>
                {item.name}
              </li>
            )}
            getOptionLabel={(item) => item.name}
            renderInput={(params) => (
              <TextField {...params} label="Find" autoFocus />
            )}
            onChange={(_e, item) => setListSelected(item)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isNil(listSelected)}
          onClick={handleAdd}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
