import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { capitalize } from 'lodash-es';
import type { QuickInfoPanelProps } from '.';

export const MineralRightsQuickInfo = (props: QuickInfoPanelProps) => {
  const { classes, dataItem, onClose } = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" className={classes.fontWeight500}>
              {dataItem.agreement_id?.toString()}-{dataItem.tract?.toString()}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2" align="right">
              <span className={classes.fontWeight500}>Status:</span>{' '}
              {capitalize(dataItem.status?.toString())}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="50%">
            {/* <Typography variant="body2" noWrap>Very long company name for testing purposes</Typography> */}
            <Typography variant="body2" noWrap>
              {dataItem.participants?.toString()}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2" align="right">
              <span className={classes.fontWeight500}>Term:</span>{' '}
              {dataItem.expiry_date?.toString() ?? 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionWrapper}>
        <IconButton size="small" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
