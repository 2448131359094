import { dependencies } from '@pn/core/dependencies';
import { handleError } from '@pn/core/errors/handleError';
import { apiSearchProvider } from '@pn/services/api/search/apiSearchProvider';
import { isEmpty } from 'lodash-es';
import {
  clearGridSearchResultLayer,
  handleSearchResult,
} from './handleSearchResult';

export async function handleSearch(text: string): Promise<void> {
  const { notificationService } = dependencies;

  if (isEmpty(text)) return;

  try {
    const searchResults = await apiSearchProvider.searchByText(text);

    if (isEmpty(searchResults))
      return notificationService.info('No results found');

    const searchResult = searchResults[0];

    handleSearchResult(searchResult);
  } catch (error) {
    clearGridSearchResultLayer();
    handleError({
      error,
      userFriendlyMessage: 'Search failed',
    });
  }
}
