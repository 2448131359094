import type { Theme } from '@mui/material';

/**
 * mobileStepper: 1000,
 * fab: 1050,
 * speedDial: 1050,
 * appBar: 1100,
 * drawer: 1200,
 * modal: 1300,
 * snackbar: 1400,
 * tooltip: 1500,
 */

export const zIndex = (theme: Theme) => ({
  appBar: theme.zIndex.appBar,
  bottomTable: theme.zIndex.appBar + 1,
  drawingToolbar: theme.zIndex.appBar + 2,
  draggableHandle: theme.zIndex.appBar + 3,
  mainPanel: theme.zIndex.appBar + 50,
  mainTray: theme.zIndex.appBar + 51,
  // draggableHandle: theme.zIndex.appBar + 52,
  library: theme.zIndex.appBar + 53,
  workspace: theme.zIndex.appBar + 54,
});
