import { isEmbedded } from '@pn/core/utils/embedded';
import type { IAuthenticationService } from './ports';

export class TokenManager {
  private getAccessToken?: IAuthenticationService['getAccessToken'];
  private cachedToken = '';
  private formatToken: (token: string) => string;

  constructor(formatToken: (token: string) => string = (token) => token) {
    this.formatToken = formatToken;
  }

  /**
   * Retrieves the cached token. Useful in synchronous contexts.
   */
  getCached(raw = false): string {
    return raw ? this.cachedToken : this.formatToken(this.cachedToken);
  }

  /**
   * Sets the function that retrieves the token.
   */
  set(getAccessToken: IAuthenticationService['getAccessToken']): void {
    this.getAccessToken = getAccessToken;
  }

  /**
   * Retrieves the access token by calling the stored function.
   * @param raw If true, returns the raw token without formatting.
   */
  async fetchToken(raw = false): Promise<string | undefined> {
    if (isEmbedded()) return undefined;
    if (!this.getAccessToken) return undefined;
    try {
      const token = await this.getAccessToken();

      this.cachedToken = token;

      return raw ? token : this.formatToken(token);
    } catch {
      return undefined;
    }
  }
}

export const pnTokenManager = new TokenManager((token) => `Bearer ${token}`);
export const stackTokenManager = new TokenManager();
