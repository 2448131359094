import type { PromptResult } from '@pn/core/operations/gpt/promptToWorkspaceItem';
import { generateId } from '@pn/core/utils/id';
import { isArray } from 'lodash-es';
import React from 'react';

type ChatMessagePayload =
  | { role: 'user'; content: string }
  | { role: 'assistant'; content: PromptResult | string }
  | { role: 'system'; content: string };

export type ChatMessage = {
  id: string;
} & ChatMessagePayload;

type ChatMessageParams = ChatMessagePayload & {
  id?: ChatMessage['id'];
};

type ChatAction =
  | { type: 'ADD_MESSAGES'; messages: ChatMessage[] }
  | { type: 'CLEAR_MESSAGES' };

function chatReducer(state: ChatMessage[], action: ChatAction): ChatMessage[] {
  switch (action.type) {
    case 'ADD_MESSAGES':
      return [...state, ...action.messages];
    case 'CLEAR_MESSAGES':
      return [];
    default:
      return state;
  }
}

export function useChatReducer(initialMessages: ChatMessage[] = []) {
  const [messages, dispatch] = React.useReducer(chatReducer, initialMessages);

  const addMessages = (arg: ChatMessageParams | ChatMessageParams[]) =>
    dispatch({
      type: 'ADD_MESSAGES',
      messages: isArray(arg) ? arg.map(addId) : [addId(arg)],
    });
  const clearMessages = () => dispatch({ type: 'CLEAR_MESSAGES' });

  return { messages, addMessages, clearMessages };
}

function addId(message: ChatMessageParams): ChatMessage {
  return { ...message, id: message.id ?? generateId() };
}
