import { Box, Button, Typography } from '@mui/material';

type Props = {
  position: GeoJSON.Position;
  onGetDirections: (position: GeoJSON.Position) => void;
};

export function MapboxPopup({ position, onGetDirections }: Props) {
  return (
    <Box>
      <Typography variant="caption" textAlign="center">
        {position[1].toFixed(6)}, {position[0].toFixed(6)}
      </Typography>
      <Box mt={1}>
        <Button
          fullWidth
          color="inherit"
          variant="outlined"
          size="small"
          onClick={() => onGetDirections(position)}
        >
          Get Directions to Here
        </Button>
      </Box>
    </Box>
  );
}
