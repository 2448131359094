import type {
  CanvasBBox,
  CanvasFeature,
  CircleFeature,
  IconFeature,
  PathFeature,
  TextFeature,
} from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import { getContext, pathToPoints } from '@pn/services/drawing';
import { canvasPointToPosition } from '@pn/services/utils/geojson';
import { isArray } from 'lodash-es';

export function getCanvasBBox(
  arg: CanvasFeature | CanvasFeature[]
): CanvasBBox {
  const canvas = document.getElementById('canvas-live') as HTMLCanvasElement;
  const ctx = getContext(canvas);

  if (isArray(arg)) {
    const bboxes = arg.map((feature) => getFeatureBBox(ctx, feature));

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const bbox of bboxes) {
      const adjustedX = bbox.x - bbox.strokeWidth / 2;
      const adjustedY = bbox.y - bbox.strokeWidth / 2;
      const adjustedWidth = bbox.width + bbox.strokeWidth;
      const adjustedHeight = bbox.height + bbox.strokeWidth;

      minX = Math.min(minX, adjustedX);
      minY = Math.min(minY, adjustedY);
      maxX = Math.max(maxX, adjustedX + adjustedWidth);
      maxY = Math.max(maxY, adjustedY + adjustedHeight);
    }

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      strokeWidth: 0,
    };
  } else {
    return getFeatureBBox(ctx, arg);
  }
}

function getFeatureBBox(
  ctx: CanvasRenderingContext2D,
  feature: CanvasFeature
): CanvasBBox {
  switch (feature.type) {
    case 'path':
      return getBBoxPath(feature);
    case 'poly':
      return getBBoxPoints(feature.coordinates, feature.strokeWidth);
    case 'circle':
      return getBBoxCircle(feature);
    case 'text':
      return getBBoxText(ctx, feature);
    case 'icon':
      return getBBoxIcon(feature);
  }
}

function getBBoxPath(feature: PathFeature): CanvasBBox {
  const points = pathToPoints(feature.pathData);
  return getBBoxPoints(points, feature.strokeWidth);
}

function getBBoxCircle(feature: CircleFeature): CanvasBBox {
  const upperLeft = {
    x: feature.center.x - feature.radius,
    y: feature.center.y - feature.radius,
  };
  const lowerRight = {
    x: feature.center.x + feature.radius,
    y: feature.center.y + feature.radius,
  };

  return {
    x: upperLeft.x,
    y: upperLeft.y,
    width: lowerRight.x - upperLeft.x,
    height: lowerRight.y - upperLeft.y,
    strokeWidth: feature.strokeWidth,
  };
}

function getBBoxText(
  ctx: CanvasRenderingContext2D,
  feature: TextFeature
): CanvasBBox {
  const x = feature.position.x;
  const initialY = feature.position.y + feature.fontSize;

  ctx.save();

  ctx.font = `${feature.fontSize}px ${feature.fontFamily}`;

  const lines = feature.text.split('\n');
  const lineHeight = feature.fontSize * 1.2;

  const textMetrics = lines.map((line) => ctx.measureText(line));
  const first = textMetrics[0];
  const last = textMetrics[textMetrics.length - 1];

  const textHeight =
    (lines.length - 1) * lineHeight +
    first.actualBoundingBoxAscent +
    last.actualBoundingBoxDescent;
  const textWidth = Math.max(...textMetrics.map((metrics) => metrics.width));

  ctx.restore();

  return {
    x,
    y: initialY - first.actualBoundingBoxAscent,
    width: textWidth,
    height: textHeight,
    strokeWidth: 0,
  };
}

function getBBoxIcon(feature: IconFeature): CanvasBBox {
  const [viewBoxX, viewBoxY, viewBoxW, viewBoxH] = feature.icon.viewBox
    .split(/\s+/)
    .map(Number);

  const viewBoxWidth = viewBoxW - viewBoxX;
  const viewBoxHeight = viewBoxH - viewBoxY;

  const height = feature.iconSize;
  const width = feature.iconSize * (viewBoxWidth / viewBoxHeight);

  const x = feature.position.x - width / 2;
  const y = feature.position.y - height / 2;

  return {
    x,
    y,
    width: width,
    height: height,
    strokeWidth: 0,
  };
}

function getBBoxPoints(points: Point[], strokeWidth = 0): CanvasBBox {
  const xs = points.map((point) => point.x);
  const ys = points.map((point) => point.y);

  const upperLeft = {
    x: Math.min(...xs),
    y: Math.min(...ys),
  };
  const lowerRight = {
    x: Math.max(...xs),
    y: Math.max(...ys),
  };

  return {
    x: upperLeft.x,
    y: upperLeft.y,
    width: lowerRight.x - upperLeft.x,
    height: lowerRight.y - upperLeft.y,
    strokeWidth,
  };
}

export function isIncluded(outer: CanvasBBox, candidate: CanvasBBox): boolean {
  return (
    outer.x <= candidate.x &&
    outer.y <= candidate.y &&
    outer.x + outer.width >= candidate.x + candidate.width &&
    outer.y + outer.height >= candidate.y + candidate.height
  );
}

export function toBBox(
  canvasBBox: CanvasBBox,
  referencePosition: GeoJSON.Position,
  referenceZoom: number
): GeoJSON.BBox {
  const sw = canvasPointToPosition(
    {
      x: canvasBBox.x,
      y: canvasBBox.y + canvasBBox.height,
    },
    referencePosition,
    referenceZoom
  );
  const ne = canvasPointToPosition(
    {
      x: canvasBBox.x + canvasBBox.width,
      y: canvasBBox.y,
    },
    referencePosition,
    referenceZoom
  );

  return [sw[0], sw[1], ne[0], ne[1]];
}
