import {
  toDomainValue,
  type DataItem,
  type Mapping,
} from '@pn/core/domain/data';
import type {
  IDataItemMapper,
  IDataItemValueMapper,
} from '@pn/core/mappers/data';
import { nullToUndefined, undefinedToNull } from '@pn/core/utils/logic';
import { apiDataItemValueMapper } from '@pn/services/api/data/apiDataItemMapper';
import { geometryToWkt } from '@pn/services/utils/geojson';
import assert from 'minimalistic-assert';
import { isNil, isNumber, isString, isUndefined } from 'lodash-es';
import type { GeoJsonDataItem, GeoJsonValue } from './types';

export const geoJsonDataItemMapper = (
  mapping: Mapping
): IDataItemMapper<GeoJsonDataItem> => {
  return {
    toDomainDataItem: (geoJsonDataItem) => {
      assert(
        isUndefined(geoJsonDataItem.internal_id) || // not needed for exports
          isString(geoJsonDataItem.internal_id) ||
          isNumber(geoJsonDataItem.internal_id),
        'GeoJSON mapping failed: internal_id must be a (string | number)'
      );

      return Object.entries(mapping).reduce<DataItem>(
        (dataItem, [field, mappingItem]) => {
          if (field === 'geometry') return dataItem;

          const fromValue = geoJsonDataItem[field];
          const toValue = geoJsonDataItemValueMapper.toDomainValue(
            fromValue,
            mappingItem!
          );

          dataItem[field] = toValue;
          return dataItem;
        },
        {
          _id: geoJsonDataItem.internal_id,
          geometry: nullToUndefined(geoJsonDataItem.geometry),
        }
      );
    },
    toTargetDataItem: (dataItem) => {
      return Object.entries(mapping).reduce<GeoJsonDataItem>(
        (targetItem, [field, mappingItem]) => {
          if (field === 'internal_id' || field === 'geometry')
            return targetItem;

          const value = dataItem[field];
          const toValue = geoJsonDataItemValueMapper.toTargetValue(
            toDomainValue(value, mappingItem!)
          );

          targetItem[field] = toValue;
          return targetItem;
        },
        {
          internal_id: dataItem._id,
          geometry: undefinedToNull(dataItem.geometry),
        }
      );
    },
  };
};

const geoJsonDataItemValueMapper: IDataItemValueMapper<GeoJsonValue> = {
  toDomainValue: apiDataItemValueMapper.toDomainValue,
  toTargetValue: ({ value, domainType }) => {
    if (isNil(value)) return undefined;

    switch (domainType) {
      case 'string':
        return value;
      case 'number':
        return value;
      case 'boolean':
        return value;
      case 'object':
        return JSON.stringify(value);
      case 'DateString':
        return value;
      case 'SIUnit':
        return value.value; // no unit conversion for exports
      case 'Geometry':
        return value;
      case 'WKT':
        return geometryToWkt(value);
      default:
        return domainType satisfies never;
    }
  },
};
