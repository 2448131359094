import DragHandleIcon from '@mui/icons-material/DragHandle';
import Box from '@mui/material/Box';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isFreeCordova } from '@pn/core/utils/env';
import { clamp } from '@pn/core/utils/number';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { debounce } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import {
  BOTTOM_TABLE_HEIGHT,
  getBottomTableContainerEl,
} from 'src/ui/bottom-table/BottomTable';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const draggableHandleHeight = 16;
const draggableHandleWidth = 48;

const useStyles = makeStyles()((theme) => ({
  draggableHandle: {
    position: 'absolute',
    left: `calc(50% - ${draggableHandleWidth / 2}px)`,
    bottom: -1,
    width: draggableHandleWidth,
    height: draggableHandleHeight,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    textAlign: 'center',
    cursor: 'ns-resize',
    userSelect: 'none',
    zIndex: zIndex(theme).draggableHandle,
    '@media print': {
      display: 'none',
    },
  },
}));

export const DraggableHandle = () => {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();

  const handleInteractionStart = (
    initialMouseY: number,
    initialHeight: number
  ) => {
    const resizeMap = debounce(() => map.resize(), 50);

    function handleMouseMove(e: MouseEvent | TouchEvent) {
      let clientY: number;

      if (e instanceof MouseEvent) {
        clientY = e.clientY;
      } else if (e instanceof TouchEvent) {
        clientY = e.touches[0].clientY;
      } else {
        return;
      }

      const deltaY = initialMouseY - clientY;
      const newHeight = clamp({
        num: initialHeight + deltaY,
        min: 0,
        max: window.innerHeight - 64 - 18,
      });

      getBottomTableContainerEl().style.height = `${newHeight}px`;

      resizeMap();
    }

    function handleInteractionEnd() {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleInteractionEnd);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleInteractionEnd);
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleInteractionEnd);
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleInteractionEnd);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const initialMouseY = e.clientY;
    const initialHeight = getBottomTableContainerEl().offsetHeight;

    handleInteractionStart(initialMouseY, initialHeight);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const initialMouseY = e.touches[0].clientY;
    const initialHeight = getBottomTableContainerEl().offsetHeight;

    handleInteractionStart(initialMouseY, initialHeight);
  };

  const handleDoubleClick = () => {
    getBottomTableContainerEl().style.height = `${BOTTOM_TABLE_HEIGHT}px`;

    map.resize();
  };

  if (smScreen || isEmbedded() || isFreeCordova(user)) return null;

  return (
    <Box
      className={classes.draggableHandle}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onDoubleClick={handleDoubleClick}
    >
      <DragHandleIcon fontSize="small" />
    </Box>
  );
};
