import { dependencies } from '@pn/core/dependencies';
import type { CanvasBBox, CanvasFeature } from '@pn/core/domain/drawing';
import {
  REFERENCE_PT,
  computeMapTransformation,
  getCanvasBBox,
  isIncluded,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'minimalistic-assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useMultiSelectTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { liveCanvasRef, drawingMode, drawingState, historyManager, redraw } =
    useDrawing();

  useHotkeys(
    'ctrl+a',
    (e) => {
      if (!isDrawingPanelOpen) return;

      e.preventDefault();

      drawingState.featuresSelected = selectFeaturesByItemId(
        drawingState.features,
        itemId
      );

      redraw();
    },
    [isDrawingPanelOpen, itemId]
  );

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode !== 'select') return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    let startPoint = { x: 0, y: 0 };
    let isSelecting = false;
    let featureBBoxes: Record<string, CanvasBBox> = {};

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      const isBoxSelection = e.originalEvent.shiftKey;
      const isClickSelection =
        e.originalEvent.ctrlKey && !isNil(drawingState.featureHovered);

      if (
        drawingState.isCustomPanning ||
        !isNil(drawingState.resizeDirection) ||
        !isNil(drawingState.vertexHovered) ||
        (!isBoxSelection && !isClickSelection)
      ) {
        return;
      }

      if (isClickSelection) {
        assert(drawingState.featureHovered, 'featureHovered is undefined');

        const id = drawingState.featureHovered.id;
        if (isNil(drawingState.featuresSelected[id])) {
          drawingState.featuresSelected[id] = drawingState.featureHovered;
        } else {
          delete drawingState.featuresSelected[id];
        }

        redraw();

        return;
      }

      if (isBoxSelection) {
        const transformation = computeMapTransformation(REFERENCE_PT, false);
        const inverseTransformation = {
          dx: -transformation.dx,
          dy: -transformation.dy,
          scale: 1 / transformation.scale,
        };

        startPoint = transformPoint(e.point, inverseTransformation);
        isSelecting = true;
        featureBBoxes = Object.entries(drawingState.features)
          .filter(([, feature]) => feature.itemId === itemId)
          .reduce<Record<string, CanvasBBox>>((acc, [id, feature]) => {
            acc[id] = getCanvasBBox(feature);
            return acc;
          }, {});

        if (!e.originalEvent.ctrlKey) drawingState.featuresSelected = {};

        map.disableMovement();
      }
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || !isSelecting) return;

      const transformation = computeMapTransformation(REFERENCE_PT, false);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const bbox = map._native.getContainer().getBoundingClientRect();
      const point = transformPoint(
        {
          x: e.clientX - bbox.left,
          y: e.clientY - bbox.top,
        },
        inverseTransformation
      );

      const selection: CanvasBBox = {
        x: Math.min(startPoint.x, point.x),
        y: Math.min(startPoint.y, point.y),
        width: Math.abs(point.x - startPoint.x),
        height: Math.abs(point.y - startPoint.y),
        strokeWidth: 2 / transformation.scale,
      };

      drawingState.boxSelection = selection;

      Object.entries(drawingState.features)
        .filter(([, feature]) => feature.itemId === itemId)
        .forEach(([id, feature]) => {
          if (isIncluded(selection, featureBBoxes[id])) {
            drawingState.featuresSelected[id] = feature;
          } else if (!e.ctrlKey) {
            delete drawingState.featuresSelected[id];
          }
        });

      redraw();
    };

    const onMouseUp = () => {
      if (!isSelecting) return;

      drawingState.boxSelection = undefined;

      redraw();

      isSelecting = false;

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    isDrawingPanelOpen,
    drawingMode,
    itemId,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

function selectFeaturesByItemId(
  features: Record<string, CanvasFeature>,
  itemId: string | undefined
): Record<string, CanvasFeature> {
  return Object.entries(features).reduce<Record<string, CanvasFeature>>(
    (acc, [id, feature]) => {
      if (feature.itemId === itemId) {
        acc[id] = feature;
      }
      return acc;
    },
    {}
  );
}
