import { UnitSystem, isDateString } from '@pn/core/domain/types';
import { convertUnit, isSIUnit, unitToString } from '@pn/core/domain/units';
import { isGeoJSONGeometry } from '@pn/services/utils/geojson';
import {
  isBoolean,
  isNil,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from 'lodash-es';
import { hasOnlyDigits } from './string';

export const formatId = (
  unformattedId: string | number,
  dataType: string
): string => {
  let id = unformattedId.toString();

  switch (dataType) {
    case 'wells':
    case 'wells_usa':
      if (id.length === 14 && hasOnlyDigits(id)) {
        // API well number
        id =
          id.slice(0, 2) +
          '-' +
          id.slice(2, 5) +
          '-' +
          id.slice(5, 10) +
          '-' +
          id.slice(10, 12) +
          '-' +
          id.slice(12);
      } else if (id.length === 16 && !id.startsWith('NB')) {
        // Canadian UWI
        if (id.charAt(0) === '2') {
          id =
            id.substring(0, 3) +
            '/' +
            id.substring(3, 4) +
            '-' +
            id.substring(4, 7) +
            '-' +
            id.substring(7, 8) +
            '/' +
            id.substring(8, 11) +
            '-' +
            id.substring(11, 12) +
            '-' +
            id.substring(12, 14) +
            '/' +
            id.substring(14);
        } else {
          id =
            id.substring(0, 3) +
            '/' +
            id.substring(3, 5) +
            '-' +
            id.substring(5, 7) +
            '-' +
            id.substring(7, 10) +
            '-' +
            id.substring(10, 14) +
            '/' +
            id.substring(14);
        }
      }
      break;
    default:
      // no formatting
      break;
  }

  return id;
};

export function formatDomainValue(params: {
  value: unknown;
  unitSystem: UnitSystem;
  displaySymbol?: boolean;
}): string {
  const { value, unitSystem, displaySymbol = false } = params;

  if (isNil(value)) {
    return '';
  }

  if (isBoolean(value)) {
    return value ? 'Yes' : 'No';
  } else if (isNumber(value)) {
    return value.toString();
  } else if (isSIUnit(value)) {
    const convertedUnit = convertUnit(value, unitSystem);
    return unitToString(convertedUnit, { displaySymbol });
  } else if (isGeoJSONGeometry(value)) {
    return '[GeoJSON.Geometry]';
  } else if (isObject(value)) {
    console.warn(
      'Plain objects are not supported when formatting domain values. This indicates a problem with your data.',
      value
    );
    return '[Object]';
  } else if (isDateString(value)) {
    return value;
  } else if (isString(value)) {
    return value;
  } else {
    console.error('Bad domain data', value);
    throw new Error(`Unsupported domain value type: ${typeof value}`);
  }
}

export function getRawDomainValue(
  value: unknown
): string | number | boolean | undefined {
  if (
    isUndefined(value) ||
    isBoolean(value) ||
    isNumber(value) ||
    isString(value)
  ) {
    return value;
  }
  if (isNull(value)) {
    return undefined;
  }
  if (isSIUnit(value)) {
    return value.value; // no unit conversion
  }
  if (isGeoJSONGeometry(value)) {
    return '{geojson-geometry}';
  }

  return '{unknown}';
}

export function formatNumberAsMonth(
  number: number,
  format:
    | 'numeric'
    | '2-digit'
    | 'long'
    | 'short'
    | 'narrow'
    | undefined = 'long'
) {
  return new Date(0, number, 0).toLocaleString(undefined, {
    month: format,
  });
}
