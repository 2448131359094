import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isEmpty, isObject, isString } from 'lodash-es';

export * from './conversion';
export * from './format';
export * from './production';

export type SIUnit = {
  value: number | undefined;
  symbol: string;
};

export function toSIUnit(params: {
  value: number | undefined;
  symbol: string;
}): SIUnit {
  return params;
}

export function isSIUnit(arg: unknown): arg is SIUnit {
  return (
    isObject(arg) &&
    hasKey(arg, 'value') &&
    hasKeyWithType(arg, 'symbol', isString)
  );
}

export function sumSIUnits(units: SIUnit[]): SIUnit {
  if (isEmpty(units)) return { value: undefined, symbol: 'N/A' };
  const initialSymbol = units[0].symbol;
  return units.reduce(
    (acc, { value, symbol }) => {
      if (value === undefined) return acc;
      if (symbol !== acc.symbol) throw new Error('Incompatible units');
      return {
        value: acc.value === undefined ? value : acc.value + value,
        symbol,
      };
    },
    { value: undefined, symbol: initialSymbol }
  );
}
