import { alpha, ClassNameMap, colors } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { formatDataType } from '@pn/core/domain/data';
import { LinkedIdsTable } from '@pn/core/domain/data-info';
import { LayerType } from '@pn/core/domain/layer';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import { createWorkspaceItem } from '@pn/core/domain/workspace';
import { useCurrentUserStorage, workspaceActions } from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import assert from 'minimalistic-assert';
import { isEmpty } from 'lodash-es';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';
import { TableComponent } from './MultiColumnTable';
import { getBBox } from '@pn/services/utils/geojson';

const useStyles = makeStyles()(() => ({
  divider: {
    marginBottom: 2,
  },
}));

type Props = {
  classes: ClassNameMap;
  table: LinkedIdsTable;
};

export function LinkedIdsTableComponent({ classes, table }: Props) {
  const { title, linkedIds, linkedIdsType, linkedCoordinates } = table;
  const { classes: extraClasses } = useStyles();

  const { user } = useCurrentUserStorage();

  const handleViewOnMap = () => {
    if (linkedIdsType && linkedIds.length > 0) {
      const sourceItem = findOrThrow(
        pnWorkspaceItems,
        ({ id }) => id === linkedIdsType
      );

      assert(user, 'User must be defined when processing linked IDs');

      const newItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem,
          id: `linked-ids-${linkedIdsType}`,
          name: `Connected ${formatDataType(linkedIdsType)}`,
          extraStyle: {
            color:
              sourceItem.map.layers[0]?.type === LayerType.Polygon
                ? alpha(colors.pink[500], 0.5)
                : colors.pink[500],
          },
          queryOptions: {
            requestedIds: linkedIds,
            multiSelectionReason: DataMultiSelectionReason.LinkedIds,
          },
        },
        user
      );

      workspaceActions().add(newItem);
      workspaceActions().addToWorkspace(newItem.id);
      workspaceActions().select(newItem.id);
      workspaceActions().unsetRequestedDataItemId(newItem.id);
    }

    if (!isEmpty(linkedCoordinates)) {
      const bbox = getBBox({
        type: 'MultiPoint',
        coordinates: linkedCoordinates,
      });

      map.fitToBBox(bbox, {
        padding: '20%',
        maxZoom: 13,
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.tableTitle}>{title}</Typography>
      </Box>
      <Divider className={extraClasses.divider} />
      <TableComponent classes={classes} table={table} />
      {linkedIds.length > 0 && (
        <Box mt={1} textAlign="center">
          <CustomButton size="small" onClick={handleViewOnMap}>
            View
          </CustomButton>
        </Box>
      )}
    </Box>
  );
}
