import type { Document } from '@pn/core/domain/data-info';
import { isCordova } from '@pn/core/utils/env';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { stackApiClient } from '@pn/services/api/stackApiClient';

type File = {
  blob: Blob;
  name: string;
};

export async function getDocumentFile(
  document: Document,
  onResolved: () => void
): Promise<File> {
  switch (document.source) {
    case 'pn': {
      const { signedUrl } = await pnApiClient.request<{ signedUrl: string }>({
        url: document.downloadUrl,
      });

      const res = await fetch(signedUrl);
      const blob = await res.blob();

      onResolved();

      return { blob, name: document.name };
    }
    case 'stack': {
      const blob = await stackApiClient.request<Blob>({
        url: document.downloadUrl,
      });

      onResolved();

      return { blob, name: document.name };
    }
  }
}

export async function mobileDownload(downloadUrl: string): Promise<void> {
  const { signedUrl } = await pnApiClient.request<{ signedUrl: string }>({
    url: downloadUrl,
  });

  if (isCordova()) {
    window.cordova.InAppBrowser.open(signedUrl, '_system');
  } else {
    window.open(signedUrl, '_blank');
  }
}

export async function zipFiles(files: File[]): Promise<Blob> {
  const { default: JSZip } = await import('jszip');
  const zip = new JSZip();

  files.forEach((file) => zip.file(file.name, file.blob));
  const output = await zip.generateAsync({ type: 'blob' });

  return output;
}
