import { handleError } from '@pn/core/errors/handleError';
import type { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { isSynced, sync } from './api-sync';

export const syncStorageMiddleware: Middleware =
  (store) => (next) => (_action) => {
    const action = _action as PayloadAction; // FIXME

    const [reducer, actionMethod] = action.type.split('/');

    if (isSynced(reducer, actionMethod)) {
      const prevState = store.getState()[reducer];

      sync[reducer][actionMethod](action.payload, store.dispatch).catch(
        (error: Error) => {
          store.dispatch({ type: `${reducer}/_replace`, payload: prevState });

          handleError({
            error,
            userFriendlyMessage: `Sync failed: ${action.type}`,
          });
        }
      );
    }

    return next(action);
  };
