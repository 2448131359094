import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import { useAccess } from '@pn/core/permissions/access';
import { useWorkspaceStorage } from '@pn/core/storage';
import { UnauthorizedAlert } from '@pn/ui/custom-components/UnauthorizedAlert';
import { ColumnsSelector } from '@pn/ui/data/columns/ColumnsSelector';
import { ColumnsTemplates } from '@pn/ui/data/columns/ColumnsTemplates';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fadedLabel: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ColumnsDialog = ({ open, onClose }: Props) => {
  const { classes } = useStyles();

  const access = useAccess();
  const disabled = access('dataTable.columns').denied();

  const { workspaceItemSelected } = useWorkspaceStorage();

  if (isNil(workspaceItemSelected)) return null;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <span className={classes.fadedLabel}>
          {formatDataType(workspaceItemSelected.dataType, {
            case: 'sentence',
          })}{' '}
          /
        </span>{' '}
        Columns
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <UnauthorizedAlert path="dataTable.columns" />

        <ColumnsSelector
          disabled={disabled}
          sourceLayerId={workspaceItemSelected.dataType}
          mapping={workspaceItemSelected.mapping}
        />

        <ColumnsTemplates
          disabled={disabled}
          sourceLayerId={workspaceItemSelected.dataType}
          mapping={workspaceItemSelected.mapping}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
