import { ApiError } from '@pn/core/errors';
import { TokenManager } from '@pn/core/services/authentication/tokenManager';
import type { ApiRequestParams } from '@pn/core/services/http/ports';
import { isNil } from 'lodash-es';
import { getResponseType, isFormData } from './utils';

/**
 * **You probably don't want to use this function directly.**
 * Use `pnApiClient` or `stackApiClient` instead.
 *
 * {@link ApiError} is thrown in the following cases:
 * - Network errors (CORS, timeout, DNS failure, no internet) → `status = 0`
 * - Unexpected runtime errors (e.g., malformed response, unexpected throw) → `status = -1`
 * - Non-2xx HTTP responses:
 *   - `400-499` → Client-side errors (e.g., validation failures, unauthorized requests)
 *   - `500-599` → Server-side errors (e.g., internal server error, service unavailable)
 *
 * @returns Resolves with the parsed response data.
 */
export async function makeRequest<T>({
  baseUrl,
  tokenManager,
  extraHeaders = {},
  params,
}: {
  baseUrl: string;
  tokenManager: TokenManager;
  extraHeaders?: Record<string, string>;
  params: ApiRequestParams;
}): Promise<T> {
  const {
    method = 'GET',
    url,
    urlTemplate,
    payload,
    withCredentials = false,
  } = params;

  const headers: HeadersInit = new Headers({ ...extraHeaders });

  if (!isFormData(payload)) {
    headers.set('Content-Type', 'application/json');
  }

  const token = await tokenManager.fetchToken();
  if (!isNil(token)) headers.set('Authorization', token);

  const fullUrl = new URL(`${baseUrl}${url}`);

  const requestOptions: RequestInit = {
    method,
    headers,
    credentials: withCredentials ? 'include' : 'same-origin',
  };

  if (method === 'GET' && payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (value !== undefined) {
        fullUrl.searchParams.append(key, String(value));
      }
    });
  } else if (['POST', 'PUT', 'DELETE'].includes(method) && payload) {
    requestOptions.body = isFormData(payload)
      ? payload
      : JSON.stringify(payload);
  }

  try {
    const response = await fetch(fullUrl.toString(), requestOptions).catch(
      (error) => {
        throw new ApiError({
          message: `Network error: ${error.message}`,
          code: 0,
          method,
          url,
          urlTemplate,
        });
      }
    );

    const contentType = response.headers.get('Content-Type') ?? '';

    if (!response.ok) {
      let errorMessage = '';
      let responseData: any = null;

      if (contentType.includes('application/json')) {
        try {
          responseData = await response.json();
          errorMessage = responseData.message ?? JSON.stringify(responseData);
        } catch {
          /* JSON parsing failed, will fallback to response.text() */
        }
      }

      if (errorMessage === '') errorMessage = await response.text();

      throw new ApiError({
        message: `Response error: ${errorMessage}`,
        code: response.status,
        method,
        url,
        urlTemplate,
        responseData: responseData ?? errorMessage,
      });
    }

    switch (getResponseType(contentType)) {
      case 'json':
        return await response.json();
      case 'blob':
        return (await response.blob()) as T;
      case 'arrayBuffer':
        return (await response.arrayBuffer()) as T;
      case 'formData':
        return (await response.formData()) as T;
      default:
        return (await response.text()) as T;
    }
  } catch (error) {
    if (error instanceof ApiError) throw error;
    throw new ApiError({
      message: `Unexpected error: ${String(error)}`, // ensures even non-Error types are stringified
      code: -1,
      method,
      url,
      urlTemplate,
    });
  }
}
