/**
 * Make sure to update the list if ab_formations.json or sk_formations.json changes.
 */

export const abFormationNames = [
  'ALBERTA',
  'ALDERSON',
  'ALEXANDRA',
  'ALEXO',
  'ALTYN',
  'APPEKUNNY',
  'ARCHIBALD',
  'ARCS',
  'ARCTOMYS',
  'ARROWWOOD',
  'ATHABASCA',
  'BAD HEART',
  'BALDONNEL',
  'BANFF',
  'BANFF A',
  'BANTRY SHALE',
  'BARIL',
  'BARONS SAND',
  'BASAL COLORADO',
  'BASAL RED BEDS',
  'BASAL SANDSTONE',
  'BASAL SANDSTONE UNIT',
  'BASALT RED BEDS',
  'BATTLE',
  'BEARPAW',
  'BEAVER MINES',
  'BEAVERFOOT',
  'BEAVERHILL LAKE',
  'BELCOURT',
  'BELLE FOURCHE',
  'BELLOY',
  'BELLY RIVER',
  'BIG VALLEY',
  'BIGORAY',
  'BISON CREEK',
  'BLACK CREEK',
  'BLACKSTONE',
  'BLAIRMORE',
  'BLOOD RESERVE',
  'BLUE RIDGE',
  'BLUESKY',
  'BORSATO',
  'BOULDER CREEK',
  'BOUNDARY',
  'BOW ISLAND / VIKING',
  'BRAEBURN',
  'BRAZEAU',
  'BREWSTER LIMESTONE',
  'BRUDELL',
  'BRUIN CREEK',
  'BULLHEAD',
  'BYNG',
  'CADOMIN',
  'CADOTTE',
  'CAIRN',
  'CALCAREOUS',
  'CALMAR',
  'CALUMET',
  'CAMROSE',
  'CARBON',
  'CARDINAL LAKE',
  'CARDIUM',
  'CARLILE',
  'CARNARVON',
  'CATHEDRAL',
  'CDMM',
  'CHARLIE LAKE',
  'CHINCHAGA',
  'CHRISTINA',
  'CHUNGO',
  'CLAMPITT',
  'CLEARWATER',
  'COALSPUR',
  'COLD LAKE',
  'COLONY',
  'COLORADO',
  'CONNELLY CREEK',
  'CONTACT RAPIDS',
  'COOKING LAKE',
  'CORRAL CREEK',
  'COSTIGAN',
  'CROSSFIELD',
  'CROWFOOT',
  'CROWSNEST',
  'CUMMINGS',
  'CUT BANK',
  'CYNTHIA',
  'CYPRESS HILLS',
  'Cordilleran',
  'DALEHURST',
  'DALHOUSIE',
  'DEADWOOD',
  'DEBOLT',
  'DEL BONITA',
  'DETRITAL',
  'DIABER',
  'DINA',
  'DINOSAUR PARK',
  'DIXONVILLE',
  'DOE CREEK',
  'DOIG',
  'DOWLING',
  'DRUMHELLER',
  'DRYWOOD CREEK',
  'DUNVEGAN',
  'DUVERNAY',
  'EARLIE',
  'EAST TWIN',
  'EASTEND',
  'EDMONTON',
  'ELDON',
  'ELK',
  'ELK POINT',
  'ELKO',
  'ELKTON',
  'ELLERSLIE',
  'ELLIS',
  'EMPRESS',
  'ENTRANCE CONGLOMERATE',
  'ERNESTINA LAKE',
  'ETHERINGTON',
  'EXSHAW',
  'FAIR POINT',
  'FAIRHOLME',
  'FALHER',
  'FERNIE',
  'FIREBAG',
  'FIRST WHITE SPECKS',
  'FISH SCALES',
  'FLATHEAD',
  'FLUME',
  'FOREMOST',
  'FORT MOUNTAIN',
  'FORT ST. JOHN',
  'FORT VERMILION',
  'FRENCHMAN',
  'GATES',
  'GATEWAY',
  'GEIKIE SIDING',
  'GENERAL PETROLEUM',
  'GETHING',
  'GILWOOD',
  'GLADSTONE',
  'GLAUCONITIC',
  'GOG',
  'GOLATA',
  'GORDON',
  'GORDONDALE',
  'GRAMINIA',
  'GRAMINIA SILT',
  'GRAND RAPIDS',
  'GRANDE CACHE',
  'GRANITE WASH',
  'GRAVELBOURG',
  'GREEN BEDS',
  'GREY BEDS',
  'GRINNEL',
  'GROSMONT',
  'GROTTO',
  'GRUMBLER',
  'HAIG BROOK',
  'HALFWAY',
  'HAND HILLS',
  'HANSON',
  'HARMON',
  'HAVEN',
  'HAY RIVER',
  'HAYNES',
  'HECTOR',
  'HIGHWOOD',
  'HODGE',
  'HOLLEBEKE',
  'HOME SAND',
  'HONDO',
  'HORSESHOE CANYON',
  'HORSETHIEF',
  'HOTA',
  'HULCROSS',
  'I',
  'II',
  'III',
  'INTERLAKE',
  'IRETON',
  'IRETON / FORT SIMPSON',
  'ISHBEL',
  'J1',
  'J2',
  'J3',
  'JASPER',
  'JEAN MARIE',
  'JOHNSTON CANYON',
  'JOLI FOU',
  'JUMPING POUND',
  'KAKISA',
  'KANANASKIS',
  'KASKAPAU',
  'KEG RIVER',
  'KISKATINAW',
  'KOOTENAY',
  'KOTCHO',
  'KSITUAN',
  'LA LOCHE',
  'LACOMBE',
  'LAKE LOUISE',
  'LARTER',
  'LAST LAKE',
  'LAZENBY LAKE',
  'LEA PARK',
  'LEDUC',
  'LG',
  'LIVINGSTONE',
  'LLAMA',
  'LLOYDMINSTER',
  'LOBSTICK',
  'LOCKER LAKE',
  'LOOMIS',
  'LOON RIVER',
  'LOTSBERG',
  'LOWER FERNIE SHALE',
  'LOWER KEG RIVER',
  'LOWER LYNX',
  'LOWER MANNVILLE',
  'LOWER POROUS',
  'LUNDBRECK',
  'LUSCAR',
  'LYELL',
  'LYNX',
  'LYNX CREEK',
  'Laurentide',
  'MAHTO',
  'MAJEAU LAKE',
  'MALIGNE',
  'MANITOU FALLS',
  'MANNVILLE',
  'MARSHYBANK',
  'MARSIN',
  'MARSTON',
  'MCNAUGHTON',
  'MDL',
  'MEADOW CREEK',
  'MEDICINE HAT',
  'MIDDLE DENSE',
  'MIETTE',
  'MILDRED',
  'MILK RIVER',
  'MILL CREEK',
  'MINNES',
  'MIST MOUNTAIN',
  'MISTAYA',
  'MISTY',
  'MOBERLY',
  'MONTEITH',
  'MONTEITH / NIKANASSIN',
  'MONTNEY',
  'MOOSEBAR',
  'MORRIN',
  'MORRISSEY',
  'MORRO',
  'MOUNT HAWK',
  'MOUNT HEAD',
  'MOUNT WHYTE',
  'MOUNT WILSON',
  'MOUNTAIN PARK',
  'MOWITCH',
  'MURAL',
  'MUSKEG',
  'MUSKIKI',
  'MUSKWA',
  'McKALE',
  'McLAREN',
  'McMURRAY',
  'NANCY',
  'NIKANASSIN',
  'NIKANASSIN / MONTEITH',
  'NIOBRARA',
  'NISKU',
  'NITON',
  'NOMAD',
  'NORDEGG',
  'NORMANDVILLE',
  'NOTIKEWIN',
  'OBED MOUNTAIN',
  'OLD FORT POINT',
  'OLDMAN',
  'OLDMAND',
  'OPABIN',
  'OSTRACOD',
  'OTHERSIDE',
  'OUTRAM',
  'OWEN CREEK',
  'PADDY',
  'PAKOWKI',
  'PALLISER',
  'PASKAPOO',
  'PASSAGE BEDS',
  'PEACE RIVER',
  'PEECHEE',
  'PEKISKO',
  'PELICAN MOUNTAIN',
  'PERDRIX',
  'PEYTO',
  'PHILLIPS',
  'PIGEON CREEK',
  'PIKA',
  'POCATERRA CREEK',
  'POKER',
  'POKER CHIP SHALE',
  'PORCUPINE HILLS',
  'POUCE COUPE',
  'PRAIRIE EVAPORITE',
  'PURCELL',
  'PURCELL LAVA',
  'PUSKWASKAU',
  'RAINBOW',
  'RANGER CANYON',
  'RAVENSCRAG',
  'RED DEER',
  'RED RIVER',
  'REDKNIFE',
  'REX',
  'RIERDON',
  'ROCK CREEK',
  'ROCKY MOUNTAIN',
  'ROOSVILLE',
  'RUNDLE',
  'SALTER',
  'SASSENACH',
  'SAUNDERS',
  'SAWTOOTH',
  'SCHOOLER CREEK',
  'SCOLLARD',
  'SECOND WHITE SPECKS',
  'SHAFTESBURY',
  'SHAUNAVON',
  'SHEPPARD',
  'SHIELS',
  'SHUNDA',
  'SIMLA / RONDE',
  'SIPHON',
  'SIYEH',
  'SKOKI',
  'SLAVE POINT',
  'SMART',
  'SMOKY',
  'SNAKE INDIAN',
  'SNARE',
  'SOLITUDE',
  'SOPHIST',
  'SOUTHESK',
  'SPARKY',
  'SPIRIT RIVER',
  'SPRAY LAKES',
  'SPRAY RIVER',
  'ST. MARY RIVER',
  'ST. PIRAN',
  'STARLIGHT EVAPORITE',
  'STEPHEN',
  'STETTLER',
  'STODDART',
  'STONEWALL',
  'STONY MTN',
  'STORELK',
  'STRATHMORE',
  'SULLIVAN',
  'SULPHUR MOUNTAIN',
  'SULPHUR POINT',
  'SUNBURST SANDSTONE',
  'SUNKAY',
  'SURVEY PEAK',
  'SWAN HILLS',
  'SWIFT',
  'TAILOR FLAT',
  'TATHLINA',
  'TEMPLE LAKE',
  'TETCHO',
  'THISTLE',
  'TIPPERARY',
  'TOBERMORY',
  'TODHUNTER',
  'TOLMAN',
  'TOMBSTONE MOUNTAIN',
  'TORRENS',
  'TROUT RIVER',
  'TURNER VALLEY',
  'TWIN FALLS',
  'TYRWHITT',
  'UNNAMED',
  'UNNAMED UPPER MANNVILLE',
  'UPPER FERNIE SHALE',
  'UPPER KEG RIVER',
  'UPPER LYNX',
  'UPPER MANNVILLE',
  'UPPER MEMBER',
  'UPPER POROUS',
  'VALHALLA',
  'VEGA / PHROSO',
  'VERGER',
  'VIKING',
  'VIMY',
  'WABAMUN',
  'WABISKAW',
  'WAPIABI',
  'WAPITI',
  'WAPITI / BRAZEAU',
  'WASECA',
  'WATERFOWL',
  'WATERTON',
  'WATERWAYS',
  'WATT MOUNTAIN',
  'WEST GATE',
  'WESTGATE',
  'WHISTLER',
  'WHITECOURT MOUNTAIN',
  'WHITEHORN MTN',
  'WHITEHORSE',
  'WHITELAW',
  'WHITEMUD',
  'WILEMAN',
  'WILLOW',
  'WILLOW CREEK',
  'WILRICH',
  'WINDERMERE',
  'WINDSOR MOUNTAIN',
  'WINNIFRED',
  'WINNIPEGOSIS',
  'WINNIPEGOSIS / KEG RIVER',
  'WINTERBURN',
  'WOLF LAKE',
  'WOLVERINE POINT',
  'WOODBEND',
  'WYND',
  'YAHATINDA',
  'ZAMA',
  'ZETA LK',
];
