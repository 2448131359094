import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  inputRoot: {
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const DocumentSearch = ({ value, onChange }: Props) => {
  const { classes } = useStyles();

  return (
    <TextField
      size="small"
      type="search"
      autoComplete="off"
      placeholder="Search by title/name"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        flex: 1,
        maxWidth: 240,
      }}
      slotProps={{
        input: {
          classes: {
            root: classes.inputRoot,
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="clear search value"
                // className={value === '' ? classes.disabled : undefined}
                onClick={() => onChange('')}
              >
                {isEmpty(value) ? <SearchIcon /> : <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
