import { Box } from '@mui/material';
import { isPNDataType } from '@pn/core/domain/data';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { ConfigurationError } from '@pn/core/errors';
import { useLeaseProduction } from '@pn/core/providers/production/useLeaseProduction';
import {
  useDataDetailsStorage,
  useProductionStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isNil } from 'lodash-es';
import { DenseTable } from 'src/ui/main-panel/components/dense-components/DenseTable';
import { ProductionComponent } from './Production';

// http://us.localhost:3000/wells_usa/42473312080000/lease_production

type Props = {
  item: WorkspaceItem;
};

export default function LeaseProduction({ item }: Props) {
  if (!isPNDataType(item.dataType)) {
    throw new ConfigurationError(
      `Cannot render Lease Production panel for a non-PN data type [${item.dataType}]`
    );
  }

  const { dataItemRequested } = useWorkspaceStorage();
  const { isFetching, isError, productionItems } =
    useProductionStorage('lease');
  const { details } = useDataDetailsStorage('lease'); // isFetching and isError are identical to production ones so they are not used

  useLeaseProduction(dataItemRequested.id);

  if (isNil(dataItemRequested.id)) return null;

  const table = details?.[0].tables[0];

  return (
    <>
      <ProductionComponent
        dataType={item.dataType}
        isFetching={isFetching}
        isError={isError}
        productionItems={productionItems}
      />
      {!isNil(table) && (
        <Box mt={2}>
          <DenseTable dataItemId={dataItemRequested.id} table={table} />
        </Box>
      )}
    </>
  );
}
