import { isNil } from 'lodash-es';

export function clamp(params: {
  num: number;
  min: number;
  max: number;
}): number {
  return Math.max(params.min, Math.min(params.num, params.max));
}

export function getMedian(values: number[]) {
  if (values.length === 0) return 0;
  values.sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
}

export function getAverage(values: number[]) {
  if (values.length === 0) return 0;
  return values.reduce((a, b) => a + b) / values.length;
}

export function getSum(values: number[]) {
  if (values.length === 0) return 0;
  return values.reduce((a, b) => a + b);
}

export function formatAsSimplifiedDollarAmount(value: number | null) {
  if (isNil(value)) {
    return '';
  }
  if (value >= 1000000000) {
    return `$${(value / 1000000000).toFixed(1)} B`;
  }
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)} M`;
  }
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)} K`;
  }
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });
}

// format a number as a simplified amount
// 1000 -> 1K
// 1000000 -> 1M
// 1000000000 -> 1B

const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

export function formatAsSimplifiedAmount(
  value: number | null | undefined
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const absValue = Math.abs(value);
  let result = value.toString();

  if (absValue >= BILLION) {
    result = `${(value / BILLION).toFixed(1)} B`;
  } else if (absValue >= MILLION) {
    result = `${(value / MILLION).toFixed(1)} M`;
  } else if (absValue >= THOUSAND) {
    result = `${(value / THOUSAND).toFixed(1)} K`;
  }

  return result;
}

export function convertHectaresToSections(hectares: number) {
  return hectares / 256;
}
export function convertSectionsToHectares(sections: number) {
  return sections * 256;
}
