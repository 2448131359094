import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useRouter } from '@pn/core/operations/router';
import { isCordova, isProduction } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { pnBrandColor } from '@pn/ui/theme/themes/shared';
import { isNil } from 'lodash-es';
import React from 'react';
import { useAuthenticationService } from 'src/application/externalDependencies';
import pn_logo_white from 'src/images/pn_logo_white_2024_03_06.webp';
import { CustomLink } from 'src/ui/components/CustomLink';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  overlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.67)',
    zIndex: 1201,
  },
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 480,
    padding: theme.spacing(4),
    backgroundColor: pnBrandColor,
    color: theme.palette.common.white,
    overflowY: 'auto',
    zIndex: 1201,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 9999,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'calc(100% - 48px)',
  },
  logo: {
    // width: 'calc(100% - 12px)',
    height: 50,
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    opacity: 0.67,
  },
  label: {
    marginLeft: theme.spacing(1.5),
  },
  iconForward: {
    position: 'relative',
    top: -1,
    marginLeft: 6,
    fontSize: '1.2rem',
  },
  version: {
    opacity: 0.75,
  },
  button: {
    background: theme.palette.grey[50],
    color: pnBrandColor,
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.grey[300],
    },
  },
  textButton: {
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'none',
  },
}));

export const Splash = React.memo(function Splash() {
  const { pathname } = useRouter();

  const { classes } = useStyles();
  const { xsScreen } = useScreenSize();

  const { isAuthenticated, login } = useAuthenticationService();

  const [wasClosedBefore, setWasClosedBefore] = React.useState(false);
  const [open, setOpen] = React.useState(!isAuthenticated && pathname === '/');

  React.useLayoutEffect(() => {
    if (isAuthenticated) {
      setOpen(false);
    } else if (!wasClosedBefore && !isAuthenticated && pathname === '/') {
      setOpen(true);
    }
  }, [isAuthenticated, pathname, wasClosedBefore]);

  const handleClose = () => {
    setOpen(false);
    setWasClosedBefore(true);
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <Box className={classes.overlay} />
      <Box className={classes.container}>
        {!xsScreen && (
          <Box display="flex" justifyContent="flex-end" m={-2} mb={2}>
            <IconButton color="inherit" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        )}

        <Box className={classes.content}>
          <Box width="100%" maxWidth="320px">
            <Box className={classes.logo}>
              <img src={pn_logo_white} alt="logo" className={classes.logo} />
            </Box>

            <Box mt={2}>
              <Typography variant="h5">Open Data for Oil and Gas</Typography>
            </Box>

            <Box
              mt={4}
              display="inline-flex"
              flexDirection={isCordova() ? 'column' : 'row'}
              gap={2}
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => login()}
              >
                Login
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                onClick={() => login({ type: 'signup' })}
              >
                Sign up
              </Button>
            </Box>

            {!isCordova() && (
              <>
                <Box mt={4}>
                  <Button
                    component={CustomLink}
                    to="https://about.petroninja.com"
                    variant="text"
                    className={classes.textButton}
                  >
                    Learn more
                  </Button>
                </Box>
                <Box>
                  <Button
                    component={CustomLink}
                    to="https://about.petroninja.com/contact"
                    variant="text"
                    className={classes.textButton}
                  >
                    Contact us
                  </Button>
                </Box>
              </>
            )}
          </Box>

          {!isNil(getVersion()) && (
            <Box mt={8} mb={2}>
              <Typography
                variant="body2"
                align="center"
                className={classes.version}
              >
                {getVersion()}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
});

function getVersion() {
  if (isCordova()) {
    return `#${window.BuildInfo?.versionCode}`;
  } else {
    if (!isProduction()) {
      return `· ${import.meta.env.VITE_APP_HOST_ENV} ·`;
    } else {
      return undefined;
    }
  }
}
