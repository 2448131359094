import type { Mapping } from '@pn/core/domain/data/mappingItem';
import {
  type GptSqlResult,
  GptSqlResultSchema,
} from '@pn/core/services/gpt/GptSqlResult';
import type { IGptService } from '@pn/core/services/gpt/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { gptMappingMapper } from '@pn/services/gpt/gptMappingMapper';
import assert from 'minimalistic-assert';
import { format } from 'date-fns';
import OpenAI from 'openai';
import { zodToJsonSchema } from 'zod-to-json-schema';

export const toSql: IGptService['toSql'] = async (mapping, messages) => {
  const request: OpenAI.Chat.ChatCompletionCreateParams = {
    model: 'gpt-4o-2024-08-06',
    temperature: 0.1,
    top_p: 0.1,
    seed: 0,
    messages: [
      {
        role: 'system',
        content: getSystemMessage(mapping),
      },
      ...messages,
    ],
    response_format: {
      type: 'json_schema',
      json_schema: {
        name: 'schema',
        schema: zodToJsonSchema(GptSqlResultSchema),
      },
    },
  };

  const start = Date.now();

  const response = await pnApiClient.request<OpenAI.Chat.ChatCompletion>({
    method: 'POST',
    url: 'v2/gpt/chat_completions',
    payload: { request },
  });

  assert(response.choices[0].message.content, 'No response content present');
  const gptResult: GptSqlResult = JSON.parse(
    response.choices[0].message.content
  );

  return {
    ...gptResult,
    took: Date.now() - start,
  };
};

function getSystemMessage(mapping: Mapping): string {
  const gptMapping = gptMappingMapper.toTargetMapping(mapping);

  const message =
    `You are an oil & gas expert tasked with generating an SQL query based on a schema.\
    Today's date is ${format(new Date(), 'yyyy-MM-dd')}. Convert all dates into 'yyyy-MM-dd'.\
    The mapping below represents a single wide table with ${gptMapping.length} columns.\
    Each row is a fully self-contained record with a strict one-to-one mapping between attributes and columns.\
    All column types represent individual values unless the hint suggests otherwise.\
    \\n\\n`.replace(/ +/g, ' ') + JSON.stringify(gptMapping);

  return message;
}
