import { Link } from '@mui/material';
import { recursiveParse } from '@pn/core/utils/logic';
import { escapeMarkdown } from '@pn/services/markdown/utils';
import type { ChatMessage } from '@pn/ui/chat/useChatReducer';
import { Markdown } from '@pn/ui/custom-components/Markdown';
import { isString } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  code: {
    whiteSpace: 'break-spaces',
  },
}));

type Props = {
  message: ChatMessage;
};

export const MessageMarkdown = ({ message }: Props) => {
  const { classes } = useStyles();

  return (
    <Markdown
      markdown={toMarkdown(message)}
      options={{
        overrides: {
          a: {
            component: ({ children, href, title, className }) => (
              <Link href={href} title={title} className={className}>
                {children}
              </Link>
            ),
          },
          code: {
            component: 'code',
            props: {
              className: classes.code,
            },
          },
        },
      }}
    />
  );
};

function toMarkdown(message: ChatMessage): string {
  if (isString(message.content)) return message.content;

  let markdown = '<details>\n<summary>See technical details</summary>\n';

  markdown += `#### dataType\n${asCode(message.content.dataType)}\n`;

  markdown +=
    Object.entries(recursiveParse(message.content.preQueryAnalysis))
      .map(([key, value]) => {
        if (isString(value)) {
          return `#### ${key}\n${escapeMarkdown(value)}`;
        } else {
          return `#### ${key}\n${asCode(JSON.stringify(value, null, 2))}`;
        }
      })
      .join('\n') + '\n';

  markdown += `#### sql\n${asCode(message.content.sql)}\n`;

  // markdown += '[View workspace item](/workspace)';

  markdown += '</details>';

  markdown += '\n\n---\n\n';

  markdown += `\n${escapeMarkdown(message.content.postQueryAnalysis.explainSql)}`;

  return markdown;
}

function asCode(value: string): string {
  return `\`\`\`\n${value}\n\`\`\``;
}

/*
| Left aligned | Centered    | Right aligned  |
| :---         |    :----:   |           ---: |
| Header       | Title       | Here's this    |
| Paragraph    | Text        | And more       |
*/

/*
- First item
- Second item
- Third item
    - Indented item
    - Indented item
- Fourth item
*/

/*
- [x] Write the press release
- [ ] Update the website
- [ ] Contact the media
*/

/*
Try to put a blank line before...

---

...and after a horizontal rule.
*/

/* Inline code block `let x = 10;` */

/* <center>This text is centered.</center> */
/* <font color="red">This text is red!</font> */
/* <p style="color:blue">Make this text blue.</p> */

/* http://www.example.com */
/* ![alt text](https://picsum.photos/200) */

/**
 * NOT PROPERLY FORMATTED/HANDLED
 */

/* > Dorothy followed her through many of the beautiful rooms in her castle. */

/* 
> Dorothy followed her through many of the beautiful rooms in her castle.
>
> The Witch bade her clean the pots and kettles and sweep the floor and keep the fire fed with wood.
*/

/**
 * Also see:
 * @link https://www.markdownguide.org/hacks
 */
