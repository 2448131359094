import { createProject, type Project } from '@pn/core/domain/project';
import {
  projectsActions,
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { generateCopyName } from '@pn/core/utils/string';
import { apiProjectMapper } from '@pn/services/api/project/apiProjectMapper';
import assert from 'minimalistic-assert';
import React from 'react';
import { apiClient, notify } from 'src/application/externalDependencies';

export function useProjectActions() {
  const { user } = useCurrentUserStorage();

  const [inProgress, setInProgress] = React.useState(false);

  const duplicateProject = async (project: Project) => {
    assert(user, 'User must be defined to duplicate projects');

    setInProgress(true);

    const projectCopy = createProject(
      {
        ...project,
        name: generateCopyName(project.name),
        workspaceItemIds: project.workspaceItemIds,
      },
      user
    );

    // await wait(2000);
    const { id } = await apiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v2/projects',
      payload: apiProjectMapper.toTargetProjectPayload(projectCopy),
    });

    projectsActions().create({ ...projectCopy, id });
    projectsActions().select(id);

    workspaceActions().setWorkspace(projectCopy.workspaceItemIds); // prevents auto-updater from overriding the workspace

    notify('Project duplicated!');

    setInProgress(false);
  };

  const deleteProject = (project: Project) => {
    projectsActions().remove(project.id);
  };

  return {
    inProgress,
    duplicateProject,
    deleteProject,
  };
}
