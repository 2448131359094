import marker from '@pn/services/map/mapbox/assets/marker.png';
import { isNil } from 'lodash-es';

export function loadImages(map: mapboxgl.Map) {
  map.loadImage(marker, (error, image) => {
    if (error) {
      console.error('Error loading marker image', error);
    } else if (!isNil(image) && !map.hasImage('custom-marker')) {
      map.addImage('custom-marker', image, { sdf: true });
    }
  });
}
