import { formatDataType, type DataItemId } from '@pn/core/domain/data';
import { handleError } from '@pn/core/errors/handleError';
import { dataDocumentsActions, useCurrentUserStorage } from '@pn/core/storage';
import { apiDataInfoProvider } from '@pn/services/api/data-info/apiDataInfoProvider';
import { isNil } from 'lodash-es';
import React from 'react';

async function getStackCompanyDocuments(dataType: string, id: DataItemId) {
  const sliceName = `stack-company-${dataType}`;

  try {
    dataDocumentsActions(sliceName).request();

    const documents = await apiDataInfoProvider.getStackCompanyDocuments(
      dataType,
      id
    );

    dataDocumentsActions(sliceName).receive(documents);
  } catch (error) {
    handleError({
      error,
      onError: dataDocumentsActions(sliceName).error,
      userFriendlyMessage: `Failed to load Stack company ${formatDataType(
        dataType,
        {
          form: 'singular',
        }
      )} documents`,
    });
  }
}

export function useAutoGetStackCompanyDocuments(
  dataType: string,
  id?: DataItemId
) {
  const { isStackUser } = useCurrentUserStorage();

  React.useEffect(() => {
    if (!['wells', 'wells_usa'].includes(dataType) || !isStackUser || isNil(id))
      return;

    getStackCompanyDocuments(dataType, id);
  }, [isStackUser, dataType, id]);
}
