import type { DataItem } from '@pn/core/domain/data';
import { hashFeatureId } from '@pn/core/domain/map';
import { getCenterPoint } from '@pn/services/utils/geojson';
import { isNil } from 'lodash-es';

/**
 * Each source data can be transformed into a points collection. This is useful
 * for rendering polygon features at lower zoom levels.
 */
export function generateGeoJsonFeatureCollection(
  data: DataItem[],
  options?: {
    points?: boolean;
  }
): GeoJSON.FeatureCollection {
  const { points = false } = options ?? {};

  const geojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  for (const item of data) {
    if (!isNil(item.geometry)) {
      let featureGeometry: GeoJSON.Geometry;

      if (points) {
        featureGeometry = {
          type: 'Point',
          coordinates: getCenterPoint(item.geometry),
        };
      } else {
        featureGeometry = item.geometry;
      }

      geojson.features.push({
        type: 'Feature',
        id: hashFeatureId(item._id),
        geometry: featureGeometry,
        properties: item,
      });
    } else {
      console.error('Missing geometry from', item);
    }
  }

  return geojson;
}
