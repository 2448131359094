import { AUTO_VISUALIZE_LIMIT } from '@pn/core/limits';
import { dependencies } from '@pn/core/dependencies';
import { formatDataType, type DataItemId } from '@pn/core/domain/data';
import { getMapFeatureIds, type MapFeature } from '@pn/core/domain/map';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import {
  createWorkspaceItem,
  getColorComponents,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import {
  applyDynamicStyling,
  getDefaultBinCount,
  getDefaultGradientColorPalette,
  getDefaultRadiusRange,
  getDefaultStaggeredColors,
} from '@pn/core/operations/workspace/hooks/useDynamicStyling';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import assert from 'minimalistic-assert';
import { isArray, isEmpty, isNil, uniq } from 'lodash-es';
import React from 'react';

export function useMapSelectionProcessor() {
  const { workspaceItemSelected, workspaceItems } = useWorkspaceStorage();

  const sourceItemIdSelected = workspaceItemSelected?.dataType;
  const sourceItemIds = React.useMemo(
    () =>
      workspaceItems
        .filter((item) => isNil(item.sourceItem))
        .map(({ id }) => id),
    [workspaceItems]
  );

  const processSelection = useProcessSelection();

  /**
   * Builds a new selection under a new data key without affecting other queries.
   */
  const processMixedMapSelection = React.useCallback(
    (params: { features: MapFeature[]; append: boolean }): void => {
      const { features, append } = params;
      const { notificationService } = dependencies;

      const selectedMapFeatureIds = getMapFeatureIds(
        features.filter((f) => f.dataType === sourceItemIdSelected)
      );

      if (isNil(sourceItemIdSelected) || isEmpty(selectedMapFeatureIds)) {
        for (let i = sourceItemIds.length - 1; i >= 0; i--) {
          const sourceItemId = sourceItemIds[i];

          const mapFeatureIds = getMapFeatureIds(
            features.filter((f) => f.dataType === sourceItemId)
          );

          if (mapFeatureIds.length > AUTO_VISUALIZE_LIMIT) {
            return notificationService.notify(
              `You can only select up to ${AUTO_VISUALIZE_LIMIT.toLocaleString()} ${formatDataType(
                sourceItemId
              )}`,
              'warning'
            );
          }

          if (!isEmpty(mapFeatureIds)) {
            return processSelection({
              featureIds: mapFeatureIds,
              sourceItemId,
              append,
            });
          }
        }
      } else {
        if (selectedMapFeatureIds.length > AUTO_VISUALIZE_LIMIT) {
          return notificationService.notify(
            `You can only select up to ${AUTO_VISUALIZE_LIMIT.toLocaleString()} ${formatDataType(
              sourceItemIdSelected
            )}`,
            'warning'
          );
        }

        processSelection({
          featureIds: selectedMapFeatureIds,
          sourceItemId: sourceItemIdSelected,
          append,
        });
      }
    },
    [sourceItemIdSelected, sourceItemIds, processSelection]
  );

  return { processMixedMapSelection };
}

/**
 * If append mode is specified, the value can be true or false.
 * In exclusion mode, the value must be true.
 */
export type ProcessSelectionParams = {
  featureIds: DataItemId[];
  sourceItemId: string;
} & (
  | {
      append: boolean;
      exclude?: never;
    }
  | {
      append?: never;
      exclude: true;
    }
);

/**
 * Used for both map selection and map clicks in append mode.
 */
export function useProcessSelection() {
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems, workspaceItemSelected } = useWorkspaceStorage();

  return React.useCallback(
    (params: ProcessSelectionParams): void => {
      const { featureIds, sourceItemId, append, exclude } = params;

      const selectionItem: WorkspaceItem = (() => {
        const existingTemporaryItem = allWorkspaceItems.find(
          (item) => item.id === getSelectionItemId(sourceItemId)
        );

        if (
          !isNil(existingTemporaryItem) &&
          !isNil(existingTemporaryItem.query.requestedIds)
        ) {
          const requestedIds = exclude
            ? existingTemporaryItem.query.requestedIds.filter(
                (id: DataItemId) => !featureIds.includes(id)
              )
            : append
              ? uniq([
                  ...existingTemporaryItem.query.requestedIds,
                  ...featureIds,
                ])
              : featureIds;

          return {
            ...existingTemporaryItem,
            numberOfElements: requestedIds.length,
            query: {
              ...existingTemporaryItem.query,
              requestedIds,
            },
            isProcessed: false,
            isRendered: false,
          };
        }

        const sourceItem = allWorkspaceItems.find(
          (item) => item.id === sourceItemId
        );

        assert(user, 'User must be defined when processing selection');
        assert(sourceItem, 'Selection source item not found');

        // const normalizedSourceName = workspaceItemSelected?.name.replace(
        //   /^Selection of /,
        //   ''
        // );

        const newItem = createWorkspaceItem(
          {
            source: 'item',
            sourceItem,
            temporarySourceItemId: workspaceItemSelected?.id,
            id: getSelectionItemId(sourceItemId),
            // name: `Selection of ${normalizedSourceName ?? formatDataType(sourceItem.dataType)}`,
            extraStyle: {
              color: muiColorPalette.getMultiSelectionColor(),
            },
            queryOptions: {
              requestedIds: featureIds,
              multiSelectionReason: DataMultiSelectionReason.MultiSelection,
            },
          },
          user
        );

        return newItem;
      })();

      workspaceActions().add(selectionItem);
      workspaceActions().addToWorkspace(selectionItem.id);
      workspaceActions().select(selectionItem.id);
      workspaceActions().updatePage(selectionItem.id, 0);
      workspaceActions().unsetRequestedDataItemId(selectionItem.id);

      const layer = selectionItem.map.layers[0];
      const colorComponents = getColorComponents(layer.style.color);

      if (!isEmpty(colorComponents)) {
        applyDynamicStyling({
          item: selectionItem,
          field: colorComponents.field,
          staggeredColors: getDefaultStaggeredColors(),
          gradientColorPalette: getDefaultGradientColorPalette(
            colorComponents.type,
            colorComponents.legend
          ),
          desiredBinCount: getDefaultBinCount(
            colorComponents.type,
            colorComponents.legend
          ),
          radiusRange: getDefaultRadiusRange(layer.style.radius),
          isBubbleMapEnabled: isArray(layer.style.radius),
          wasBubbleMapEnabled: isArray(layer.style.radius),
          updateAll: true,
        });
      }
    },
    [user, allWorkspaceItems, workspaceItemSelected]
  );
}

export function getSelectionItemId(sourceItemId: string): string {
  return `mixed_selection_${sourceItemId}`;
}

export function isSelectionItemId(arg: string): boolean {
  return arg.startsWith('mixed_selection_');
}
