import { stackTokenManager } from '@pn/core/services/authentication/tokenManager';
import type { IApiClient } from '@pn/core/services/http/ports';
import { STACKDX_URL } from '@pn/core/urls';
import { makeRequest } from './apiClient';

export const stackApiClient: IApiClient = {
  request: (params) =>
    makeRequest({
      baseUrl: STACKDX_URL + '/',
      tokenManager: stackTokenManager,
      params,
    }),
  stream: () => {
    throw new Error('Not implemented');
  },
};
