import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { formatId } from '@pn/core/utils/format';
import { isNil } from 'lodash-es';
import type { QuickInfoPanelProps } from '.';

export const WellQuickInfo = (props: QuickInfoPanelProps) => {
  const { classes, dataItem, onClose } = props;

  if (isNil(dataItem)) return null;

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" className={classes.typography}>
              <span className={classes.fontWeight500}>
                {formatId(dataItem._id, 'wells')}
              </span>
            </Typography>
          </Box>
          <Box width="50%">
            <Typography
              variant="body2"
              align="right"
              className={classes.typography}
            >
              <span className={classes.fontWeight500}>Surface:</span>{' '}
              {dataItem.surface_location?.toString() ?? 'N/A'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" noWrap className={classes.typography}>
              {dataItem.licensee?.toString()}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography
              variant="body2"
              align="right"
              className={classes.typography}
            >
              <span className={classes.fontWeight500}>Status:</span>{' '}
              {dataItem.petro_ninja_well_status?.toString()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionWrapper}>
        <IconButton size="small" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
