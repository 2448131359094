import { dependencies } from '@pn/core/dependencies';
import { getExportableQuery } from '@pn/core/domain/query';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import { multiExportOptions } from '@pn/core/operations/export/multiExportOptions';
import { exportWorkspaceItem } from '@pn/core/operations/workspace';
import { pnTokenManager } from '@pn/core/services/authentication/tokenManager';
import { useAppStorage, useCurrentUserStorage } from '@pn/core/storage';
import env from '@pn/core/utils/env';
import { apiExportsProvider } from '@pn/services/api/table/apiExportsProvider';
import { downloadDataItems } from '@pn/services/exports/data/downloadDataItems';
import ActionCable from 'actioncable';
import { first, isNil } from 'lodash-es';
import React from 'react';

type ActionCableEvent = {
  type: string;
  id: string;
  url: string;
};

type Props = {
  item: WorkspaceItem;
  includeTableExport?: boolean;
};

export function useMultiExport({ item, includeTableExport = false }: Props) {
  const { notificationService } = dependencies;

  const { unitSystem } = useAppStorage();
  const { user } = useCurrentUserStorage();
  const { fields } = useTableFields(item.dataType);

  const validExportOptions = React.useMemo(
    () => [
      ...(includeTableExport
        ? [
            {
              dataType: item.dataType,
              name: 'Table Export',
              type: 'table_export',
              description: 'Classic export of all visible columns in the table',
              format: 'csv',
            },
          ]
        : []),
      ...multiExportOptions.filter((opt) => opt.dataType === item.dataType),
    ],
    [item.dataType, includeTableExport]
  );

  const [isExporting, setIsExporting] = React.useState(false);
  const [exportUnits, setExportUnits] = React.useState(unitSystem);
  const [selectedExportOption, setSelectedExportOption] = React.useState(
    first(validExportOptions)
  );

  React.useLayoutEffect(() => {
    if (!isNil(first(validExportOptions))) {
      setSelectedExportOption(first(validExportOptions));
    }
  }, [validExportOptions]);

  const handleMultiExport = async () => {
    if (isNil(selectedExportOption)) return;

    setIsExporting(true);

    if (selectedExportOption.type === 'table_export') {
      try {
        const { data, totalCount } = await exportWorkspaceItem({
          item,
          fields,
        });
        downloadDataItems({
          data,
          fields,
          mapping: item.mapping,
          unitSystem: exportUnits,
        }).csv({
          fileName:
            totalCount === 0
              ? 'custom layer export.csv'
              : data.length === totalCount
                ? `${item.dataType} export (${exportUnits}).csv`
                : `${item.dataType} partial ${(
                    (data.length / totalCount) *
                    100
                  ).toFixed(0)}% export (${exportUnits}).csv`,
        });
      } catch (error) {
        console.log(error);
        if (error instanceof Error) {
          notificationService.error(`Export failed: ${error.message}`);
        }
      } finally {
        setIsExporting(false);
      }

      return;
    }

    const notificationId = `${selectedExportOption.type}-${item.id}`;
    notificationService.render({
      content: `Generating ${selectedExportOption.name} export...`,
      options: {
        id: notificationId,
        showSpinner: true,
      },
    });

    try {
      const rawToken = await pnTokenManager.fetchToken(true);
      if (isNil(rawToken)) {
        notificationService.error('Export failed: missing auth token');
        return;
      }

      const cable = ActionCable.createConsumer(
        `${env.PN_WS_URL}/cable?token=${rawToken}`
      );

      const subscription: ActionCable.Channel = cable.subscriptions.create(
        { channel: 'ExportChannel', user_id: user?.id },
        {
          received: (event: ActionCableEvent) => handleEvent(event),
        }
      );

      const handleEvent = (event: ActionCableEvent) => {
        console.log('%cAction Cable event', 'color:#FF5722', event);
        if (event.type === 'export_processed') {
          notificationService.removeNotification(event.id);
          setIsExporting(false);

          subscription.unsubscribe();
          cable.disconnect();

          window.location.assign(event.url);
        } else if (event.type === 'export_error') {
          notificationService.removeNotification(event.id);
          notificationService.error('Export failed');
          setIsExporting(false);
        }
      };

      await apiExportsProvider.getMultiExport({
        exportId: notificationId,
        exportType: selectedExportOption.type,
        unitSystem: exportUnits,
        query: getExportableQuery(item.query, []),
        mapping: item.mapping,
      });
    } catch (error) {
      if (error instanceof Error) {
        handleError({
          error,
          userFriendlyMessage: 'Export error: ' + error.message,
        });
      }
      notificationService.removeNotification(notificationId);
      setIsExporting(false);
    }
  };

  return {
    validExportOptions,
    selectedExportOption,
    setSelectedExportOption,
    isExporting,
    exportUnits,
    setExportUnits,
    handleMultiExport,
  };
}
