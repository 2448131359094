import { setCoreDependencies } from '@pn/core/dependencies';
import { pnTokenManager } from '@pn/core/services/authentication/tokenManager';
import { appActions } from '@pn/core/storage';
import React from 'react';
import {
  apiClient,
  errorLogger,
  map,
  notificationService,
  useAuthenticationService,
} from 'src/application/externalDependencies';
import { store } from 'src/storage/store';

function useInitializeApp() {
  const { getAccessToken } = useAuthenticationService();

  return () => {
    setCoreDependencies({
      appName: 'maps',
      useAuthenticationService,
      apiClient,
      errorLogger,
      notificationService,
      map,
      store,
    });

    pnTokenManager.set(getAccessToken);

    appActions().initializeApp();
  };
}

export function useAutoInitializeApp(isAppInitialized: boolean) {
  const initializeApp = useInitializeApp();

  React.useEffect(() => {
    if (!isAppInitialized) {
      initializeApp();
    }
  }, [isAppInitialized, initializeApp]);
}
