import type { ITableTemplatesProvider } from '@pn/core/providers/table/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiTableTemplateMapper } from '@pn/services/api/table/apiTableTemplateMapper';
import type { ApiColumnsTemplate } from './types';

export const getTableTemplates = async () => {
  const apiTemplates = await pnApiClient.request<ApiColumnsTemplate[]>({
    url: 'v1/reports',
  });

  const templates = await Promise.all(
    apiTemplates.map(apiTableTemplateMapper.toDomainTemplate)
  );

  return templates;
};

export const apiTableTemplatesProvider: ITableTemplatesProvider = {
  getTableTemplates,
};
