import { LayerType, type Layer } from '@pn/core/domain/layer';
import { bottomGridsLayerId } from '@pn/services/map/mapbox/style/pnMapboxLayers';
import { generateGeoJsonFeatureCollection } from '@pn/services/utils/geojson';
import type { WorkspaceItem } from './workspaceItem';

export function getSourceId(layerId: Layer['id']): string {
  const [type, dataType, , key, extension] = layerId.split(':');

  if (type !== 'static') {
    return `${layerId}-source`;
  }

  const extensionSuffix = extension === 'points' ? ':points-source' : '-source';
  return `static:${dataType}:${key}${extensionSuffix}`;
}

export function isDataLayerId(layerId: Layer['id']): boolean {
  return (
    layerId.includes(':') &&
    !layerId.endsWith(':selected') &&
    !layerId.endsWith(':highlighted')
  );
}

export function getLayerId(
  dataType: WorkspaceItem['dataType'],
  itemId: WorkspaceItem['id'],
  key: string | number
): Layer['id'] {
  return `${dataType}:${itemId}:${key}`;
}

export function getPointsLayerId(runtimeLayerId: Layer['id']): Layer['id'] {
  return `${runtimeLayerId}:points`;
}

export function getSelectedLayerId(layerId: Layer['id']): Layer['id'] {
  return `${layerId}:selected`;
}

export function getHighlightedLayerId(layerId: Layer['id']): Layer['id'] {
  return `${layerId}:highlighted`;
}

export function decomposeLayerId(layerId: Layer['id']): {
  dataType: string;
  itemId: string;
  key: string | number;
} {
  const [dataType, itemId, key] = layerId.split(':');

  return { dataType, itemId, key };
}

export function getBeforeItemsLayerIds(beforeItems: WorkspaceItem[]): string[] {
  return beforeItems.reduce<string[]>((acc, beforeItem) => {
    acc.push(getBeforeItemLayerId(beforeItem));
    return acc;
  }, []);
}

function getBeforeItemLayerId(beforeItem: WorkspaceItem): string {
  switch (beforeItem.id) {
    case '_background':
      return '_reference'; // just above the Satellite layer
    case 'grids':
      return bottomGridsLayerId; // between _reference and vector grids
    default:
      break;
  }

  return beforeItem.map.layers[0].id;
}

export function generatePointsLayer(layer: Layer): Layer {
  return {
    id: getPointsLayerId(layer.id),
    type: LayerType.Circle,
    source: { type: 'geojson', data: generateGeoJsonFeatureCollection([]) },
    sourceLayer: undefined,
    style: {
      color: layer.style.color,
      strokeWidth: 1,
      strokeColor: '#000',
    },
  };
}
