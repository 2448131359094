import { alpha } from '@mui/material';
import { APPBAR_HEIGHT } from 'src/ui/layout/CustomAppBar';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      'nav-rail title'
      'nav-rail divider'
      'nav-rail content'
      'nav-rail actions'
      'nav-rail progress'
    `,
    gridTemplateColumns: 'min-content 1fr',
    gridTemplateRows: 'min-content min-content 1fr min-content min-content',
    position: 'absolute',
    left: 0,
    top: APPBAR_HEIGHT,
    height: `calc(100% - ${APPBAR_HEIGHT}px)`,
    border: 'none',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).workspace,
    [theme.breakpoints.up('md')]: {
      width: MAIN_TRAY_WIDTH + 1 + 481,
    },
    '@media print': {
      display: 'none',
    },
  },
  dialogPaper: {
    background: theme.palette.background.paper,
  },
  dialogTitleContainer: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    // NOTE do not set explicit height, it will break the wrapping
    display: 'inline-flex',
    alignItems: 'center', // centers the icon
    flex: 1, // wraps long titles
    paddingTop: 10, // lines up with Main Panel header
    paddingBottom: 10, // lines up with Main Panel header
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  editableTypography: {
    width: '100%',
    minHeight: 29,
    paddingTop: 3,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  editableTextField: {
    position: 'relative',
    top: 2,
  },
  itemName: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  dialogContent: {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
    },
  },
  dialogActions: {
    gridArea: 'actions',
    height: 52.5,
    paddingTop: theme.spacing(1.5),
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    [theme.breakpoints.up('md')]: {
      height: 40,
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1), // 4px reserved for the progress bar
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: 'transparent',
    },
  },
  divider: {
    gridArea: 'divider',
  },
  progressIndicator: {
    gridArea: 'progress',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    },
  },
  saveButton: {
    height: 40,
    width: 80,
  },
}));
