export const elevationContours = (mode: 'light' | 'dark') => [
  {
    minzoom: 11,
    layout: {
      visibility: 'none',
    },
    filter: ['match', ['get', 'index'], [-1], false, true],
    type: 'line',
    source: 'composite',
    id: 'elevation-contour',
    paint: {
      'line-color': mode === 'light' ? '#388E3C' : '#FFF',
      'line-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        11,
        ['match', ['get', 'index'], [1, 2], 0.15, 0.25],
        12,
        ['match', ['get', 'index'], [1, 2], 0.3, 0.5],
      ],
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        ['match', ['get', 'index'], [1, 2], 0.5, 0.6],
        16,
        ['match', ['get', 'index'], [1, 2], 1.2, 0.8],
      ],
      'line-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        ['match', ['get', 'index'], [1, 2], 1, 0.6],
        16,
        ['match', ['get', 'index'], [1, 2], 1.6, 1.2],
      ],
    },
    'source-layer': 'contour',
  },
  {
    minzoom: 11,
    layout: {
      'text-field': ['concat', ['get', 'ele'], ' m'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
      'text-size': ['interpolate', ['linear'], ['zoom'], 15, 9.5, 20, 12],
      'symbol-placement': 'line',
      'text-max-angle': 25,
      'text-padding': 5,
      visibility: 'none',
    },
    filter: ['any', ['==', ['get', 'index'], 10], ['==', ['get', 'index'], 5]],
    type: 'symbol',
    source: 'composite',
    id: 'elevation-contour-labels',
    paint: {
      'text-color': mode === 'light' ? '#388E3C' : '#FFF',
    },
    'source-layer': 'contour',
  },
];
