import { dependencies } from '@pn/core/dependencies';
import type { DataItemId } from '@pn/core/domain/data';
import type { Layer } from '@pn/core/domain/layer';
import { generatePointsLayer, getSourceId } from '@pn/core/domain/workspace';
import type { IMap } from '@pn/core/services/map/ports';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import React from 'react';

export function useLayerHighlighting(tableOnly = false, hideCrosshair = false) {
  const { workspaceItems } = useWorkspaceStorage();

  const map = dependencies.map as IMap<mapboxgl.Map>;

  const shouldSkip = tableOnly || hideCrosshair;

  React.useEffect(() => {
    if (shouldSkip) return;

    const mouseMoveHandlers: Record<
      Layer['id'],
      (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void
    > = {};
    const mouseLeaveHandlers: Record<Layer['id'], () => void> = {};

    const highlighted: Record<Layer['id'], DataItemId> = {};
    const sourceLayers: Record<Layer['id'], Layer['sourceLayer']> = {};

    for (const item of workspaceItems) {
      if (item.id === '_background' || item.id === 'grids') {
        continue;
      }

      const layers = item.map.layers.flatMap((layer) => {
        if (layer.renderAsPoints) {
          return [layer, generatePointsLayer(layer)];
        } else {
          return [layer];
        }
      });

      for (const layer of layers) {
        sourceLayers[layer.id] = layer.sourceLayer;

        mouseMoveHandlers[layer.id] = (e) => {
          if (!['dispositions', 'dispositions_historical'].includes(item.id)) {
            return;
          }

          const featureId: DataItemId = e.features[0].id;

          if (highlighted[layer.id] === featureId) return;

          if (!isNil(highlighted[layer.id])) {
            map.unsetFeatureState({
              sourceId: getSourceId(layer.id),
              sourceLayer: layer.sourceLayer,
              featureId: highlighted[layer.id],
              featureStateFields: ['hover'],
            });
          }

          highlighted[layer.id] = featureId;

          map.setFeatureState({
            sourceId: getSourceId(layer.id),
            sourceLayer: layer.sourceLayer,
            featureId,
            featureState: {
              hover: true,
            },
          });
        };

        map._native.on('mousemove', layer.id, mouseMoveHandlers[layer.id]);

        mouseLeaveHandlers[layer.id] = () => {
          if (isNil(highlighted[layer.id])) return;

          map.unsetFeatureState({
            sourceId: getSourceId(layer.id),
            sourceLayer: layer.sourceLayer,
            featureId: highlighted[layer.id],
            featureStateFields: ['hover'],
          });

          delete highlighted[layer.id];
        };

        map._native.on('mouseleave', layer.id, mouseLeaveHandlers[layer.id]);
      }
    }

    return () => {
      for (const layerId in mouseMoveHandlers) {
        map._native.off('mousemove', layerId, mouseMoveHandlers[layerId]);
      }
      for (const layerId in mouseLeaveHandlers) {
        map._native.off('mouseleave', layerId, mouseLeaveHandlers[layerId]);
      }

      for (const [layerId, featureId] of Object.entries(highlighted)) {
        map.unsetFeatureState({
          sourceId: getSourceId(layerId),
          sourceLayer: sourceLayers[layerId],
          featureId,
          featureStateFields: ['hover'],
        });
      }
    };
  }, [map, shouldSkip, workspaceItems]);
}
