import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isArray, isObject, isString } from 'lodash-es';

export * from './bbox';
export * from './canvas';
export * from './create';
export * from './data';
export * from './spatial';
export * from './wkt';

/**
 * Use when a narrowed type is required.
 */
export function to2DPosition(position: GeoJSON.Position): [number, number] {
  return [position[0], position[1]];
}

export function isGeoJSONGeometry(arg: unknown): arg is GeoJSON.Geometry {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'type', isString) &&
    [
      'Point',
      'MultiPoint',
      'LineString',
      'MultiLineString',
      'Polygon',
      'MultiPolygon',
      'GeometryCollection',
    ].includes(arg.type) &&
    hasKeyWithType(arg, 'coordinates', isArray)
  );
}

export function isGeoJSONFeatureCollection(
  arg: unknown
): arg is GeoJSON.FeatureCollection {
  return (
    isObject(arg) &&
    hasKey(arg, 'type') &&
    arg.type === 'FeatureCollection' &&
    hasKeyWithType(arg, 'features', isArray)
  );
}
