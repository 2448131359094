import { isPNDataType } from '@pn/core/domain/data';

export function urlToTemplate(url: string): string {
  let result = '';

  /* Remove query string */
  result = url.replace(/\?.*$/, '');

  /* Replace path parameters with placeholders */
  const chunks = result.split('/');
  result = chunks.reduce((acc, chunk) => {
    if (isPNDataType(chunk)) {
      return acc + '/:data_type';
    } else if (isLikelyId(chunk)) {
      return acc + '/:param';
    }

    return acc + '/' + chunk;
  }, '');

  return result.startsWith('/') ? result.slice(1) : result;
}

function isLikelyId(candidate: string): boolean {
  /* ❌ Ignore "v1", "v2", ..., "v99" */
  if (/^v\d{1,2}$/.test(candidate)) return false;

  if (candidate.includes('|')) return true;

  const containsNumbers = /\d/.test(candidate);

  /* ✅ If it contains a number AND has no lowercase letters, it's an ID */
  if (containsNumbers && !/[a-z]/.test(candidate)) return true;

  /* ✅ Otherwise, check if it's 6-7 characters long and has mixed casing/contains numbers */
  const isCorrectLength = candidate.length >= 6 && candidate.length <= 7;
  const isWeirdlyCased =
    candidate !== candidate.toLowerCase() &&
    candidate !== candidate.toUpperCase();

  return isCorrectLength && (isWeirdlyCased || containsNumbers);
}
