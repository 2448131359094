import CancelIcon from '@mui/icons-material/Cancel';
import PaletteIcon from '@mui/icons-material/Palette';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { Box, Fab, Tooltip } from '@mui/material';
import { createDrawing } from '@pn/core/operations/workspace/crud/createDrawing';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isFreeCordova } from '@pn/core/utils/env';
import { useDrawing } from '@pn/services/drawing';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { CircleSelectIcon, PolygonSelectIcon } from '@pn/ui/icons';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'minimalistic-assert';
import { BufferZonesButton } from 'src/ui/map/components/map-buttons/BufferZonesButton';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    '@media print': {
      display: 'none',
    },
  },
}));

export const MapButtons = () => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();
  const access = useAccess();
  const isAccessDenied = access('annotations').denied();

  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const { drawingMode, setDrawingMode, redraw, reset } = useDrawing();
  const { closeLibrary } = useLibrary();
  const { isDrawingPanelOpen, openWorkspaceItemPanel } =
    useWorkspaceItemPanel();

  const isCircleToolActive =
    !isDrawingPanelOpen && drawingMode === 'circle_select';
  const isPolygonToolActive =
    !isDrawingPanelOpen && drawingMode === 'polygon_select';

  const handleCreateDrawing = () => {
    if (access('drawings').notify().denied()) return;
    assert(user, 'User must be defined to create a drawing');

    const newItem = createDrawing(allWorkspaceItems, user);
    openWorkspaceItemPanel({ item: newItem });
    closeLibrary();
  };

  const handleMeasureDistance = () => {
    if (drawingMode === 'distance') {
      setDrawingMode('select');
      reset('');
      redraw();
    } else {
      setDrawingMode('distance');
    }
  };

  if (smScreen || isEmbedded() || isFreeCordova(user)) return null;

  return (
    <Box className={classes.container}>
      {/* Annotations editor */}
      <Tooltip title="Draw" placement="left">
        <Box component="span">
          <Fab
            aria-label="annotate the map"
            size="small"
            color="default"
            disabled={isDrawingPanelOpen}
            onClick={handleCreateDrawing}
          >
            <PaletteIcon />
          </Fab>
        </Box>
      </Tooltip>

      {/* Circle selection */}
      <Tooltip
        title={isCircleToolActive ? 'Cancel' : 'Select (circle)'}
        placement="left"
      >
        <Box component="span">
          <Fab
            aria-label="circle selection"
            size="small"
            color={isCircleToolActive ? 'secondary' : 'default'}
            disabled={isAccessDenied}
            onClick={() =>
              isCircleToolActive
                ? setDrawingMode('select')
                : setDrawingMode('circle_select')
            }
          >
            {isCircleToolActive ? <CancelIcon /> : <CircleSelectIcon />}
          </Fab>
        </Box>
      </Tooltip>

      {/* Polygon selection */}
      <Tooltip
        title={isPolygonToolActive ? 'Cancel' : 'Select (polygon)'}
        placement="left"
      >
        <Box component="span">
          <Fab
            aria-label="polygon selection"
            size="small"
            color={isPolygonToolActive ? 'secondary' : 'default'}
            disabled={isAccessDenied}
            onClick={() =>
              isPolygonToolActive
                ? setDrawingMode('select')
                : setDrawingMode('polygon_select')
            }
          >
            {isPolygonToolActive ? <CancelIcon /> : <PolygonSelectIcon />}
          </Fab>
        </Box>
      </Tooltip>

      {/* Distance measurement */}
      <Tooltip title="Distance" placement="left">
        <Box component="span">
          <Fab
            aria-label="measure distance"
            size="small"
            color={drawingMode === 'distance' ? 'secondary' : 'default'}
            onClick={handleMeasureDistance}
          >
            <SquareFootIcon />
          </Fab>
        </Box>
      </Tooltip>

      {/* Buffer zones */}
      <BufferZonesButton />
    </Box>
  );
};
