import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { isFreeCordova } from '@pn/core/utils/env';
import {
  Details,
  Documents,
  LeaseProduction,
  Production,
} from 'src/ui/main-panel/panels';
import type { MainPanelRoute } from 'src/ui/main-panel/routes';

export const wellsRoutes: MainPanelRoute[] = [
  {
    path: '/wells/:id/details',
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    getComponent: ({ item }) => <Details item={item} />,
  },
  {
    path: '/wells/:id/production',
    title: 'Production',
    Icon: WaterDropOutlinedIcon,
    IconSelected: WaterDropIcon,
    getComponent: ({ item }) => <Production item={item} />,
  },
  {
    path: '/wells/:id/documents',
    title: 'Documents',
    Icon: InsertDriveFileOutlinedIcon,
    IconSelected: InsertDriveFileIcon,
    getComponent: ({ item }) => <Documents item={item} />,
    shouldShow: ({ user }) => !isFreeCordova(user),
  },
];

export const wellsUsaRoutes: MainPanelRoute[] = [
  {
    path: '/wells_usa/:id/details',
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    getComponent: ({ item }) => <Details item={item} />,
  },
  {
    path: '/wells_usa/:id/production',
    title: 'Production',
    Icon: WaterDropOutlinedIcon,
    IconSelected: WaterDropIcon,
    getComponent: ({ item }) => <Production item={item} />,
    shouldShow: ({ item }) => !shouldShowLeaseProduction(item),
  },
  {
    path: '/wells_usa/:id/lease_production',
    title: 'Lease Production',
    Icon: OilBarrelOutlinedIcon,
    IconSelected: OilBarrelIcon,
    getComponent: ({ item }) => <LeaseProduction item={item} />,
    shouldShow: ({ item }) => shouldShowLeaseProduction(item),
  },
  {
    path: '/wells_usa/:id/documents',
    title: 'Documents',
    Icon: InsertDriveFileOutlinedIcon,
    IconSelected: InsertDriveFileIcon,
    getComponent: ({ item }) => <Documents item={item} />,
    shouldShow: ({ user }) => !isFreeCordova(user),
  },
];

const statesWithLeaseProduction = [15, 42]; // Kansas, Texas

function shouldShowLeaseProduction(item: WorkspaceItem): boolean {
  return statesWithLeaseProduction.some((state) =>
    item.requestedDataItem.id?.toString().startsWith(state.toString())
  );
}
