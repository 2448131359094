import { isString } from 'lodash-es';

export type DateString = string;
export function isDateString(arg: unknown): arg is DateString {
  return isString(arg) && /^\d{4}-\d{2}-\d{2}$/.test(arg);
}
export type DateTimeString = string;
export type ISODateTimeString = string;

export enum UnitSystem {
  Metric = 'metric',
  Imperial = 'imperial',
}

export type WKTString = string;
export function isWKTString(arg: unknown): arg is WKTString {
  if (!isString(arg)) return false;

  return (
    arg.startsWith('POINT') ||
    arg.startsWith('LINESTRING') ||
    arg.startsWith('POLYGON') ||
    arg.startsWith('MULTIPOINT') ||
    arg.startsWith('MULTILINESTRING') ||
    arg.startsWith('MULTIPOLYGON')
  );
}
