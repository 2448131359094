import { to2DPosition } from '@pn/services/utils/geojson';
import type { MapboxIMap } from '..';

export function moveTo(
  this: MapboxIMap,
  position: GeoJSON.Position,
  zoom?: number
) {
  this._native.jumpTo({
    center: to2DPosition(position),
    zoom: zoom ?? this._native.getZoom(),
  });
}
