import { formatDataType } from '@pn/core/domain/data';
import {
  DataMultiSelectionReason,
  DataSelectionReason,
} from '@pn/core/domain/query';
import {
  createWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import { handleSearch } from '@pn/core/operations/search';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import {
  mapActions,
  useAppStorage,
  useMapStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { defaultWorkspace } from '@pn/core/storage/workspace/defaultWorkspaces';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { log } from '@pn/core/utils/embedded';
import { findOrThrow, removeNilFields } from '@pn/core/utils/logic';
import { getBBox } from '@pn/services/utils/geojson';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import type {
  PnEmbeddedIncomingMessage,
  PnEmbeddedOutgoingMessage,
} from './types';
import whitelist from './whitelist.json';

export function useEmbedded() {
  const { pageSize } = useAppStorage();
  const { isInitialized: isMapInitialized } = useMapStorage();
  const { workspaceItemSelected, dataItemSelected } = useWorkspaceStorage();

  const isStreaming =
    workspaceItemSelected?.isProcessed && !workspaceItemSelected?.isRendered;
  const isRendered = workspaceItemSelected?.isRendered;

  const mapAutoMoveRequired = React.useRef(false);

  React.useEffect(() => {
    if (isMapInitialized) {
      log.debug('map is initialized');
      postMessageToParent({ action: 'MAP_HAS_LOADED' });
    }
  }, [isMapInitialized]);

  React.useEffect(() => {
    if (isStreaming) {
      postMessageToParent({ action: 'STREAMING_STARTED' });
    } else if (isRendered) {
      postMessageToParent({ action: 'STREAMING_FINISHED' });
    }
  }, [isStreaming, isRendered]);

  React.useEffect(() => {
    const processMessage = (
      message: MessageEvent<PnEmbeddedIncomingMessage>
    ) => {
      const { origin, data } = message;

      if (!whitelist.includes(origin)) {
        log.debug('ignoring message from non-whitelisted origin', origin);
        return;
      }

      log.debug('IncomingMessage received', message);

      switch (data.action) {
        case 'MOVE_TO': {
          const { lat, lon, zoom } = data.payload;

          map.setZoom(zoom);
          map.moveTo([lon, lat]);

          log.debug(`centered map on ${lat}, ${lon} at z=${zoom}`);
          break;
        }
        case 'SET_MAP_MODE': {
          const { mode } = data.payload;

          mapActions().updateMode(mode);

          log.debug(`updated map mode to ${mode}`);
          break;
        }
        case 'SEARCH': {
          handleSearch(data.payload.searchQuery);

          log.debug(`searched ${data.payload.searchQuery}`);
          break;
        }
        case 'SELECT_FEATURE_BY_ID': {
          const { id, dataType, autoMoveMap } = data.payload;

          workspaceActions().addToWorkspace(dataType);
          workspaceActions().select(dataType);

          const workspaceItem = findOrThrow(
            pnWorkspaceItems,
            ({ id }) => id === dataType
          );

          selectDataItem({
            id,
            reason: DataSelectionReason.Embedded,
            workspaceItem,
            pageSize,
          });

          if (autoMoveMap) {
            mapAutoMoveRequired.current = true;
          }

          log.debug(
            `selected 1 ${formatDataType(dataType, { form: 'singular' })}`
          );
          break;
        }
        case 'SELECT_FEATURES_BY_IDS': {
          const {
            ids,
            dataType,
            color = undefined,
            size = undefined,
            width = undefined,
            autoMoveMap,
          } = data.payload;

          const sourceItem = findOrThrow(
            pnWorkspaceItems,
            ({ id }) => id === dataType
          );

          const newItem = createWorkspaceItem(
            {
              source: 'item',
              sourceItem: sourceItem,
              temporarySourceItemId: sourceItem.id,
              extraStyle: removeNilFields({
                color,
                size,
                width,
              }),
              queryOptions: {
                requestedIds: ids,
                multiSelectionReason: DataMultiSelectionReason.List,
              },
            },
            undefined
          );

          workspaceActions().add(newItem);
          workspaceActions().addToWorkspace(newItem.id);
          workspaceActions().select(newItem.id);

          if (autoMoveMap) {
            mapAutoMoveRequired.current = true;
          }

          log.debug(`selected ${ids.length.toLocaleString()} ${dataType}`);

          break;
        }
        case 'SELECT_SINGLE_FEATURE': {
          const {
            id,
            sourceLayerId,
            color = undefined,
            size = undefined,
            width = undefined,
            autoMoveMap,
          } = data.payload;

          const sourceItem = findOrThrow(
            pnWorkspaceItems,
            ({ id }) => id === sourceLayerId
          );

          const newItem = createWorkspaceItem(
            {
              source: 'item',
              sourceItem: sourceItem,
              temporarySourceItemId: sourceItem.id,
              extraStyle: removeNilFields({
                color,
                size,
                width,
              }),
              queryOptions: {
                requestedIds: [id],
                multiSelectionReason: DataMultiSelectionReason.List,
              },
            },
            undefined
          );

          workspaceActions().add(newItem);
          workspaceActions().addToWorkspace(newItem.id);
          workspaceActions().select(newItem.id);

          selectDataItem({
            id,
            reason: DataSelectionReason.Embedded,
            workspaceItem: newItem,
            pageSize,
          });

          if (autoMoveMap) {
            mapAutoMoveRequired.current = true;
          }

          log.debug(`selected ${id} from ${sourceLayerId}`);
          break;
        }
        case 'VISUALIZE_LISTS': {
          const { lists, autoMoveMap } = data.payload;

          const newItems: WorkspaceItem[] = [];

          lists.forEach(({ ids, dataType, color, size, width }: any) => {
            const sourceItem = findOrThrow(
              pnWorkspaceItems,
              ({ id }) => id === dataType
            );

            newItems.push(
              createWorkspaceItem(
                {
                  source: 'item',
                  sourceItem: sourceItem,
                  temporarySourceItemId: sourceItem.id,
                  extraStyle: removeNilFields({
                    color,
                    size,
                    width,
                  }),
                  queryOptions: {
                    requestedIds: ids,
                    multiSelectionReason: DataMultiSelectionReason.List,
                  },
                },
                undefined
              )
            );
          });

          newItems.forEach((item, index) => {
            workspaceActions().add(item);
            workspaceActions().addToWorkspace(item.id);
            if (index === 0) workspaceActions().select(item.id);
          });

          if (autoMoveMap) {
            mapAutoMoveRequired.current = true;
          }

          log.debug(`visualized ${lists.length.toLocaleString()} lists`);

          break;
        }
        case 'SHOW_LAYER': {
          const { id } = data.payload;

          workspaceActions().addToWorkspace(id);

          log.debug(`showed layer ${id}`);
          break;
        }
        case 'HIDE_LAYER': {
          const { id } = data.payload;

          workspaceActions().removeFromWorkspace(id);

          log.debug(`hidden layer ${id}`);
          break;
        }
        case 'CLEAR_ALL': {
          workspaceActions().select(undefined);
          workspaceActions().setWorkspace(defaultWorkspace);
          workspaceActions().resetInternalState();

          break;
        }
      }
    };

    window.addEventListener('message', processMessage);
    log.debug('ready to receive messages');

    return () => {
      window.removeEventListener('message', processMessage);
      log.debug('message listener removed');
    };
  }, [pageSize]);

  React.useEffect(() => {
    if (isNil(dataItemSelected)) return;

    postMessageToParent({
      action: 'FEATURE_SELECTED',
      payload: {
        dataType: workspaceItemSelected?.dataType,
        feature: dataItemSelected,
      },
    });
  }, [dataItemSelected, workspaceItemSelected?.dataType]);

  React.useEffect(() => {
    if (isNil(workspaceItemSelected)) return;

    const mapDataItems = getMapDataItems(workspaceItemSelected);
    if (
      mapAutoMoveRequired.current &&
      workspaceItemSelected?.isRendered &&
      !isEmpty(mapDataItems)
    ) {
      mapAutoMoveRequired.current = false;

      const geometries = mapDataItems
        .map((item) => item.geometry)
        .filter((g) => !isNil(g));
      const bbox = getBBox(geometries);
      map.fitToBBox(bbox, {
        padding: 50,
        maxZoom: 11.75,
      });

      log.debug(
        `automatically centered map to encompass ${mapDataItems.length} result${
          mapDataItems.length === 1 ? '' : 's'
        }`
      );
    }
  }, [workspaceItemSelected]);
}

function postMessageToParent(message: PnEmbeddedOutgoingMessage): void {
  log.debug('posting message to parent', message);
  window.parent.postMessage(message, '*');
}
