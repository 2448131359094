import { companiesSlice } from '@pn/core/storage/company/companiesSlice';
import { apiCompanyMapper } from '@pn/services/api/company/apiCompanyMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import type { ReducerSync } from '.';

export const companies: ReducerSync<typeof companiesSlice.actions> = {
  update: ({ companyId, company }) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v1/companies/${companyId}`,
      payload: apiCompanyMapper.toTargetCompany(company),
    });
  },
};
