import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import { Box, Button } from '@mui/material';
import { useRouter } from '@pn/core/operations/router';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isFreeCordova } from '@pn/core/utils/env';
import { useFreshdesk } from '@pn/services/support/useFreshdesk';
import { DataTable } from '@pn/ui/data-table';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import assert from 'minimalistic-assert';
import React from 'react';
import { ShortcutsDialog } from 'src/ui/components/ShortcutsDialog';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

export const BOTTOM_TABLE_HEIGHT = isEmbedded() ? 358 - 40 : 358; // (5 rows OR 4 rows + header filters) + scrollbar

const useStyles = makeStyles()((theme) => ({
  bottomTableContainer: {
    gridArea: 'bottom-table',
    position: 'relative',
    height: BOTTOM_TABLE_HEIGHT,
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    overflowX: 'auto',
    zIndex: zIndex(theme).bottomTable,
    [`@media (max-height: 640px)`]: {
      height: 243, // single row with no scrollbar (two rows if header filters are disabled)
    },
    '@media print': {
      display: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textTransform: 'none',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  isMainTrayOpen?: boolean;
};

export const BottomTable = React.memo(function BottomTable({
  isMainTrayOpen = false,
}: Props) {
  const { query } = useRouter();

  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const { user } = useCurrentUserStorage();
  const { handleOpenHelp } = useFreshdesk('petro-ninja');

  const [isShortcutsDialogOpen, setShortcutsDialogOpen] = React.useState(false);

  if (isEmbedded() && query.bottomTable !== '1') return null;
  if (isFreeCordova(user) || smScreen) return null;

  return (
    <Box
      id="bottom-table-container"
      className={classes.bottomTableContainer}
      sx={{ marginLeft: isMainTrayOpen ? MAIN_TRAY_WIDTH + 'px' : 0 }}
    >
      <DataTable
        enableRowClicks
        toolbarProps={{
          show: !isEmbedded(),
          showColumnsSelector: true,
          showStyleButton: true,
        }}
      />

      {!isEmbedded() && (
        <Box className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            color="inherit"
            variant="contained"
            onClick={handleOpenHelp}
            endIcon={<HelpOutlineIcon className={classes.icon} />}
          >
            Help
          </Button>
          <Button
            className={classes.button}
            color="inherit"
            variant="contained"
            onClick={() => setShortcutsDialogOpen(true)}
            endIcon={<KeyboardCommandKeyIcon className={classes.icon} />}
          >
            Shortcuts
          </Button>

          <ShortcutsDialog
            open={isShortcutsDialogOpen}
            onClose={() => setShortcutsDialogOpen(false)}
          />
        </Box>
      )}
    </Box>
  );
});

export function getBottomTableContainerEl(): HTMLDivElement {
  const el = document.getElementById('bottom-table-container');
  assert(el, 'bottom-table-container is not found');

  return el as HTMLDivElement;
}
