export function isFormData(arg: unknown): arg is globalThis.FormData {
  return typeof FormData !== 'undefined' && arg instanceof globalThis.FormData;
}

export function getResponseType(contentType: string) {
  if (contentType.includes('application/json')) return 'json';
  if (
    contentType.includes('application/pdf') ||
    contentType.includes('image/') ||
    contentType.includes('video/') ||
    contentType.includes('audio/')
  )
    return 'blob';
  if (contentType.includes('application/octet-stream')) return 'arrayBuffer';
  if (contentType.includes('multipart/form-data')) return 'formData';
  if (contentType.includes('text/')) return 'text';

  console.warn(`Unhandled content-type: ${contentType}`);
  return 'text';
}
