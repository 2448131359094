type Output = {
  metric: string;
  imperial: string;
};

export function formatDistance(meters: number): Output {
  const metric =
    meters < 1000
      ? `${formatNumber(meters)} m`
      : `${formatNumber(meters / 1000)} km`;
  const imperial =
    meters < 1609.34
      ? `${formatNumber(meters * 3.28084)} ft`
      : `${formatNumber((meters * 3.28084) / 5280)} mi`;
  return { metric, imperial };
}

export function formatArea(sqMeters: number): Output {
  const metric =
    sqMeters < 1e6
      ? `${formatNumber(sqMeters)} m²`
      : `${formatNumber(sqMeters / 1e6)} km²`;
  const imperial = `${formatNumber(sqMeters / 10e3)} ha`;
  return { metric, imperial };
}

function formatNumber(value: number): string {
  const digits = value < 10 ? 2 : value < 100 ? 1 : 0;
  return value.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}
