import type { TreeViewBaseItem } from '@mui/x-tree-view';
import type { Document } from '@pn/core/domain/data-info';
import { isCloseMatch } from '@pn/services/utils/string';
import { isNil } from 'lodash-es';

export type CustomTreeItem = {
  id: string;
  label: string;
  secondaryLabel?: string;
  openUrl?: string;
  downloadUrl?: string;
};

export function buildTree(
  documents: Document[],
  groupBy: keyof Document,
  searchValue: string
): TreeViewBaseItem<CustomTreeItem>[] {
  const tree: TreeViewBaseItem<CustomTreeItem>[] = [];
  const folderMap: Record<string, TreeViewBaseItem<CustomTreeItem>> = {};

  const parseSlashes = groupBy === 'folder';

  const filteredDocuments = documents.filter(
    (doc) =>
      isCloseMatch(doc.name, searchValue) ||
      (!isNil(doc.title) && isCloseMatch(doc.title, searchValue))
  );

  filteredDocuments.forEach((doc) => {
    let currentLevel = tree;

    if (doc[groupBy]) {
      const parts = parseSlashes
        ? doc[groupBy].replace(/\/$/, '').split('/') // Remove trailing slash and split the folder path into parts
        : [doc[groupBy]];
      let path = '';

      parts.forEach((part) => {
        path = path ? `${path}/${part}` : part;
        if (!folderMap[path]) {
          const newFolder: TreeViewBaseItem<CustomTreeItem> = {
            id: path,
            label: part,
            children: [],
          };
          folderMap[path] = newFolder;

          // If it's a top-level folder, add it directly to the tree
          if (!parseSlashes || !path.includes('/')) {
            currentLevel.push(newFolder);
          } else {
            // Otherwise, add it to its parent's children array
            const parentPath = path.substring(0, path.lastIndexOf('/'));
            folderMap[parentPath].children!.push(newFolder);
          }
        }
        // Move to the next level in the folder structure
        currentLevel = folderMap[path].children!;
      });
    }

    // Add the document as a leaf node
    currentLevel.push({
      id: doc.id,
      label: doc.name,
      secondaryLabel: doc.title,
      openUrl: doc.url,
      downloadUrl: doc.downloadUrl,
    });
  });

  // Sort the tree items recursively
  return sortTreeItems(tree);
}

function sortTreeItems(
  items: TreeViewBaseItem<CustomTreeItem>[]
): TreeViewBaseItem<CustomTreeItem>[] {
  items.sort((a, b) => {
    // Determine if the item is a folder (has children) or a file
    const aIsFolder = a.children !== undefined;
    const bIsFolder = b.children !== undefined;

    // Folders should come before files
    if (aIsFolder && !bIsFolder) return -1;
    if (!aIsFolder && bIsFolder) return 1;

    // If both are folders or both are files, sort alphabetically by label
    return a.label.localeCompare(b.label);
  });

  // Recursively sort children if any
  items.forEach((item) => {
    if (item.children) {
      sortTreeItems(item.children);
    }
  });

  return items;
}
