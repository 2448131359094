import type { GridColDef } from '@mui/x-data-grid-pro';
import type { MappingItem } from '@pn/core/domain/data';
import type { DateString, UnitSystem } from '@pn/core/domain/types';
import {
  convertSymbol,
  convertUnit,
  prettyPrintUnitSymbol,
  type SIUnit,
  unitToString,
} from '@pn/core/domain/units';
import { useAppStorage } from '@pn/core/storage';
import {
  defaultBooleanOperators,
  defaultDateOperators,
  defaultDropdownOperators,
  defaultNumericOperators,
  defaultStringOperators,
} from '@pn/ui/data-table/hooks/operators';
import {
  renderBoolean,
  renderCellWithNA,
} from '@pn/ui/data-table/hooks/renderCellMethods';
import type { ColumnAttributesSetter } from '@pn/ui/data-table/hooks/useColumns';
import { isNil } from 'lodash-es';
import React from 'react';

export function useDefaultColumnAttributes(): ColumnAttributesSetter {
  const { unitSystem } = useAppStorage();

  return React.useCallback(
    (params) => {
      const { column, mappingItem } = params;

      column.headerName = getDefaultHeaderName(mappingItem, unitSystem);
      column.filterOperators = getDefaultFilterOperators(column.type);
      column.valueFormatter = getDefaultValueFormatter(mappingItem, unitSystem);
      column.renderCell = getDefaultRenderCell(mappingItem.domainType);
    },
    [unitSystem]
  );
}

/**
 * Append newline before [StackDX] if present.
 * If the mapping item supports unit conversions, add its symbol.
 */
export function getDefaultHeaderName(
  mappingItem: MappingItem,
  unitSystem: UnitSystem
): GridColDef['headerName'] {
  let headerName = mappingItem.ui.label;

  if (headerName.includes('[StackDX]')) {
    headerName = headerName.replace('[StackDX]', '\n[StackDX]');
  }

  if (mappingItem.domainType === 'SIUnit') {
    headerName +=
      (headerName.includes('\n') ? ' ' : '\n') +
      `(${prettyPrintUnitSymbol(
        convertSymbol(mappingItem.domainTypeAttributes.symbol, unitSystem)
      )})`;
  }

  return headerName.trim();
}

export function getDefaultFilterOperators(
  columnType: GridColDef['type']
): GridColDef['filterOperators'] {
  switch (columnType) {
    case 'string':
      return defaultStringOperators;
    case 'singleSelect':
      return defaultDropdownOperators;
    case 'number':
      return defaultNumericOperators;
    case 'date':
      return defaultDateOperators;
    case 'boolean':
      return defaultBooleanOperators;
    default:
      return undefined;
  }
}

export function getDefaultValueFormatter(
  mappingItem: MappingItem,
  unitSystem: UnitSystem
): GridColDef['valueFormatter'] {
  switch (mappingItem.domainType) {
    case 'number':
      return (value: number | undefined) => {
        if (isNil(value)) return undefined;
        return value.toLocaleString(undefined, {
          maximumFractionDigits: 5,
        });
      };
    case 'DateString':
      return (value: DateString | undefined) => {
        if (isNil(value)) return undefined;
        return value;
      };
    case 'Geometry':
      throw new Error('Geometry columns should not be rendered');
    case 'SIUnit':
      return (value: SIUnit | undefined) => {
        if (isNil(value)) return undefined;
        return unitToString(convertUnit(value, unitSystem), {
          fractionDigits: mappingItem.domainTypeAttributes.fractionDigits,
        });
      };
    default:
      return undefined;
  }
}

export function getDefaultRenderCell(
  domainType: MappingItem['domainType']
): GridColDef['renderCell'] {
  switch (domainType) {
    case 'boolean':
      return renderBoolean;
    default:
      return renderCellWithNA;
  }
}
