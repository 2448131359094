import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Details } from 'src/ui/main-panel/panels';
import type { MainPanelRoute } from 'src/ui/main-panel/routes';

export const mineralRightsRoutes: MainPanelRoute[] = [
  {
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    path: '/mineral_rights/:id/details',
    getComponent: ({ item }) => <Details item={item} />,
  },
];
